import React from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DeliveryDiningOutlinedIcon from '@mui/icons-material/DeliveryDiningOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined';
import Warning from '@mui/icons-material/Warning';
import List from '@mui/material/List';
import makeStyles from '@mui/styles/makeStyles';
import Permissions from 'react-redux-permissions';

import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';

import { toKebabCase } from '../../../helpers/strings';
import { RMSModules } from '../../RMS/utils/rmsModules';

const useStyles = makeStyles(() => ({
  listItemDanger: {
    color: '#ff426a',
  },
}));

const PropertySettingsList = ({
  appId,
  propertyId,
  showPropertyOpeningHours,
}: {
  appId: string;
  propertyId: string;
  showPropertyOpeningHours: boolean;
}) => {
  const classes = useStyles();

  const basePropertyUrl = `/${appId}/properties/${propertyId}`;
  return (
    <PaperContainer fluid>
      <List>
        <ListItemLinkButton
          subtitle="Set_address_phone_email_and_map_pin"
          title="General_settings"
          iconComponent={<MapOutlinedIcon />}
          link={`${basePropertyUrl}/general-settings`}
        />
        {showPropertyOpeningHours && (
          <ListItemLinkButton
            title="Opening_Hours"
            subtitle="Configure_property_opening_hours"
            iconComponent={<AccessTimeOutlinedIcon />}
            link={`${basePropertyUrl}/opening-hours`}
          />
        )}
        <ListItemLinkButton
          title="Operating_Configuration"
          subtitle="Sms_configuration_staff_meals_emails_for_staff_com"
          link={`${basePropertyUrl}/${toKebabCase(RMSModules.OPERATING_CONFIGURATION)}`}
          iconComponent={<SettingsApplicationsOutlinedIcon />}
        />
        <ListItemLinkButton
          subtitle="Manage_staff"
          title="Staff"
          iconComponent={<GroupsOutlinedIcon />}
          link={`${basePropertyUrl}/${toKebabCase(RMSModules.STAFF)}`}
        />
        <ListItemLinkButton
          title={'Layout_Configuration'}
          subtitle={'Configure_restaurant_layout'}
          iconComponent={<ViewModuleOutlinedIcon />}
          link={`${basePropertyUrl}/layout`}
        />
        <ListItemLinkButton
          title={'Geo_Configuration'}
          subtitle={'Set_amenities_co_ordinates_and_ratings'}
          iconComponent={<PinDropOutlinedIcon />}
          link={`${basePropertyUrl}/${toKebabCase(RMSModules.GEO_CONFIGURATION)}`}
        />
        <ListItemLinkButton
          title={'Address_Mapping'}
          subtitle={'Configure_property_address_overrides'}
          iconComponent={<EditLocationOutlinedIcon />}
          link={`${basePropertyUrl}/${toKebabCase(RMSModules.ADDRESS_MAPPING)}`}
        />
        <Permissions permissions={['FlipdishStaff']}>
          <ListItemLinkButton
            tagText="Flipdish_staff_only"
            title={'Delivery_Management_Configuration'}
            subtitle={'Set_type_webhook_and_API_key'}
            iconComponent={<DeliveryDiningOutlinedIcon />}
            link={`${basePropertyUrl}/${toKebabCase(RMSModules.DELIVERY_CONFIGURATION)}`}
          />
        </Permissions>
        <ListItemLinkButton
          title={'Float_Configuration'}
          subtitle={'Configure_property_float_settings'}
          iconComponent={<TvOutlinedIcon />}
          link={`${basePropertyUrl}/${toKebabCase(RMSModules.FLOAT_CONFIGURATION)}`}
        />
        <ListItemLinkButton
          title="Localization_Configuration"
          subtitle="Configure_localization_setting_overrides"
          link={`${basePropertyUrl}/localisation-configuration`}
          iconComponent={<LanguageOutlinedIcon />}
        />
        <ListItemLinkButton
          title="Danger_zone_title"
          subtitle="property_danger_zone_description"
          link={`${basePropertyUrl}/dangerzone`}
          iconComponent={<Warning />}
          isLast={true}
          classesFromParent={{
            logo: classes.listItemDanger,
            primary: classes.listItemDanger,
            secondary: classes.listItemDanger,
          }}
        />
      </List>
    </PaperContainer>
  );
};

export default PropertySettingsList;
