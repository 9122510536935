import { RefObject, useEffect } from 'react';

import type { KeyPressType } from '@fd/customHooks/useKeyPress';
import type { Brand, Org } from '@flipdish/orgmanagement';

export function useKeyboardNavigation<T extends Org | Brand>(
  items: T[],
  refs: Array<RefObject<HTMLDivElement>>,
  keyPressed: KeyPressType,
  currentFocusedIndex: number,
  setCurrentFocusedIndex: (val: number) => void,
  toggleOrgDrawer: () => void
) {
  const handleArrowUp = () => {
    if (currentFocusedIndex === -1 || currentFocusedIndex === 0) {
      const lastElement = refs[refs.length - 1];
      setCurrentFocusedIndex(refs.length - 1);
      lastElement.current?.focus();
    } else {
      const newFocusedItem = refs[currentFocusedIndex - 1];
      setCurrentFocusedIndex(currentFocusedIndex - 1);
      newFocusedItem.current?.focus();
    }
  };

  const handleArrowDown = () => {
    if (currentFocusedIndex < refs.length - 1) {
      const newFocusedItem = refs[currentFocusedIndex + 1];
      setCurrentFocusedIndex(currentFocusedIndex + 1);
      newFocusedItem.current?.focus();
    } else {
      setCurrentFocusedIndex(0);
      refs[0].current?.focus();
    }
  };

  const handleKeyPress = () => {
    if (keyPressed.key === 'ArrowUp') {
      handleArrowUp();
    } else if (keyPressed.key === 'ArrowDown') {
      handleArrowDown();
    } else if (keyPressed.key === 'Escape') {
      toggleOrgDrawer();
    }
  };

  useEffect(() => {
    if (items.length) {
      handleKeyPress();
    }
  }, [keyPressed]);
}
