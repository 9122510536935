import { useEffect, useRef, useState } from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import useStoreToSalesChannelTransformerWithQuery from '../components/Settings/utils/useStoreToSalesChannelTransformerWithQuery';
import { flagService } from '../services/flagService/flagService';
import { logger } from '../services/loggerService';

export const useRedirectIfStoresPageHidden = (shouldCheckRedirect = true) => {
  const history = useHistory();
  const location = useLocation();
  const appId = useSelector((state: AppState) => state.currentApp?.AppId);
  const { data } = useQueryRMSCommonDataHook(appId ?? '');
  const storeToSalesChannelAssociations = useStoreToSalesChannelTransformerWithQuery();
  const state = useSelector((state: AppState) => state);
  const { storeId: storeIdParam } = useParams<{ storeId: string }>();
  const storeId = storeIdParam ? Number(storeIdParam) : undefined;

  const hideStoresPage2Flag = flagService.isFlagOn(state, 'hideStoresPage2');
  const useStoresToSalesChannelRedirectFlag = flagService.isFlagOn(
    state,
    'useStoresToSalesChannelRedirect'
  );

  const [isRedirecting, setIsRedirecting] = useState(true);
  const hasRedirected = useRef(false);

  if (!useStoresToSalesChannelRedirectFlag) {
    shouldCheckRedirect = false;
  }

  const shouldHideStoresPage = () => {
    const isMigratedToSalesChannelsApiFlagOn = data?.associations?.isMigratedToSalesChannels;
    return isMigratedToSalesChannelsApiFlagOn && hideStoresPage2Flag;
  };

  const redirectToSalesChannelOrProperties = (storeId: number | undefined) => {
    const salesChannelAssociation = storeId
      ? storeToSalesChannelAssociations.find((assoc) => assoc.storeId === storeId)
      : undefined;

    const property = data?.associations?.orgHierarchy?.properties?.find((property) =>
      property.storefronts.some(
        (storefront) => storefront.code === salesChannelAssociation?.salesChannelId
      )
    );

    if (storeId && salesChannelAssociation && property) {
      const redirectPath = `/${appId}/properties/${property.code}/sales-channel/${salesChannelAssociation.salesChannelId}`;
      logger.info('Redirecting to sales channel page', {
        appId,
        propertyId: property.code,
        salesChannelId: salesChannelAssociation.salesChannelId,
        storeId,
        from: location.pathname,
        to: redirectPath,
        flags: {
          isApiFlagOn: data?.associations?.isMigratedToSalesChannels,
          hideStoresPage2: hideStoresPage2Flag,
        },
      });
      history.push(redirectPath);
    } else {
      const redirectPath = `/${appId}/properties`;
      logger.info('Redirecting to properties page', {
        appId,
        from: location.pathname,
        to: redirectPath,
        reason: storeId ? 'No matching sales channel found' : 'No store ID in URL',
        flags: {
          isApiFlagOn: data?.associations?.isMigratedToSalesChannels,
          hideStoresPage2: hideStoresPage2Flag,
        },
      });
      history.push(redirectPath);
    }
  };

  useEffect(() => {
    if (!shouldCheckRedirect || hasRedirected.current || !appId) {
      setIsRedirecting(false);
      return;
    }

    if (shouldHideStoresPage()) {
      redirectToSalesChannelOrProperties(storeId);
      hasRedirected.current = true;
    } else {
      setIsRedirecting(false);
    }
  }, [appId, data, storeToSalesChannelAssociations]);

  return shouldCheckRedirect && isRedirecting;
};
