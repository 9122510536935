import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { type FieldProps, Field } from 'formik';
import debounce from 'lodash/debounce';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Select, { type OptionType } from '@fd/ui/Select/Select';

import { useInfiniteQueryStoreFilterHook } from '../../../custom-hooks/useInfiniteQuerySoreFilter';
import { loadByAppId } from '../../../services/store.service';
import { getSalesChannelType } from '../../../ui/utils/generateSalesChannelsTypes';
import FieldWrapper from '../../Finance/Banking/components/fields/FieldWrapper';
import useStoreToSalesChannelTransformerWithQuery from '../../Settings/utils/useStoreToSalesChannelTransformerWithQuery';

type ExtendedOptionType = OptionType & {
  salesChannelType?: string;
  salesChannelName?: string;
  brandName?: string;
};

type OuterProps = {
  fieldName: string;
  preSelectedStores?: ExtendedOptionType[];
};

type InnerProps = MappedState;
type Props = InnerProps & OuterProps;

const AssignStoresField: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { appId, preSelectedStores } = props;
  const [query, setQuery] = useState('');
  const [storeSelectValue, setStoreSelectValue] = useState<ExtendedOptionType[]>();
  const [storeOptions, setStoreOptions] = useState<ExtendedOptionType[]>();
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    if (preSelectedStores?.length) {
      setStoreSelectValue(preSelectedStores);
    }
  }, [preSelectedStores]);

  const fetchDataProps = {
    appId,
    query,
  };

  // @ts-expect-error: PRJSP-445
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQueryStoreFilterHook(fetchDataProps, loadByAppId, 'loadByAppId');

  const storeIdAssociations = useStoreToSalesChannelTransformerWithQuery();

  useEffect(() => {
    if (data && data.pages) {
      const addNewStoreOptions = data.pages.reduce<ExtendedOptionType[]>((total, page) => {
        if (page.Data) {
          page.Data.forEach((store) => {
            const salesChannelInfo = storeIdAssociations.find(
              (association) => association.storeId === store.StoreId
            );

            total.push({
              label: `${store.Name as string}${getSalesChannelType(salesChannelInfo?.salesChannelType ?? '')}`,
              value: String(store.StoreId),
              isDisabled: false,
              salesChannelType: salesChannelInfo?.salesChannelType,
              salesChannelName: salesChannelInfo?.salesChannelName,
              brandName: salesChannelInfo?.brandName,
            });
          });
        }
        return total;
      }, []);
      if (preSelectedStores?.length) {
        const filteredStores = addNewStoreOptions.filter(
          (store) => !preSelectedStores.some((selected) => selected.value == Number(store.value))
        );
        setStoreOptions(filteredStores);
      } else {
        setStoreOptions(addNewStoreOptions);
      }
    }
  }, [data?.pages, storeIdAssociations, preSelectedStores]);

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (hasScrollBar && hasNextPage && Math.ceil(scrollTop) >= scrollTopMax) {
      fetchNextPage();
    }
  };
  //#endregion

  const onSearchQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        setQuery(query);
      }, 500),
    []
  );

  const validate = (value: string[]) => {
    if (value.length === 0 || value === undefined) {
      return <Translate id="Please_select_a_store" />;
    }
    return;
  };

  return (
    <Box onScroll={handleScroll}>
      <FieldWrapper>
        <Field name={'StoreIds'} validate={validate}>
          {({ field, form }: FieldProps) => {
            const { errors, touched, isSubmitting } = form;
            const fieldError = errors[field.name] as string | undefined;
            const showError = !!fieldError && (touched[field.name] as boolean | undefined);

            const handleSelectedStoreChange = (values: ExtendedOptionType[]) => {
              setStoreSelectValue(values);

              const storeId = values.map((v) => v.value);

              setShrink(true);
              form.setFieldValue('StoreIds', storeId, false);
              form.errors.StoreIds = undefined;
            };

            return (
              <Select
                {...field}
                dataFd="campaign-loyalty-store-select"
                fieldError={showError ? fieldError : undefined}
                isClearable
                isDisabled={isSubmitting}
                isLoading={isLoading || isFetchingNextPage}
                isMulti
                onFocus={() => {
                  setQuery('');
                }}
                onChange={(values: ExtendedOptionType[]) => {
                  handleSelectedStoreChange(values);
                  form.errors.Store = undefined;
                }}
                options={storeOptions}
                placeholder={''}
                setSearchQuery={onSearchQueryChange}
                TextFieldProps={{
                  fdKey: 'stores-dropdown',
                  name: 'selected-stores',
                  InputLabelProps: { shrink: false },
                }}
                value={storeSelectValue}
                variant="standard"
              />
            );
          }}
        </Field>
      </FieldWrapper>
    </Box>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    appId: currentApp.AppId!,
  };
};

const EnhancedComponent = connect(mapStateToProps)(AssignStoresField);

export default EnhancedComponent;
