import React, { useCallback, useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import { IAppState } from '../../../reducers/root.reducer';
import { logger } from '../../../services/loggerService';
import { getTheme } from '../../../styles/theme';
import { Loader } from '../../common/Loader';
import { getLookerSsoEmbedUrl, GetStoreSearch, getStoreSearch } from '../OrderReport.actions';

const useStyles = makeStyles({
  container: {
    height: '95vH',
    '&::-webkit-scrollbar': { display: 'none' },
    overflow: 'hidden',
    flexDirection: 'column',
  },
  embedFrame: {
    flex: 1,
    borderWidth: 0,
  },
  learnMoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 32,
    marginTop: 10,
  },
  learnMoreLink: {
    cursor: 'pointer',
    color: getTheme().palette.primary.main,
  },
});

export enum GlobalDashboards {
  CustomerReports = 'customer_reports',
  PerformanceSummary = 'performance_summary',
  FlipdishPhoneMarketing = 'flipdish_phone_marketing_fpm_report',
  BusinessReview = 'business_review',
  BusinessReviewNew = 'business_review_new',
}

type OuterProps = { isGlobal?: boolean };
type InnerProps = MappedState & MappedDispatch & RouteComponentProps<{ id: string }>;
const LookerDashboard = (props: OuterProps & InnerProps) => {
  const {
    isGlobal = false,
    getLookerSsoEmbedUrl,
    getStoreSearch,
    match: {
      params: { id },
    },
  } = props;
  const classes = useStyles();

  const iframeRef = useRef<HTMLIFrameElement | null>();

  const runAction = useCallback(
    (action: string, payload: any = {}) => {
      if (iframeRef.current) {
        const { hostname } = new URL(iframeRef.current.src);
        iframeRef.current?.contentWindow?.postMessage(
          JSON.stringify({
            type: action,
            ...payload,
          }),
          'https://' + hostname
        );
      }
    },
    [iframeRef.current]
  );

  useEffect(() => {
    const onMessage = (event) => {
      if (event.source === iframeRef.current?.contentWindow) {
        let data;
        try {
          data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
        } catch (e) {
          logger.error(
            `LookerDashboard.tsx: error parsing window message: ${JSON.stringify(event.data)}`,
            e
          );
          throw e;
        }
        switch (data?.type) {
          case 'dashboard:filters:changed': {
            const filters = Object.fromEntries(new URLSearchParams(location.search) as any);
            const {
              dashboard: { dashboard_filters },
            } = data || {};
            for (const key in dashboard_filters) {
              if (dashboard_filters[key].length) {
                filters[key] = dashboard_filters[key];
              } else if (filters[key]) {
                delete filters[key];
              }
            }
            history.pushState(
              null,
              '',
              location.pathname + '?' + new URLSearchParams(filters).toString()
            );
            break;
          }
          case 'dashboard:loaded': {
            (async () => {
              runAction('dashboard:filters:update', {
                filters: {
                  ...(await getDefaultFilters(id)),
                  ...Object.fromEntries(new URLSearchParams(location.search) as any),
                },
              });
              runAction('dashboard:run');
            })();
            break;
          }
          default:
            break;
        }
      }
    };

    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [isGlobal, id]);

  const getDefaultFilters = useCallback(
    async (dashboard: string | GlobalDashboards) => {
      const stores = await getStoreSearch({ isReporting: true });
      const defaultCurrency = stores && stores[0].currency;
      return {
        Currency: defaultCurrency || undefined,
      };
    },
    [isGlobal, id]
  );

  const { data } = useQuery({
    queryKey: ['lookerDashboard', isGlobal, id],

    queryFn: async () =>
      getLookerSsoEmbedUrl(isGlobal ? `flipdish::${id}` : id, { embed_domain: window.origin }),
  });

  return (
    <Grid container spacing={0} className={classes.container}>
      {data?.length ? (
        <iframe
          ref={(frame) => (iframeRef.current = frame)}
          src={data}
          className={classes.embedFrame}
        />
      ) : (
        <Loader />
      )}
    </Grid>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: IAppState) => ({});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getLookerSsoEmbedUrl: (dashboard: string, filters: { [key: string]: any } = {}) =>
    dispatch(getLookerSsoEmbedUrl(dashboard, filters)),
  getStoreSearch: (params: GetStoreSearch) => dispatch(getStoreSearch(params)),
});

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(LookerDashboard);
