import * as React from 'react';

import type { Property } from '@flipdish/orgmanagement';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import memoize from 'memoize-one';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import MapCard from '../../StoreGroups/components/MapCard';

const StyledPropertyTag = styled(Typography)(({ theme }) => ({
  display: 'inline-flex',
  padding: theme.spacing(0.5, 1),
  borderRadius: '4px',
  backgroundColor: '#EEEBE9',
  fontSize: '12px',
  fontWeight: 400,
  width: 'fit-content',
  marginLeft: 0,
  lineHeight: theme.spacing(2),
}));

const StyledCaptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const Caption = ({ propertyData }) => {
  const address = propertyData?.address;
  return (
    <StyledCaptionContainer>
      <span data-fd="property_card_address_caption">{address}</span>
      {propertyData?.isArchived && (
        <StyledPropertyTag>
          <Translate id="Archived" />
        </StyledPropertyTag>
      )}
    </StyledCaptionContainer>
  );
};

// Memoized function to generate map query parameters for the property location
const mapProps = memoize((propertyData) => {
  const { latitude, longitude } = propertyData?.coordinates ?? {};
  return latitude && longitude
    ? `zoom=9&center=${latitude},${longitude}&markers=${latitude},${longitude}`
    : undefined;
});

type Props = {
  propertyData: Property;
};

const PropertyCard: React.FC<Props> = ({ propertyData }) => {
  const match = useRouteMatch('/:appId/properties');
  const { propertyId, name } = propertyData;

  return (
    <MapCard
      fdKey={`property_${propertyId}`}
      title={name || ''}
      href={`${match?.url}/${propertyId}`}
      mapQueryProps={mapProps(propertyData)}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Caption propertyData={propertyData} />
      </div>
    </MapCard>
  );
};

export default compose<Props, Props>(setDisplayName('PropertyCard'), connect())(PropertyCard);
