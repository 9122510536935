import React, { useCallback, useEffect, useState } from 'react';

import FullScreenIcon from '@mui/icons-material/Fullscreen';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExitOutlined';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import markerSvg from '../../../assets/images/marker.svg';
import { GMAP_API_KEY, libraries } from '../../../constants/map.constant';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  toolbox: {
    position: 'absolute',
    right: '52px',
    bottom: '24px',
    height: '52px',
    borderRadius: '3px',
    backgroundColor: ' rgba(255, 255, 255)',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '8px',
    '& > button': {
      color: '#05149e',
    },
  },
  search: {
    position: 'absolute',
    bottom: '24px',
    left: '12px',
    backgroundColor: ' rgba(255, 255, 255)',
  },
}));

export type Props = {
  coords: google.maps.LatLngLiteral;
  center: google.maps.LatLngLiteral;
  updateCoordinatesAction: (coordinates: google.maps.LatLngLiteral) => void;
};

const PropertyLocationMap = ({ center, coords, updateCoordinatesAction }: Props) => {
  const classes = useStyles();
  const [fullscreen, setFullscreen] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GMAP_API_KEY ?? '',
    libraries,
  });

  useEffect(() => {
    const listener = () => {
      const parentNode = map && (map.getDiv().parentNode as HTMLDivElement);

      if (parentNode && document.fullscreenElement === parentNode) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', listener);

    return () => {
      document.removeEventListener('fullscreenchange', listener);
    };
  }, [map]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleMapClick = (e: google.maps.MapMouseEvent | undefined) => {
    if (e?.latLng) {
      const newPosition = e.latLng.toJSON();
      handleSave(newPosition);
    }
  };

  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent | undefined) => {
    if (e && e.latLng) {
      const newPosition = e.latLng.toJSON();
      handleSave(newPosition);
    }
  };

  const handleSave = (newPosition?: google.maps.LatLngLiteral) => {
    if (updateCoordinatesAction) {
      if (newPosition) {
        updateCoordinatesAction(newPosition);
      }
    }
  };

  const mapOptions: google.maps.MapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: true,
  };

  if (!isLoaded) return null;

  return (
    <div className={classes.root} data-fd="map-root-div" style={{ height: '500px' }}>
      <GoogleMap
        mapContainerStyle={{ height: '100%' }}
        center={center}
        onClick={handleMapClick}
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        options={mapOptions}
        zoom={16}
      >
        <Marker draggable icon={markerSvg} onDragEnd={handleMarkerDragEnd} position={coords} />
      </GoogleMap>

      <div className={classes.toolbox}>
        <IconButton
          onClick={() => {
            const parentNode = map?.getDiv().parentNode as HTMLDivElement;
            if (parentNode) {
              if (document.fullscreenElement === parentNode) {
                document.exitFullscreen();
              } else {
                parentNode.requestFullscreen();
              }
            }
          }}
        >
          {fullscreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
        </IconButton>
      </div>
    </div>
  );
};

export default PropertyLocationMap;
