import { addTranslationForLanguage, initialize } from 'react-localize-redux';

import { languageActions } from './actions/language.actions';
import constants from './constants.json';
import { store } from './helpers/store';
import { translateTransform } from './helpers/utilities';
import DEFAULT_TRANSLATION from './localization/en.json';
import locales from './localization/locales.json';
import { getSupportedLanguages } from './utils/languageUtils';

const DEFAULT_CODE = 'en';

declare global {
  // eslint-disable-next-line no-undef
  export type Translation = typeof DEFAULT_TRANSLATION;
  export type TranslationId = keyof Translation;
}

const isProduction = constants.GIT_BRANCH === 'master';

const getUserLanguage = (supportedLanguages: string[]): string => {
  const currentLanguageInStorage = localStorage.getItem('flipdish-current-language');

  if (currentLanguageInStorage && supportedLanguages.some((l) => l === currentLanguageInStorage)) {
    return currentLanguageInStorage;
  }

  const userLanguages = navigator.languages;
  if (userLanguages && userLanguages.length > 0) {
    const userLanguage = userLanguages.find((code) => supportedLanguages.some((l) => l === code));
    if (userLanguage) {
      return userLanguage;
    }
  }

  return 'en';
};

const setupFallbackLanguages = () => {
  const translations = locales
    .filter((l) => l.code !== DEFAULT_CODE)
    .reduce(
      (agg, loco) => {
        const t = require(`./localization/${loco.code}.json`);
        agg[loco.code] = t;
        return agg;
      },
      { en: DEFAULT_TRANSLATION } as { [key: string]: Translation }
    );

  // add fallback languages
  Object.entries(translations).forEach(([code, t]) => {
    if (code.indexOf('-')) {
      const baseCode = code.split('-')[0];
      if (translations[baseCode]) {
        translations[code] = { ...DEFAULT_TRANSLATION, ...translations[baseCode], ...t };
      }
    } else {
      translations[code] = { ...DEFAULT_TRANSLATION, ...t };
    }
    store.dispatch(
      addTranslationForLanguage({
        language: code,
        translation: translateTransform(translations[code]),
      })
    );
  });
};

const setupUserLanguage = () => {
  const supportedLanguages: string[] = getSupportedLanguages();

  const userLanguage = getUserLanguage(supportedLanguages);

  store.dispatch(
    initialize({
      languages: supportedLanguages,
      options: {
        showMissingTranslationMsg: !isProduction,
      },
    })
  );

  setupFallbackLanguages();
  store.dispatch(languageActions.setLanguage(userLanguage) as any);
};

export default setupUserLanguage;
