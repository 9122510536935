import {
  BankAccountSummary,
  CountryWithAccountFieldsDefinitions,
  Store,
  StripeConnectedAccountInfo,
} from '@flipdish/api-client-typescript';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import { editBankAccountFeature } from '../../../selectors/app.selector';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { GetLastFourIbanNumbers } from '../../Home/helpers';
import { BANKING_LOAD } from './banking.actions';
import { countries } from './components/fields/CountrySelectorFormField';

const AccountStatusEnum = StripeConnectedAccountInfo.AccountStatusEnum;
const CardPaymentsStatus = StripeConnectedAccountInfo.CardPaymentStatusEnum;
const AccountStateEnum = BankAccountSummary.AccountStateEnum;

type CurrentApp = ReturnType<typeof currentAppSelector>;
const currentAppSelector = (state: AppState) => state.currentApp;

export const getIsBankAccountLoading = createLoadingSelector([BANKING_LOAD]);

export const getBankAccountListUrl = createSelector([currentAppSelector], (app) =>
  app ? `/${app.AppId}/finance/bank-accounts` : undefined
);

export const getAddNewAccountUrl = createSelector([currentAppSelector], (app) =>
  app && app.AppId ? `/${app.AppId}/finance/bank-accounts/new` : undefined
);

export const getEditAccountUrlFactory = (accountId: number) =>
  createSelector([currentAppSelector], (app) =>
    app ? `/${app.AppId}/finance/bank-accounts/${accountId}/edit` : undefined
  );

// #region getCountry
export const countryCodeToCountry = (countryCode: string | undefined) => {
  if (countryCode) {
    let country = '';
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].value === countryCode) {
        country = countries[i].label;
      }
    }
    return country;
  } else {
    return;
  }
};
// #endregion

type Accounts = ReturnType<typeof accountsSelector>;
const accountsSelector = (state: AppState) => state.banking.accounts;

export const getAccountIds = createSelector([accountsSelector], (accounts) =>
  accounts.map((a) => a.Id as number)
);

export const getAccountByRouteId = createSelector(
  [(state, accountId) => state.banking.accountById[accountId]],
  (account) => account
);

export const getAccountById = createSelector(
  [
    (state, accountId) =>
      accountId !== undefined ? state.banking.accountById[accountId] : undefined,
  ],
  (account) => account || null
);

// #region createBankAccountSummaryExtended
export type BankAccountSummaryExtended = {
  priority?: number;
  alertType?: string;
  bodyText?: {
    id: TranslationId;
    data: {};
  };
} & BankAccountSummary;

const checkEditBankAccountFF = (state: AppState) => editBankAccountFeature(state);

const createBankAccountSummaryExtended = (
  account: BankAccountSummary
): BankAccountSummaryExtended => {
  const stripe: StripeConnectedAccountInfo | undefined = account.StripeConnectedAccountInfo;
  const isAccountVerifiedOrAwaiting =
    account.AccountState === AccountStateEnum.Verified ||
    account.AccountState === AccountStateEnum.AwatingVerification;
  const isAccountVerifiedAwaitingOrGrandfathered =
    isAccountVerifiedOrAwaiting || account.AccountState === AccountStateEnum.Grandfathered;
  const associatedStores = !isEmpty(account.StoreNames);
  const rejectedBank = account.AccountState === AccountStateEnum.Unverified;
  const rejectedStripe = stripe?.AccountStatus === AccountStatusEnum.Rejected;
  const additionalInfoRequieres =
    stripe?.AccountStatus === AccountStatusEnum.AdditionalInformationRequired;

  const rejectedBankAccountExtended =
    associatedStores && (rejectedBank || rejectedStripe)
      ? {
          priority: 1,
          alertType: 'rejectedAccount',
          bodyText: {
            id: 'Bank_account_0_has_been_rejected_Assign_associated_sales_channel' as TranslationId,
            data: {
              LastFourIbanNumbers: GetLastFourIbanNumbers({
                stripeUpdateAccount: account,
              }),
            },
          },
        }
      : {};
  const additionalInformationRequiredStripe = additionalInfoRequieres
    ? {
        priority: 2,
        alertType: 'verifyAccount',
        bodyText: {
          id: 'You_must_verify_some_of_your_banking_details_to_receive_payouts' as TranslationId,
          data: {},
        },
      }
    : {};
  const isUnverified =
    isAccountVerifiedOrAwaiting && stripe?.AccountStatus === AccountStatusEnum.Unverified
      ? {
          priority: 2,
          alertType: 'verifyAccount',
          bodyText: {
            id: 'You_must_verify_some_of_your_banking_details_to_receive_payouts' as TranslationId,
            data: {},
          },
        }
      : {};
  const hasBankingAccountWithoutBussinesType = !account.BusinessType
    ? {
        priority: 2,
        alertType: 'verifyAccount',
        bodyText: {
          id: 'You_must_verify_some_of_your_banking_details_to_receive_payouts' as TranslationId,
          data: {},
        },
      }
    : {};

  const updateStripe =
    //@ts-ignore
    checkEditBankAccountFF &&
    isAccountVerifiedAwaitingOrGrandfathered &&
    stripe?.AccountStatus === AccountStatusEnum.UpdateExternalAccount
      ? {
          priority: 3,
          alertType: 'editAccount',
          bodyText: {
            id: 'We_are_unable_to_make_payouts_to_your_bank_account_ending' as TranslationId,
            data: {
              LastFourIbanNumbers: GetLastFourIbanNumbers({
                stripeUpdateAccount: account,
              }),
            },
          },
        }
      : {};
  const cardPaymentsStatus =
    isAccountVerifiedAwaitingOrGrandfathered &&
    (stripe?.CardPaymentStatus === CardPaymentsStatus.Inactive ||
      stripe?.CardPaymentStatus === CardPaymentsStatus.Pending ||
      stripe?.CardPaymentStatus === CardPaymentsStatus.Unrequested)
      ? {
          priority: 4,
          alertType: 'cardPayment',
          bodyText: {
            id: 'Verify_details_to_avoid_downtime_in_card_payments_for_stores' as TranslationId,
            data: {
              LastFourIbanNumbers: GetLastFourIbanNumbers({
                stripeUpdateAccount: account,
              }),
            },
          },
        }
      : {};

  return {
    ...account,
    ...hasBankingAccountWithoutBussinesType,
    ...additionalInformationRequiredStripe,
    ...isUnverified,
    ...updateStripe,
    ...cardPaymentsStatus,
    ...rejectedBankAccountExtended,
  };
};
// #endregion

export const getAccountByIdFactory = (accountId: number) =>
  createSelector([accountsSelector], (accounts) => {
    const account = accounts.find((a) => a.Id === accountId);
    if (account) {
      return createBankAccountSummaryExtended(account);
    }
    return undefined;
  });

export const getAccountDetailsUrlFactory = (accountId: number) =>
  createSelector([currentAppSelector], (app) =>
    app ? `/${app.AppId}/finance/bank-accounts/${accountId}` : undefined
  );

// #region get stores
const bankingStores = (state: AppState) => state.banking.stores;
export const getStoreList = createSelector([bankingStores], (stores: Store[]) => {
  if (stores) {
    return stores.map((store) => {
      return {
        value: store.StoreId,
        label: store.Name,
        currency: store.Currency,
        isDisabled: false,
      };
    });
  } else {
    return [];
  }
});
// #endregion

const bankingAccounts = (state: AppState) => state.banking.accounts;

const bankAccountSummaryExtendedSelector = createSelector([bankingAccounts], (bankingAccounts) =>
  bankingAccounts.map(createBankAccountSummaryExtended)
);

// #region getBankAccountAlerts
const getBankAccountAlerts = createSelector(
  [bankAccountSummaryExtendedSelector],
  (bankingAccounts) => {
    const seen: number[] = [];
    return bankingAccounts
      .filter((a) => typeof a.priority === 'number')
      .reduce<BankAccountSummaryExtended[]>((acc, cur) => {
        if (!seen.includes(cur.priority!)) {
          acc.push(cur);
          seen.push(cur.priority!);
        }
        return acc;
      }, [])
      .sort((a, b) => a.priority! - b.priority!);
  }
);
// #endregion

export const getLatestBankAccount = createSelector(
  [bankingAccounts],
  (bankingAccounts: BankAccountSummary[]) => {
    const latestBankAccount = bankingAccounts[bankingAccounts.length - 1];
    return latestBankAccount;
  }
);

// #region hasEnabledBankingAccount
const hasEnabledStripeAccount = createSelector(
  [bankingAccounts],
  (bankingAccounts: BankAccountSummary[]): boolean => {
    if (bankingAccounts) {
      return bankingAccounts.some(
        (account) => account.StripeConnectedAccountInfo?.AccountStatus === AccountStatusEnum.Enabled
      );
    } else {
      return false;
    }
  }
);
// #endregion

// #region getCountryFieldDefinitions
const countryWithFieldDefinition = (state: AppState) =>
  state.banking.countriesfieldDefinitions || {};

const getCountriesWithFieldDefinitions = (selectedCountry: string, state: AppState) => {
  const countryFieldDefinition = countryWithFieldDefinition(state)[selectedCountry];
  return countryFieldDefinition;
};
// #endregion

// #region getCountryList
const getCountryList = createSelector(
  [countryWithFieldDefinition],
  (countryWithFieldDefinition: CountryWithAccountFieldsDefinitions) =>
    Object.entries(countryWithFieldDefinition)
      .reduce<{ value: string; label: string }[]>((list, [key, details]) => {
        if (details.SupportType !== 'not-supported') {
          list.push({
            value: key as string,
            label: details.DisplayName as string,
          });
        }
        return list;
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label))
);
// #endregion

// #region getBankAccountsExtended
const getBankAccountsExtended = createSelector(
  [bankingAccounts],
  (bankingAccounts: BankAccountSummary[]): BankAccountSummaryExtended[] =>
    bankingAccounts.map((bankAccount) => createBankAccountSummaryExtended(bankAccount))
);
// #endregion

export const bankingSelectors = {
  hasEnabledStripeAccount,
  getBankAccountAlerts,
  getBankAccountsExtended,
  getCountriesWithFieldDefinitions,
  getCountryList,
};
