import React, { ChangeEvent, forwardRef, KeyboardEvent, useEffect, useRef } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { usePrevious } from '../../../custom-hooks/usePrevious';
import { BRAND_SEARCH_PREFIX } from './constants';

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '10px',
  padding: '16px 12px 16px 24px',
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,

  '& > div::before, & > div::after, & > div:hover::before': {
    borderBottom: 'initial !important',
  },
}));

const SearchIconStyled = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledSearchPrefix = styled('span')({
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
  padding: '2px 6px',
  borderRadius: '4px',
  marginRight: '6px',
  fontSize: '0.875rem',
  fontWeight: 500,
});

const StyledInputContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const StyledInput = styled('input')({
  border: 'none',
  outline: 'none',
  width: '100%',
  background: 'transparent',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

type Props = {
  open: boolean;
  searchQuery: string;
  setSearchQuery: (searchQuery: string) => void;
} & MappedProps;

const useRouteSearchParams = (name: string) => {
  const location = useLocation();
  const history = useHistory();

  const setSearch = (query: string) => {
    const params = new URLSearchParams(location.search);
    if (query) {
      params.set(name, query);
    } else {
      params.delete(name);
    }
    history.replace({ search: params.toString() });
  };

  return setSearch;
};

const CustomInput = forwardRef<HTMLInputElement, any>((props, ref) => {
  const { value, onChange, ...other } = props;
  const isBrandSearch = value?.toLowerCase?.().startsWith(BRAND_SEARCH_PREFIX.toLowerCase());
  const displayValue = isBrandSearch ? value.slice(BRAND_SEARCH_PREFIX.length) : value;

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && isBrandSearch && displayValue === '') {
      e.preventDefault();
      onChange?.({ ...e, target: { ...e.target, value: '' } });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (isBrandSearch) {
      if (value === BRAND_SEARCH_PREFIX && newValue.length < displayValue.length) {
        onChange?.({ ...e, target: { ...e.target, value: '' } });
      } else {
        onChange?.({ ...e, target: { ...e.target, value: `${BRAND_SEARCH_PREFIX}${newValue}` } });
      }
    } else {
      onChange?.(e);
    }
  };

  return (
    <StyledInputContainer>
      {isBrandSearch && <StyledSearchPrefix>{BRAND_SEARCH_PREFIX}</StyledSearchPrefix>}
      <StyledInput
        {...other}
        ref={ref}
        value={displayValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </StyledInputContainer>
  );
});

const OrgSelectorSearch = (props: Props) => {
  const { searchQuery, setSearchQuery, open, translate } = props;
  const previousOpen = usePrevious(open);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const setSearch = useRouteSearchParams('orgSearch');

  const handleSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    setSearch(query);
  };

  const onSearchQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    handleSearchQueryChange(query);
  };

  useEffect(() => {
    if (open && !previousOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open, previousOpen]);

  return (
    <StyledTextField
      inputRef={inputRef}
      type="search"
      fullWidth
      placeholder={`${translate('Search')}...`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIconStyled />
          </InputAdornment>
        ),
        inputComponent: CustomInput,
      }}
      inputProps={{ 'data-fd': 'org-brand-selector-search' }}
      onChange={onSearchQueryChange}
      value={searchQuery}
      variant="standard"
    />
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

export default connect(mapStateToProps)(OrgSelectorSearch);
