import * as React from 'react';

import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import * as actions from '../../actions/storeOpeningHours.actions';
import { weekDaysLocal } from '../../selectors/storeOpeningHours.selector';
import GridContainer from '../../ui/Layout/GridContainer';
import OpeningHoursFormItem from './OpeningHoursFormItem';
import { BusinessHoursPeriodBase, DeliveryType } from './types';

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const weekdays = weekDaysLocal(state);
  return {
    weekdays,
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = () => {
  return (dispatch, ownProps) => {
    const { deliveryType } = ownProps;
    return {
      loadAll: () => {
        if (deliveryType === 'Delivery') {
          dispatch(actions.loadDelivery());
        } else {
          dispatch(actions.loadPickup());
        }
      },
      update: (businessHoursPeriod: BusinessHoursPeriodBase) =>
        dispatch(actions.updateOpeningHours(ownProps.storeId, deliveryType, businessHoursPeriod)),
      updateAllDays: (businessHoursPeriod: BusinessHoursPeriodBase) =>
        dispatch(actions.updateAllDays(ownProps.storeId, deliveryType, businessHoursPeriod)),

      dispose: () => dispatch(actions.reset()),
    };
  };
};

export interface IOpeningHoursFormProps {
  id: string;
  deliveryType: DeliveryType;
  storeId: number | string;
  autoFocus?: boolean;
  disabled?: boolean;
  isLast?: boolean;
}

type Props = IOpeningHoursFormProps & MappedState & MappedDispatch;
const OpeningHoursForm = compose<Props, IOpeningHoursFormProps>(
  setDisplayName('OpeningHoursForm'),
  connect(mapStateToProps, mapDispatchToPropsFactory)
)(
  class OpeningHoursForm extends React.PureComponent<Props, {}> {
    public componentDidMount() {
      this.props.loadAll();
    }

    public render() {
      const {
        weekdays,
        update,
        updateAllDays,
        id,
        deliveryType,
        storeId,
        autoFocus,
        disabled,
        isLast,
      } = this.props;

      return (
        <GridContainer>
          {weekdays.map(({ key, val }, idx) => {
            return (
              <OpeningHoursFormItem
                storeId={storeId}
                label={val}
                name={key}
                key={key}
                update={update}
                updateAllDays={updateAllDays}
                id={id}
                isLast={isLast}
                deliveryType={deliveryType}
                autoFocus={autoFocus && idx === 0}
                isFirst={idx === 0}
                disabled={disabled}
              />
            );
          })}
        </GridContainer>
      );
    }
  }
);

export default OpeningHoursForm;
