import React, { useState } from 'react';

import { App, Menu } from '@flipdish/api-client-typescript';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import StoresIcon from '@mui/icons-material/StoreOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MuiExpansionPanel from '@mui/material/Accordion';
import MuiExpansionPanelDetails from '@mui/material/AccordionDetails';
import MuiExpansionPanelSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';

import { Button } from '@fd/ui/atoms';
import Checkbox from '@fd/ui/Checkbox';

import percentage from '../../../assets/images/percentage.svg';
import { flagService } from '../../../services';
import LinkButton from '../../../ui/Button/LinkButton';
import { AssignStoresDialog } from './AssignStoresDialog';
import { MenuTaxRatesDialog } from './MenuTaxRatesDialog';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    paddingRight: '15px',
    [breakpoints.down('md')]: {
      paddingLeft: '24px',
    },
  },
  cardLink: {
    textTransform: 'none',
    padding: '0',
    marginTop: spacing(2),
    '&:hover': {
      background: 'rgba(255, 255, 255)',
    },
  },
  cardSwitchLabel: {
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.25px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  cardText: {
    fontSize: '14px',
    fontWeight: 'normal' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.43',
    letterSpacing: '0.38px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  cardAction: {
    cursor: 'pointer',
    color: '#05149e',
  },
  settingsIcon: {
    fontSize: '18px',
    marginRight: '8.8px',
  },
  settingsCheckboxText: {
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: '0.21px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  taxRates: {
    marginTop: '10px',
  },
  percentage: {
    height: '18px',
    width: '18px',
    marginRight: '8.8px',
  },
  storeAndHideButtons: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginTop: '16px',
    marginBottom: -1,
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
    },
    padding: 0,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0,
    paddingTop: '5.8px',
    flexDirection: 'column',
  },
}))(MuiExpansionPanelDetails);

export interface IControlProps {
  expanded: boolean;
  handleExpand: (e: any, newExpanded: boolean) => any;
  hideHidden: boolean;
  toggleHideHidden: () => any;
  toggleMenuSectionBehaviour: () => any;
}

type Props = IControlProps & MappedState;

const MenuControlsFirstColumnResponsive = (props: Props) => {
  const [showTaxDialog, setShowTaxDialog] = useState<boolean>(false);
  const [showAssignStoresDialog, setShowAssignStoresDialog] = useState<boolean>(false);
  const classes = useStyles();
  const {
    currentApp,
    hideHidden,
    expanded,
    handleExpand,
    isAddStoresOn,
    toggleHideHidden,
    toggleMenuSectionBehaviour,
    translate,
    menu,
  } = props;

  const toggleTaxDialog = () => {
    setShowTaxDialog(!showTaxDialog);
  };

  return (
    <div className={classes.root}>
      <MenuTaxRatesDialog showTaxDialog={showTaxDialog} toggleTaxDialog={toggleTaxDialog} />
      {menu && (
        <AssignStoresDialog
          menuId={menu.MenuId}
          open={showAssignStoresDialog}
          onClose={() => setShowAssignStoresDialog(false)}
          translate={translate}
        />
      )}
      {menu ? (
        <>
          <div className={classes.storeAndHideButtons}>
            {isAddStoresOn && (
              <Button
                className={clsx(classes.cardText, classes.cardAction, classes.cardLink)}
                fdKey="assign-stores-button"
                variant="text"
                lowercase
                onClick={() => setShowAssignStoresDialog(true)}
              >
                <StoresIcon className={clsx(classes.cardAction, classes.settingsIcon)} />
                <Typography className={clsx(classes.cardText, classes.cardAction)}>
                  <Translate id="Add_sales_channels" />
                </Typography>
              </Button>
            )}
            <Permissions allowed={[App.AppResourceSetEnum.UpdateMenuItemsHideTemporarily]}>
              <LinkButton
                to={`/${currentApp.AppId}/menus/bulk-edit/${menu.MenuId}`}
                fdKey={menu.Name}
                className={clsx(classes.cardText, classes.cardAction, classes.cardLink)}
                variant="text"
              >
                <VisibilityOutlinedIcon
                  className={clsx(classes.cardAction, classes.settingsIcon)}
                />
                <Typography className={clsx(classes.cardText, classes.cardAction)}>
                  {translate('show_hide_items')}
                </Typography>
              </LinkButton>
            </Permissions>
          </div>
          <ExpansionPanel square expanded={expanded} onChange={handleExpand}>
            <ExpansionPanelSummary>
              <SettingsIcon className={clsx(classes.cardAction, classes.settingsIcon)} />
              <Typography className={clsx(classes.cardText, classes.cardAction)}>
                {translate('Menu_options')}
              </Typography>
              {expanded ? (
                <ArrowDropUpIcon className={classes.cardAction} />
              ) : (
                <ArrowDropDownIcon className={classes.cardAction} />
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Permissions allowed={[App.AppResourceSetEnum.UpdateMenu]}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        dataFd="only-allow-one-expanded-section-checkbox"
                        checked={
                          menu.MenuSectionBehaviour === Menu.MenuSectionBehaviourEnum.ExpandSingle
                        }
                        color="primary"
                        onChange={toggleMenuSectionBehaviour}
                        value="expanded"
                      />
                    }
                    label={translate('Only_allow_one_expanded_section')}
                    classes={{
                      label: classes.settingsCheckboxText,
                    }}
                  />
                </FormGroup>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        dataFd="hide-hidden-sections-checkbox"
                        checked={hideHidden}
                        color="primary"
                        onChange={toggleHideHidden}
                        value="hide-hidden"
                      />
                    }
                    label={translate('Hide_hidden_sections_items')}
                    classes={{
                      label: classes.settingsCheckboxText,
                    }}
                  />
                </FormGroup>
                <a className={clsx(classes.flexCenter, classes.taxRates)} onClick={toggleTaxDialog}>
                  <img src={percentage} className={classes.percentage} />
                  <Typography className={clsx(classes.cardText, classes.cardAction)}>
                    {translate('Manage_tax_rates')}
                  </Typography>
                </a>
              </Permissions>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </>
      ) : null}
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { locale } = state;
  return {
    currentApp: state.currentApp,
    isAddStoresOn: flagService.isFlagOn(state, 'addStoresButton'),
    menu: state.menus.menu,
    translate: getTranslate(locale),
  };
}

const EnhancedComponent = connect(mapStateToProps)(MenuControlsFirstColumnResponsive);
export { EnhancedComponent as FirstColumn };
