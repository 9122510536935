import React, { Dispatch, SetStateAction, useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import LoadingButton from '../../LoadingButton';
import Tooltip from '../../Tooltip/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '244px',
    color: '#ff395f',
    letterSpacing: '1.25px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    [theme.breakpoints.down(640)]: { width: '100%' },
    '& :hover': { color: 'rgba(0, 0, 0, 0.38)' },
  },
  tooltip: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    fontWeight: 'normal',
  },
  addPadding: { paddingBottom: '16px', paddingRight: '24px' },
}));

type Props = {
  buttonText: TranslationId;
  dialogTitle: TranslationId;
  dialogDescription: TranslationId;
  helperText: TranslationId;
  handleSubmit: () => Promise<void>;
  itemName: string;
  isDisabled?: boolean;
  setIsDisabled?: Dispatch<SetStateAction<boolean>>;
  tooltipMessage?: TranslationId;
  confirmButtonText?: TranslationId;
  introText?: TranslationId;
  dataFdPrefix?: string;
};

const DangerActionButton = ({
  buttonText,
  dialogTitle,
  dialogDescription,
  helperText,
  handleSubmit,
  itemName,
  isDisabled = false,
  setIsDisabled,
  tooltipMessage,
  confirmButtonText,
  introText = 'If_you_are_sure_you_want_to_archive',
  dataFdPrefix = '',
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [textField, setTextField] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const openDialog = () => {
    !isDisabled && setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const _handleSubmit = async () => {
    try {
      if (textField === itemName) {
        setIsSubmitting(true);
        await handleSubmit();
        setIsDisabled?.(true);
        setOpen(false);
      } else {
        setValidationError(true);
      }
    } catch (e) {
      setIsSubmitting(false);
      console.error(e);
    }
  };

  const button = (
    <Button
      data-fd={`${dataFdPrefix}-btn`}
      variant="outlined"
      className={classes.button}
      key={buttonText}
      onClick={openDialog}
      disabled={isDisabled || isSubmitting}
    >
      <Translate id={buttonText} />
    </Button>
  );

  return (
    <>
      {tooltipMessage ? (
        <Tooltip
          messageId={tooltipMessage}
          fdKey="tooltip"
          disableHoverListener={!isDisabled}
          disableTouchListener={!isDisabled}
        >
          <div>{button}</div>
        </Tooltip>
      ) : (
        button
      )}
      <Dialog open={open} onClose={closeDialog} aria-labelledby={dialogTitle}>
        <DialogTitle id={dialogTitle}>
          <Translate id={dialogTitle} />
        </DialogTitle>
        <DialogContent style={{ maxWidth: '280px' }}>
          <DialogContentText component="div">
            <Typography variant="body1" color="textSecondary">
              <Translate id={introText} />{' '}
              <span style={{ fontWeight: 500, fontStyle: 'italic' }}>{itemName}</span>
              {', '}
              <Translate id={dialogDescription} />
            </Typography>
          </DialogContentText>

          <TextField
            variant="standard"
            inputProps={{ 'data-fd': `${dataFdPrefix}-textfield` }}
            autoFocus
            disabled={isSubmitting}
            required
            placeholder={itemName}
            error={validationError}
            margin="none"
            style={{ width: '100%' }}
            onChange={(e) => setTextField(e.target.value)}
            helperText={validationError ? <Translate id={helperText} /> : ''}
          />
        </DialogContent>
        <DialogActions className={classes.addPadding}>
          <Button
            data-fd={`cancel-${dataFdPrefix}`}
            onClick={closeDialog}
            color="primary"
            disabled={isSubmitting}
          >
            <Translate id="Cancel" />
          </Button>

          <LoadingButton
            fdKey={`confirm-${dataFdPrefix}`}
            onClick={_handleSubmit}
            color="secondary"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <Translate id={confirmButtonText ?? buttonText} />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DangerActionButton;
