import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Translate } from 'react-localize-redux';

const StyledButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  width: 'max-content',
  textTransform: 'none',
  '& .MuiSvgIcon-root': {
    marginRight: 0,
    fontSize: '1rem',
  },
}));

const CopyButton = ({
  onClick,
  label,
  disabled,
}: {
  onClick: () => void;
  label: TranslationId;
  disabled?: boolean;
}) => {
  return (
    <StyledButton
      variant="text"
      color="primary"
      startIcon={<ContentCopyIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      <Translate id={label} />
    </StyledButton>
  );
};

export default CopyButton;
