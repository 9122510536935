import type { SignalR } from '@flipdish/api-client-typescript-signalr';

export enum hubConstants {
  SIGNALR_SUBSCRIBE = 'SIGNALR_SUBSCRIBE',
  SIGNALR_UNSUBSCRIBE = 'SIGNALR_UNSUBSCRIBE',
  SIGNALR_SUBSCRIBED = 'SIGNALR_SUBSCRIBED',
  SIGNALR_UNSUBSCRIBED = 'SIGNALR_UNSUBSCRIBED',
}

type HubNameConstants =
  | 'APP_HUB'
  | 'ANALYTICS_HUB'
  | 'BANK_HUB'
  | 'CAMPAIGN_HUB'
  | 'CUSTOMER_HUB'
  | 'KIOSK_CARDREADER_HUB'
  | 'ORDER_HUB'
  | 'PHONECALL_HUB'
  | 'STORE_HUB'
  | 'STORE_GROUP_HUB'
  | 'TEAMMATE_HUB'
  | 'VOUCHER_HUB';

export const hubNames: Record<HubNameConstants, keyof SignalR> = {
  APP_HUB: 'AppHub',
  ANALYTICS_HUB: 'AnalyticsHub',
  BANK_HUB: 'BankAccountHub',
  CAMPAIGN_HUB: 'CampaignHub',
  CUSTOMER_HUB: 'CustomerHub',
  KIOSK_CARDREADER_HUB: 'CardReaderHub',
  ORDER_HUB: 'OrderHub',
  PHONECALL_HUB: 'PhoneCallHub',
  STORE_HUB: 'StoreHub',
  STORE_GROUP_HUB: 'StoreGroupHub',
  TEAMMATE_HUB: 'TeammateHub',
  VOUCHER_HUB: 'VoucherHub',
};
