import React, { useState } from 'react';

import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button, Typography } from '@fd/ui/atoms';
import { SelectAction } from '@fd/ui/Filter/StoreFilter';
import StoreFilterInfiniteScroll from '@fd/ui/Filter/StoreFilterInfiniteScroll';
import { Dialog } from '@fd/ui/molecules/Dialog';

import { menusActions } from '../../../../actions/menus.actions';
import { assignMenuToStore } from '../../../../actions/store.actions';

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  dialog: {
    overflow: 'visible',
  },
  dialogText: {
    color: theme.palette.grey[700],
  },
  storesContainer: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

export type AssignStoresDialogProps = {
  menuId?: number;
  onClose: () => void;
  open: boolean;
  translate: TranslateFunction;
};

const AssignStoresDialog = (props: AssignStoresDialogProps & MappedDispatch): JSX.Element => {
  const { assignMenu, getMenuStores, menuId, open, onClose, translate } = props;
  const classes = useStyles();
  const [selectAction, setSelectAction] = useState<SelectAction>();

  const handleSave = async () => {
    if (selectAction?.values && menuId) {
      //TODO Fix the type. When select is a single select, the values is an object, not an array
      //Tries to change the type definition but it
      //@ts-ignore
      const { label, value } = selectAction.values;
      await assignMenu(Number(value), menuId, label);
      setSelectAction(undefined);
      getMenuStores(menuId);
      onClose();
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      contentClassName={classes.dialog}
      title={translate('Add_sales_channels') as string}
      open={open}
      actions={[
        <Button
          fdKey="cancel-assign-stores"
          key="cancel-assign-stores"
          onClick={onClose}
          variant="text"
        >
          <Translate id="Cancel" />
        </Button>,
        <Button
          disabled={selectAction?.values === undefined}
          fdKey="save-assign-stores"
          key="save-assign-stores"
          onClick={handleSave}
        >
          <Translate id="Save" />
        </Button>,
      ]}
    >
      <Typography className={classes.dialogText} variant="body2">
        <Translate id="Manage_what_stores_this_menu_is_used_for" />
      </Typography>
      <div className={classes.storesContainer}>
        <StoreFilterInfiniteScroll
          hasAllStoresOption={false}
          label="Select_sales_channel"
          placeholder="Select_sales_channel"
          setSelectAction={setSelectAction}
          variant="standard"
        />
      </div>
    </Dialog>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  assignMenu: (storeId: number, menuId: number, storeName: string | undefined) =>
    dispatch(assignMenuToStore(storeId, menuId, storeName)),
  getMenuStores: (menuId) => dispatch(menusActions.getMenuStores(menuId)),
});

const EnhancedComponent = connect(null, mapDispatchToProps)(AssignStoresDialog);
export { EnhancedComponent as AssignStoresDialog };
