import React, { useEffect, useMemo, useState } from 'react';

import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { connect } from 'react-redux';

import { SelectAction } from '@fd/ui/Filter/StoreFilter';
import { getSalesChannelType } from '@fd/ui/utils/generateSalesChannelsTypes';

import { notify, notifyError } from '../../../layouts/Notify/actions';
import { SalesChannelTypeEnum } from '../../../ui/Filter/AssignStoresField';
import StoreFilterInfiniteScroll from '../../../ui/Filter/StoreFilterInfiniteScroll';
import { kioskServices } from '../../Kiosks/Kiosks.services';
import useStoreToSalesChannelTransformerWithQuery from '../../Settings/utils/useStoreToSalesChannelTransformerWithQuery';
import { salesChannelsServices } from '../salesChannels.services';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
}));

type InnerProps = MappedDispatch;
type OuterProps = {
  appId: string;
  appType?: TranslationId;
  description?: React.ReactNode;
  channelId: number;
  showTitle?: boolean;
  isDisabled?: boolean;
  translate: TranslateFunction;
  salesChannelType?: SalesChannelTypeEnum;
};
type Props = InnerProps & OuterProps;

const AssignStoresVisibility = (props: Props) => {
  const {
    appId,
    appType,
    channelId,
    description,
    isDisabled = false,
    notifySaved,
    notifyError,
    showTitle = true,
    translate,
    salesChannelType,
  } = props;
  const [selectAction, setSelectAction] = useState<SelectAction>();
  const classes = useStyles();
  const storeIdAssociations = useStoreToSalesChannelTransformerWithQuery();

  const { data: getStoreHeaders, isError } = useQuery({
    queryKey: ['kioskServices.getStoresByAppId', appId, salesChannelType],
    queryFn: () => kioskServices.getStoreHeaders({ appId, salesChannelType }),
    enabled: !!appId,
  });
  useEffect(() => {
    if (isError) {
      notifyError();
    }
  }, [isError]);

  const {
    data: getAssignedStores,
    isPending,
    isError: isErrorChannels,
  } = useQuery({
    queryKey: ['salesChannelsServices.getStores', appId, channelId],
    queryFn: () => salesChannelsServices.getStores(appId, channelId),
    enabled: !!appId,
  });

  useEffect(() => {
    if (isErrorChannels) {
      notifyError();
    }
  }, [isErrorChannels]);

  const [getAlreadyAssignedStores, setGetAlreadyAssignedStores] = useState(getAssignedStores || []);

  const stores = useMemo(() => {
    if (getStoreHeaders && getAlreadyAssignedStores) {
      const unassignedStores = getStoreHeaders
        .filter((all) => !getAlreadyAssignedStores.some((assigned) => assigned === all.StoreId))
        .map((s) => {
          const salesChannelInfo = storeIdAssociations.find(
            (association) => association.storeId === s.StoreId
          );
          return {
            label: `${s.Name}${getSalesChannelType(salesChannelInfo?.salesChannelType ?? '')}`,
            value: s.StoreId ? s.StoreId.toString() : '',
          };
        });

      const assignedStores = getAlreadyAssignedStores.map((s) => {
        const salesChannelInfo = storeIdAssociations.find(
          (association) => association.storeId === s.StoreId
        );
        return {
          label: `${s.Name}${getSalesChannelType(salesChannelInfo?.salesChannelType ?? '')}`,
          value: s.StoreId ? s.StoreId.toString() : '',
        };
      });
      return { assignedStores, unassignedStores };
    }
  }, [getStoreHeaders, getAlreadyAssignedStores, storeIdAssociations]);

  const assignStoresToAppType = useMutation({
    mutationFn: ({ storeId }: { storeId: number }) =>
      salesChannelsServices.assignStoresToAppType(appId, channelId, storeId),

    onSuccess: () => {
      notifySaved('Kiosk_successfully_assigned_store');
    },

    onError: () => {
      notifyError();
    },
  });

  const unassignStoreFromAppType = useMutation({
    mutationFn: ({ storeId }: { storeId: number }) =>
      salesChannelsServices.deleteStoreFromAppType(appId, channelId, storeId),

    onSuccess: () => {
      notifySaved('Kiosk_successfully_unassigned_store');
    },

    onError: () => {
      notifyError();
    },
  });

  useEffect(() => {
    if (getAssignedStores) {
      setGetAlreadyAssignedStores(getAssignedStores);
    }
  }, [getAssignedStores, channelId]);

  useEffect(() => {
    if (selectAction) {
      switch (selectAction.action) {
        case 'select-option':
          if (selectAction.option?.value) {
            assignStoresToAppType.mutateAsync({
              storeId: Number(selectAction.option.value),
            });
          }
          break;
        case 'remove-value':
          if (selectAction.values?.length) {
            unassignStoreFromAppType.mutateAsync({
              storeId: Number(selectAction.removedValue?.value),
            });
          } else {
            notifyError('You_should_have_at_least_one_store_assigned');
          }
          break;
        default:
          break;
      }
    }
  }, [selectAction]);

  // Assign the store to the app type if there is only one store
  useEffect(() => {
    if (getStoreHeaders?.length === 1) {
      const singleStore = getStoreHeaders[0];

      if (getAssignedStores?.length === 0) {
        assignStoresToAppType.mutateAsync({ storeId: Number(singleStore.StoreId) });
      }
    }
  }, [getStoreHeaders, getAssignedStores]);

  return (
    <>
      {showTitle && (
        <Typography variant="subtitle1">
          {appType ? (
            <>
              <Translate id={appType} />{' '}
              {lowerCase(translate('Sales_channels_visibility') as string)}
            </>
          ) : (
            <Translate id={'Sales_channels_visibility'} />
          )}
        </Typography>
      )}
      <Typography variant="caption" component="p">
        {description}
      </Typography>
      <div className={classes.container}>
        <StoreFilterInfiniteScroll
          customStoreOptions={stores?.unassignedStores}
          doNotAllowLastChipToBeRemoved
          hasAllStoresOption={false}
          isClearable={false}
          isCustomLoading={isPending}
          isDisabled={isDisabled}
          isMulti
          label={'Select_sales_channels'}
          placeholder={'Sales_channels'}
          preSelectedStores={stores?.assignedStores}
          setSelectAction={setSelectAction}
          useCustomStoreOptions
          useLoadingSkeleton
          variant="standard"
          zIndex={9}
        />
      </div>
    </>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (message?: TranslationId) =>
    dispatch(
      notifyError({
        message: message || 'Error_please_try_again_later',
        translate: true,
      })
    ),
  notifySaved: (message: TranslationId) => {
    dispatch(
      notify({
        message,
        variant: 'success',
        translate: true,
      })
    );
  },
});

export default connect(null, mapDispatchToProps)(AssignStoresVisibility);
