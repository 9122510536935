import React, { useEffect } from 'react';

import 'core-js/stable';
import CssBaseline from '@mui/material/CssBaseline';
import type { Theme } from '@mui/material/styles';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'react-redux';

import CookieSettingsInitializer from './components/CookiePreferences/CookieSettingsInitializer';
import NotificationProvider from './components/NotificationProvider';
import { ensureAppIdInUrlPath } from './helpers/ensureAppIdInUrlPath';
import { store } from './helpers/store';
import setupUserLanguage from './languageSetup';
import Notifier from './layouts/Notify/Notifier';
import ErrorBoundary from './layouts/Portal/ErrorBoundary';
import AuthRouter from './routes/AuthRouter';
import BrowserRouter from './routes/BrowserRouter';
import RedirectOrgToBrand from './routes/RedirectOrgToBrand';
import { closeConnection } from './services/signalr';
import configMoment from './startup/moment';
import { getTheme } from './styles/theme';
import OfflineDetector from './ui/OfflineDetector/OfflineDetector';

const theme: Theme = getTheme('light');
export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      closeConnection();
    });
    configMoment();
    setupUserLanguage();
  }, []);

  ensureAppIdInUrlPath();

  return (
    <Provider store={store}>
      <ErrorBoundary identifier="app-root">
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline>
                  <OfflineDetector />
                  <NotificationProvider>
                    <Notifier />
                    <RedirectOrgToBrand />
                    <AuthRouter />
                    {process.env.VITE_REACT_QUERY_DEV_TOOLS && <ReactQueryDevtools initialIsOpen />}
                    <CookieSettingsInitializer />
                  </NotificationProvider>
                </CssBaseline>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
