import React from 'react';

import { BankAccountDetail } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import { countryCodeToCountry } from '../../banking.selectors';
import { getDynamicLabel } from '../../components/bankAccFieldTextHelpers/DynamicLabel';
import formatDynamicValue from '../../components/bankAccFieldTextHelpers/DynamicValue';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 24,
    },
    column: {
      paddingBottom: '20px',
      paddingTop: '26px',
      [theme.breakpoints.up('xs')]: {
        paddingTop: theme.spacing(3),
      },
    },
    gridItem: {
      padding: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
    },
  })
);

const renderDynamicFields = (account: BankAccountDetail) => {
  const { PopulatedAccountFields } = account;

  const dynamicAccountDetails = PopulatedAccountFields?.map(function (field, index) {
    const { Key, Value } = field;
    const translatedKey = getDynamicLabel(Key);
    return (
      <TextField
        variant="standard"
        key={index}
        //@ts-ignore
        label={<Translate id={translatedKey} />}
        defaultValue={formatDynamicValue(Value, translatedKey)}
        margin="none"
        fullWidth
        InputProps={{ readOnly: true, disableUnderline: true }}
        data-fd={Key}
      />
    );
  });
  return dynamicAccountDetails;
};

type Props = {
  account: BankAccountDetail;
};

const DynamicAccountDetail = ({ account }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
      <Grid container>
        <Grid item xs={12} sm={5}>
          <Typography variant="subtitle1">
            <Translate id="Bank_account" />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField
            variant="standard"
            label={<Translate id="Account_name" />}
            defaultValue={account.AccountName}
            margin="none"
            fullWidth
            InputProps={{ readOnly: true, disableUnderline: true }}
            data-fd={account.AccountName}
          />

          <TextField
            variant="standard"
            label={<Translate id="Account_type" />}
            defaultValue={account.BusinessType}
            margin="none"
            fullWidth
            InputProps={{ readOnly: true, disableUnderline: true }}
            data-fd={account.BusinessType}
          />

          <TextField
            variant="standard"
            label={<Translate id={'Bank_name'} />}
            defaultValue={account.BankName}
            margin="none"
            fullWidth
            InputProps={{ readOnly: true, disableUnderline: true }}
            data-fd={account.BankName}
          />

          <TextField
            variant="standard"
            label={<Translate id="Address" />}
            defaultValue={account.BankAddress}
            fullWidth
            multiline
            maxRows="5"
            InputProps={{ readOnly: true, disableUnderline: true }}
            data-fd={'bank-address'}
          />

          <TextField
            variant="standard"
            label={<Translate id={'Bank_country'} />}
            defaultValue={countryCodeToCountry(account.BankCountryCode)}
            fullWidth
            InputProps={{ readOnly: true, disableUnderline: true }}
            data-fd={account.BankCountryCode}
          />

          {renderDynamicFields(account)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DynamicAccountDetail;
