import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Translate } from 'react-localize-redux';

import checkeredBackground from '../../../assets/images/checkered.svg';
import { formikValidate } from '../../../helpers/validation';
import FileUpload from '../../../ui/FileUpload';
import FormItemLayout from '../../../ui/Forms/FormItemLayout';
import FormItem, { RenderProps } from './FormItem';

const fileReader = new FileReader();

const validateLogo = (value: File, setLogo: (data: string | null) => void) =>
  new Promise<void | JSX.Element | string>((resolve) => {
    if (!(value instanceof File)) {
      resolve();
    }
    const isNotValidExtension = formikValidate.extension(value, ['png', 'jpeg', 'jpg']);

    if (isNotValidExtension) {
      resolve(isNotValidExtension);
    }

    if (value) {
      fileReader.readAsDataURL(value);
      fileReader.onload = () => {
        if (fileReader.result) {
          setLogo(fileReader.result as string);
        }
      };
      fileReader.onerror = () => {
        setLogo(null);
        resolve(<Translate id="Error_please_try_again_later" />);
      };
    }
    resolve();
  });

type Props = {
  logoUrl: string;
};

const StyledLogoWrapper = styled('div')(() => ({
  width: 144,
  padding: '15px 15px 15px 15px',
  backgroundImage: `url("${checkeredBackground}")`,
  backgroundSize: 'auto 110%',
  backgroundPositionY: '-15px',
  backgroundRepeat: 'no-repeat',
}));

const LogoField = ({ logoUrl }: Props) => {
  const [logo, setLogo] = useState<string | null>(logoUrl);

  useEffect(() => {
    setLogo((prevUrl) => {
      if (!prevUrl || prevUrl === logoUrl) {
        return logoUrl;
      } else return prevUrl;
    });
  }, [logoUrl]);

  return (
    <FormItemLayout label={<Translate id="Logo" />}>
      <FormItem
        name="logo"
        // @ts-ignore
        validate={(value: File) => validateLogo(value, setLogo)}
      >
        {({ field: { name }, form: { setFieldValue } }: RenderProps) => (
          <StyledLogoWrapper>
            <FileUpload
              fdKey="logo"
              url={logo}
              onChange={(data?: File) => {
                setFieldValue(name, data);
                if (!data) {
                  setLogo(null);
                }
              }}
            />
          </StyledLogoWrapper>
        )}
      </FormItem>
    </FormItemLayout>
  );
};

export default LogoField;
