import React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import InputLabel from '@mui/material/InputLabel';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { type FieldProps, type FormikProps, Field, withFormik } from 'formik';
import memoize from 'memoize-one';
import * as moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import { getActiveLanguage, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getDateTimeFormatter } from '../../../selectors/localeDateTime.selector';
import type { BusinessHoursPeriodBase, DeliveryType } from '../types.d';
import { generatePayload } from './utils';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    height: '60px',
  },
  label: {
    position: 'initial',
    transform: 'none',
    marginRight: theme.spacing(2),
    textTransform: 'capitalize',
    width: '48px',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  loader: {
    height: '24px',
    width: '100%',
    lineHeight: '1.5',
  },
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
  copyButton: {
    paddingLeft: 0,
    textTransform: 'none',
    '& .MuiSvgIcon-root': {
      marginRight: 0,
      fontSize: '1rem',
    },
  },
}));

const Loader = ({ className }) => (
  <div className={className}>
    <Skeleton height={'100%'} />
  </div>
);

type InnerProps = MappedState & FormikProps<FormFieldProps>;
type OuterProps = {
  id: string;
  loading: boolean;
  label: string;
  name: string;
  openingHour: string | undefined;
  autoFocus?: boolean;
  disabled?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  deliveryType: DeliveryType;
  update(data: BusinessHoursPeriodBase): Promise<void>;
  updateAllDays(data: BusinessHoursPeriodBase): Promise<void>;
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getClosedKeyTester = memoize((text: string) => new RegExp(`${text}`, 'i'));
  const getAllDayKeyTester = memoize((text: string) => new RegExp(`${text}`, 'i'));

  return (state: AppState) => {
    const { time, timeLocal, timePeriod } = getDateTimeFormatter(state);
    const translate = getTranslate(state.locale);
    const locale = getActiveLanguage(state.locale);
    const isClosedTester = getClosedKeyTester(translate('Closed') as string);
    const isAllDayTester = getAllDayKeyTester(translate('All_day') as string);

    return {
      time,
      timeLocal,
      timePeriod,
      isClosedTester,
      isAllDayTester,
      translate: getTranslate(state.locale),
      locale,
    };
  };
};

type FormValues = { [key: string]: string };

export type FormFieldProps = {
  loading: boolean;
  label: string;
  name: string;
  openingHour: string | undefined;
  autoFocus?: boolean;
  disabled?: boolean;
  deliveryType: DeliveryType;
  update(data: BusinessHoursPeriodBase): Promise<void>;
  updateAllDays(data: BusinessHoursPeriodBase): Promise<void>;
};
type Props = InnerProps & OuterProps;
const createFormField = (formName) =>
  compose<InnerProps, OuterProps>(
    connect(mapStateToPropsFactory),
    withFormik<Props, FormValues>({
      displayName: `OpeningHoursFormField${formName}`,
      enableReinitialize: true,
      mapPropsToValues(props) {
        const { openingHour, name } = props;
        if (openingHour) {
          return {
            [name]: openingHour,
          };
        }

        return {};
      },

      handleSubmit(values, formikBag) {
        const {
          props: { update, time, timeLocal, timePeriod, isAllDayTester, isClosedTester, name },
        } = formikBag;
        const value = values[name];
        const payload = generatePayload(name, value, {
          time,
          timeLocal,
          timePeriod,
          isClosedTester,
          isAllDayTester,
        });
        update(payload);
      },
    })
  )((props: Props) => {
    const {
      id,
      name,
      label,
      errors,
      isLast,
      isFirst,
      loading,
      disabled,
      timeLocal,
      translate,
      openingHour,
      handleSubmit,
      isAllDayTester,
      isClosedTester,
      deliveryType,
      updateAllDays,
    } = props;

    const classes = useStyles();
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      // @ts-ignore
      const { value } = e.target;
      if (e.key === 'Enter') {
        if (openingHour && openingHour[name] !== value) {
          if (value === '' || validate(value)) {
            handleSubmit();
          }
        }
      }
    };
    const onBlur = (e: any) => {
      if (openingHour === 'closed' && e.target.value === '') {
        return;
      }
      if (e.target.value !== openingHour) {
        handleSubmit();
      }
    };
    const timeValidator = (value) => {
      if (value.length < 1) {
        // don't bother
        return;
      }

      const error = translate(`Invalid_format`, undefined, {
        missingTranslationMsg: `Invalid format!`,
      });

      const earlyLateParts = value.split(',');
      if (earlyLateParts.length > 2) {
        return error;
      }
      const valid = validate(value);

      if (valid) {
        return;
      }
      return error;
    };

    const validate = (value: string): boolean => {
      if (value.length < 3) {
        return false;
      }

      if (isClosedTester.test(value) || isAllDayTester.test(value)) {
        return true;
      }

      const earlyLateParts = value.split(',');
      if (earlyLateParts.length > 2) {
        return false;
      }
      const valid = earlyLateParts.every((rawRange: string) => {
        const leftRightTime = rawRange.split('-');
        if (leftRightTime.length !== 2) {
          return false;
        }

        const lTime = timeLocal(leftRightTime[0].trim()) as moment.Moment;
        const rTime = timeLocal(leftRightTime[1].trim()) as moment.Moment;
        if (lTime.isValid() && rTime.isValid()) {
          return true;
        }
        return false;
      });

      return valid;
    };

    return (
      <Field name={name} validate={timeValidator}>
        {(fProps: FieldProps) => {
          const {
            field: { value },
            form: { setFieldValue },
          } = fProps;
          const error = errors[name];

          return (
            <>
              <Hidden {...(isLast ? { mdUp: true } : {})}>
                <Grid item xs={2} className={classes.gridItem}>
                  <InputLabel htmlFor={name} className={classes.label}>
                    {loading ? <Loader className={classes.loader} /> : label}
                  </InputLabel>
                </Grid>
              </Hidden>
              <Grid item xs={6} className={classes.gridItem}>
                {loading ? (
                  <Loader className={classes.loader} />
                ) : (
                  <TextField
                    variant="standard"
                    fullWidth
                    error={!!error}
                    helperText={error}
                    placeholder={value ? '' : 'closed'}
                    autoComplete={'off'}
                    onKeyDown={disabled ? undefined : onKeyDown}
                    disabled={disabled}
                    value={value || ''}
                    onBlur={onBlur}
                    InputProps={{
                      inputProps: {
                        style: { height: '1.1876em' },
                        'data-fd': `${id}.${name}`,
                      },
                      id: name,
                    }}
                    onChange={(e) => {
                      setFieldValue(name, e.target.value);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                {isFirst && (
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.copyButton}
                    startIcon={<ContentCopyIcon />}
                    data-delivery-type={deliveryType}
                    onClick={() => {
                      const payload = generatePayload(name, value, {
                        time: props.time,
                        timeLocal,
                        timePeriod: props.timePeriod,
                        isClosedTester: props.isClosedTester,
                        isAllDayTester: props.isAllDayTester,
                      });
                      updateAllDays(payload);
                    }}
                  >
                    {translate('Copy_to_all')}
                  </Button>
                )}
              </Grid>
              <Hidden {...(isLast ? { mdDown: true } : { xsUp: true })}>
                <Grid item xs={2} className={classes.gridItem} />{' '}
              </Hidden>
            </>
          );
        }}
      </Field>
    );
  });

export { createFormField };
