import { BusinessHoursPeriod, BusinessHoursPeriodBase } from '@flipdish/api-client-typescript';

import { DeliveryType } from '../components/OpeningHours/types';
import * as storeOpeningHoursConstants from '../constants/storeOpeningHours.constants';
import {
  closeAllOtherNotifications,
  closeNotifySaving,
  ERROR_KEY,
  notifyError,
  notifySaved,
  notifySaving,
  SAVED_KEY,
  SAVING_KEY,
} from '../layouts/Notify/actions';
import { getSelectedStoreId } from '../selectors/store.selector';
import { loadByDeliveryType, update as updateService } from '../services/storeOpeningHours.service';
import { action, actionError } from './utils';

export const isOpeningHoursLoaded = (
  storeId: number,
  deliveryType: DeliveryType,
  state: AppState
) =>
  state.openingHours.data[storeId] &&
  Object.keys(state.openingHours.data[storeId][deliveryType] || {}).length > 0;

// #region loadDelivery
const { LOAD_DELIVERY_REQUEST, LOAD_DELIVERY_SUCCESS, LOAD_DELIVERY_FAILURE } =
  storeOpeningHoursConstants;

export type LoadDeliveryRequest = ReturnType<typeof loadDeliveryRequest>;
export const loadDeliveryRequest = (storeId: number) => action(LOAD_DELIVERY_REQUEST, { storeId });

export type LoadDeliverySuccess = ReturnType<typeof loadDeliverySuccess>;
export const loadDeliverySuccess = (storeId: number, businessHoursPeriods: BusinessHoursPeriod[]) =>
  action(LOAD_DELIVERY_SUCCESS, businessHoursPeriods, { storeId });

export type LoadDeliveryFailure = ReturnType<typeof loadDeliveryFailure>;
export const loadDeliveryFailure = (storeId: number, error: Error) =>
  actionError(LOAD_DELIVERY_FAILURE, { storeId }, error);

export const loadDelivery = (specificStoreId?: number) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const storeId = specificStoreId || getSelectedStoreId(state) || 0;
    if (!storeId) {
      return console.error(new Error('StoreId is required!'));
    }
    try {
      if (isOpeningHoursLoaded(storeId, 'Delivery', state)) {
        return Promise.resolve();
      }

      dispatch(loadDeliveryRequest(storeId));

      const data = await loadByDeliveryType({ storeId, deliveryType: 'Delivery' });
      dispatch(loadDeliverySuccess(storeId, data));
    } catch (error) {
      dispatch(loadDeliveryFailure(storeId as number, error));
    }
  };
};
// #endregion
// #region loadPickup
const { LOAD_PICKUP_REQUEST, LOAD_PICKUP_SUCCESS, LOAD_PICKUP_FAILURE } =
  storeOpeningHoursConstants;

export type LoadPickupRequest = ReturnType<typeof loadPickupRequest>;
export const loadPickupRequest = (storeId: number) => action(LOAD_PICKUP_REQUEST, { storeId });

export type LoadPickupSuccess = ReturnType<typeof loadPickupSuccess>;
export const loadPickupSuccess = (storeId: number, businessHoursPeriods: BusinessHoursPeriod[]) =>
  action(LOAD_PICKUP_SUCCESS, businessHoursPeriods, { storeId });

export type LoadPickupFailure = ReturnType<typeof loadPickupFailure>;
export const loadPickupFailure = (storeId: number, error: Error) =>
  actionError(LOAD_PICKUP_FAILURE, { storeId }, error);

export const loadPickup = (specificStoreId?: number) => {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const storeId = specificStoreId || getSelectedStoreId(state) || 0;
    if (!storeId) {
      return console.error(new Error('StoreId is required!'));
    }
    try {
      if (isOpeningHoursLoaded(storeId, 'Pickup', state)) {
        return Promise.resolve();
      }

      dispatch(loadPickupRequest(storeId));

      const data = await loadByDeliveryType({ storeId, deliveryType: 'Pickup' });
      dispatch(loadPickupSuccess(storeId, data));
    } catch (error) {
      dispatch(loadPickupFailure(storeId, error));
    }
  };
};
// #endregion

// #region update
const { UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE } = storeOpeningHoursConstants;

export type UpdateRequest = ReturnType<typeof updateRequest>;
export const updateRequest = (
  storeId: number,
  deliveryType: DeliveryType,
  businessHoursPeriod: BusinessHoursPeriodBase
) => action(UPDATE_REQUEST, { storeId, deliveryType, businessHoursPeriod });

export type UpdateSuccess = ReturnType<typeof updateSuccess>;
export const updateSuccess = (
  storeId: number,
  deliveryType: DeliveryType,
  businessHoursPeriod: BusinessHoursPeriod
) => action(UPDATE_SUCCESS, { storeId, deliveryType, businessHoursPeriod });

export type UpdateFailure = ReturnType<typeof updateFailure>;
export const updateFailure = (
  storeId: number,
  deliveryType: DeliveryType,
  businessHoursPeriod: BusinessHoursPeriodBase,
  error: Error
) => actionError(UPDATE_FAILURE, { storeId, deliveryType, businessHoursPeriod }, error);

export const updateOpeningHours = (
  storeId: number,
  deliveryType: DeliveryType,
  businessHoursPeriod: BusinessHoursPeriodBase
) => {
  return async (dispatch, getState) => {
    try {
      dispatch(closeAllOtherNotifications(SAVING_KEY));
      dispatch(notifySaving());

      dispatch(updateRequest(storeId, deliveryType, businessHoursPeriod));

      const result = await updateService(storeId, deliveryType, businessHoursPeriod);
      dispatch(updateSuccess(storeId, deliveryType, result as BusinessHoursPeriod));
      dispatch(closeAllOtherNotifications(SAVED_KEY));
      dispatch(notifySaved());
    } catch (error) {
      dispatch(closeNotifySaving());
      dispatch(updateFailure(storeId, deliveryType, businessHoursPeriod, error));
      dispatch(closeAllOtherNotifications(ERROR_KEY));
      dispatch(notifyError({ message: error.message }));
    }
  };
};

export const updateBulkOpeningHours = (
  storeId: number,
  deliveryType: DeliveryType,
  businessHoursPeriods: BusinessHoursPeriodBase[]
) => {
  return async (dispatch, getState) => {
    dispatch(closeAllOtherNotifications(SAVING_KEY));
    dispatch(notifySaving());

    await Promise.all(
      businessHoursPeriods.map(async (businessHoursPeriod) => {
        try {
          dispatch(updateRequest(storeId, deliveryType, businessHoursPeriod));
          const result = await updateService(storeId, deliveryType, businessHoursPeriod);
          dispatch(updateSuccess(storeId, deliveryType, result as BusinessHoursPeriod));
        } catch (error) {
          dispatch(closeNotifySaving());
          dispatch(updateFailure(storeId, deliveryType, businessHoursPeriod, error));
          dispatch(closeAllOtherNotifications(ERROR_KEY));
          dispatch(notifyError({ message: error.message }));
        }
      })
    );

    dispatch(closeAllOtherNotifications(SAVED_KEY));
    dispatch(notifySaved());
  };
};

export const updateAllDays = (
  storeId: number,
  deliveryType: DeliveryType,
  sourceHours: BusinessHoursPeriodBase
) => {
  return async (dispatch, getState) => {
    dispatch(closeAllOtherNotifications(SAVING_KEY));
    dispatch(notifySaving());

    const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const businessHoursPeriods = allDays.map((day) => ({
      ...sourceHours,
      DayOfWeek: day as unknown as BusinessHoursPeriodBase['DayOfWeek'],
    }));

    await Promise.all(
      businessHoursPeriods.map(async (businessHoursPeriod) => {
        try {
          dispatch(updateRequest(storeId, deliveryType, businessHoursPeriod));
          const result = await updateService(storeId, deliveryType, businessHoursPeriod);
          dispatch(updateSuccess(storeId, deliveryType, result as BusinessHoursPeriod));
        } catch (error) {
          dispatch(closeNotifySaving());
          dispatch(updateFailure(storeId, deliveryType, businessHoursPeriod, error));
          dispatch(closeAllOtherNotifications(ERROR_KEY));
          dispatch(notifyError({ message: error.message }));
        }
      })
    );

    dispatch(closeAllOtherNotifications(SAVED_KEY));
    dispatch(notifySaved());
  };
};
// #endregion

// #region reset
const { RESET } = storeOpeningHoursConstants;
export const reset = () => action(RESET);
// #endregion
