import axios, { CancelTokenSource } from 'axios';
import upperFirst from 'lodash/upperFirst';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapKeysDeep } from '../../helpers/objects';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseUrl = getApiEndPoint();
const portalApi = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
});

// #region getAuditLogs
export type Params = {
  page: number;
  limit: number;
  start?: string;
  end?: string;
  FlipdishEventId?: string;
};

let cancelToken: CancelTokenSource | null = null;
export const cancelErrorMessage = 'Canceled by user';

export type AuditLog =
  | Flipdish.AppCreatedEvent
  | Flipdish.AppUpdatedEvent
  | Flipdish.CustomerUpdatedEvent
  | Flipdish.CustomerConsentUpdatedEvent
  | Flipdish.StoreBusinessHoursOverrideCreatedEvent
  | Flipdish.StoreBusinessHoursOverrideDeletedEvent
  | Flipdish.UserUpdatedEvent
  | Flipdish.StoreUpdatedEvent;

type ExtendedAuditLog = {
  OrgId: string;
  EventId: string;
  OccurredAt: string;
  EventType: string;
  Event: AuditLog;
};

type RestApiAuditLogs = Flipdish.RestApiPaginationResult<AuditLog>;
export const getAuditLogs = async (appId: string, params: Params) => {
  try {
    if (cancelToken) {
      cancelToken.cancel(cancelErrorMessage);
    }

    cancelToken = axios.CancelToken.source();
    const incomingMessage = await portalApi.get<RestApiAuditLogs>(`/api/v1.0/${appId}/auditlogs`, {
      params,
      cancelToken: cancelToken.token,
    });

    cancelToken = null;

    return incomingMessage.data;
  } catch (e) {
    if (e.message && e.message === cancelErrorMessage) {
      throw e;
    }

    const err = await mapServerError(e);
    throw err;
  }
};

const getAuditLogsForOrg = async (orgId: string, params: Params) => {
  try {
    if (cancelToken) {
      cancelToken.cancel(cancelErrorMessage);
    }

    cancelToken = axios.CancelToken.source();

    const incomingMessage = await portalApi.get<Flipdish.RestApiPaginationResult<ExtendedAuditLog>>(
      `/platform/auditlogs/v1/org/${orgId}`,
      {
        params,
        cancelToken: cancelToken.token,
      }
    );

    cancelToken = null;

    const inPascalCase = mapKeysDeep(incomingMessage.data, upperFirst);

    return {
      ...inPascalCase,
      Data: inPascalCase.Data.map((log) => {
        return {
          ...log.Event,
          CreateTime: log.Event.CreateTime || log.OccurredAt,
          EventName: log.Event.EventName || log.EventType,
          FlipdishEventId: log.Event.FlipdishEventId || log.EventId,
        };
      }),
    };
  } catch (e) {
    if (e.message && e.message === cancelErrorMessage) {
      throw e;
    }

    const err = await mapServerError(e);
    throw err;
  }
};
// #endregion

// #region getPushNotificationLogs
export type GetPushNotificationParams = {
  appId: string;
  page?: number;
  limit?: number;
  start?: string;
  end?: string;
};

export type RestApiPaginationResult<T> = {
  Data: T;
  Page: number;
  Limit: number;
  TotalRecordCount: number;
};

// #endregion

export const service = {
  getAuditLogs,
  getAuditLogsForOrg,
};
