import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import qs from 'qs';
import { getActiveLanguage, getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import StoreByCurrencyFilter from '@fd/ui/Filter/StoreByCurrencyFilter';

import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { useTracking } from '../../../services/amplitude/useTracking';
import GenericTableTitle from '../../../ui/GenericTable/GenericTableTitle';
import PageLayout from '../../../ui/Layout';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import DateRangeSelector from '../components/Filters/DateRangeSelector';
import OrderOverview from '../components/OrderOverview';
import {
  getPreviousPeriod,
  onPresetPeriodChange,
  presetRanges,
  renderPeriodString,
} from '../helpers';
import { getOrderReportOverview } from '../OrderReport.actions';

type PeriodProps = {
  SectionTitle: TranslationId;
  Type: 'Value' | 'Count';
  Data: Array<{ key: string; value: number }>;
  Count: number;
};

type PeriodFilterProps = {
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
};
type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type Props = MappedDispatch & MappedProps;

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

const ReportsOverview = (props: Props) => {
  const { getOrderReportOverview, languageCode, translate } = props;
  const classes = useStyles();
  const { search } = useLocation();

  const emptyFilter = { value: 0, startDate: undefined, endDate: undefined };
  const [currency, setCurrency] = useState<CurrencyEnum>();
  const [storeIds, setStoreIdsFilter] = useState<number[]>([]);
  const [currentPeriodFilter, setCurrentPeriodFilter] = useState<PeriodFilterProps>({
    startDate: moment().startOf('week'),
    endDate: moment().endOf('week'),
  });
  const [previousPeriodFilter, setPreviousPeriodFilter] = useState<PeriodFilterProps>(emptyFilter);

  const [currentPeriod, setCurrentPeriod] = useState<PeriodProps[]>([]);
  const [previousPeriod, setPreviousPeriod] = useState<PeriodProps[]>([]);

  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_reports_overview', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    const values = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    if (!isEmpty(values.store)) {
      let storesFilter: number[] = [];
      if (isArray(values.store)) {
        storesFilter = values.store.map((v) => parseInt(v, 10));
      } else if (values.store) {
        storesFilter.push(parseInt(values.store as any, 10));
      }
      selectStores(storesFilter);
    }
  }, []);

  //#region Initial search
  const getRouteSearch = () => {
    if (search) {
      const searchProps = qs.parse(search, {
        ignoreQueryPrefix: true,
      });
      return searchProps || {};
    }
    return undefined;
  };

  const getPeriod = (daterangeUrl?: string, fpstart?: string, fpend?: string) => {
    let startDate;
    let endDate;
    if (daterangeUrl) {
      // If we have daterange params
      const preset = presetRanges.find((pr) => pr.url === daterangeUrl)!;
      const { start, end } = onPresetPeriodChange(preset.value);
      startDate = start;
      endDate = end;
    } else {
      // If there is no daterange, we can have start/end period in the url
      const start = moment(fpstart, 'YYYY-MM-DD').startOf('day');
      const end = moment(fpend, 'YYYY-MM-DD').endOf('day');
      startDate = start;
      endDate = end;
    }
    return { startDate, endDate };
  };

  const getComparePeriod = (
    comparewith: string | undefined,
    daterangeUrl: string,
    start: moment.Moment,
    end: moment.Moment
  ) => {
    let urlPrevPeriod;

    if (comparewith) {
      // Handle previous period
      if (daterangeUrl) {
        if (comparewith === 'previousyear') {
          // Compare with last year
          urlPrevPeriod = {
            value: 20,
            url: comparewith,
            startDate: moment()
              .year(moment(start).year() - 1)
              .startOf('day'),
            endDate: moment()
              .year(moment(end).year() - 1)
              .endOf('day'),
          };
        } else {
          // Compare with previous period
          const event = { target: { value: 10 } };
          const sp = getPreviousPeriod(event, { moment: start }, { moment: end }, daterangeUrl);
          urlPrevPeriod = sp;
        }
      } else {
        // If no daterange
        // Get comparison period start / end
        if (comparewith === 'previousyear') {
          // Compare with last year
          urlPrevPeriod = {
            value: 20,
            url: comparewith,
            startDate: moment()
              .year(moment(start).year() - 1)
              .startOf('day'),
            endDate: moment()
              .year(moment(end).year() - 1)
              .endOf('day'),
          };
        } else {
          // Compare with previous period
          const event = { target: { value: 10 } };
          urlPrevPeriod = getPreviousPeriod(event, { moment: start }, { moment: end }, 'custom');
        }
      }
    }
    return urlPrevPeriod;
  };

  const getInitialQuery = () => {
    const currentSearch = getRouteSearch();
    if (currentSearch) {
      const mainPeriod = getPeriod(
        currentSearch.daterange as any,
        currentSearch.fp_start as any,
        currentSearch.fp_end as any
      );
      const comparePeriod = getComparePeriod(
        currentSearch.comparewith as any,
        currentSearch.daterange as any,
        mainPeriod.startDate,
        mainPeriod.endDate
      );
      const stores = currentSearch.store;

      getOrderOverview(mainPeriod, comparePeriod, stores);
    }
  };

  useEffect(() => {
    if (firstRender && currency) {
      setFirstRender(false);
      const timer = setTimeout(() => {
        getInitialQuery();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [search, currency]);
  //#endregion

  useEffect(() => {
    if (!firstRender && !loading && currency) {
      const timer = setTimeout(() => {
        if (!storeIds || (Array.isArray(storeIds) && !storeIds.length)) {
          setCurrentPeriod([]);
          setPreviousPeriod([]);
        } else {
          getOrderOverview(currentPeriodFilter, previousPeriodFilter, storeIds);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
    return;
  }, [currency, currentPeriodFilter, previousPeriodFilter, storeIds]);

  useEffect(() => {
    if (!previousPeriodFilter || !previousPeriodFilter.startDate) {
      // If we select "None" as period to compare
      setPreviousPeriod([]);
    }
  }, [previousPeriodFilter]);

  const getPeriodType = () => {
    const values = qs.parse(search, { ignoreQueryPrefix: true });
    return values.daterange ? values.daterange : 'custom';
  };

  const getOrderOverview = async (mainPeriod, comparePeriod, storeIds) => {
    try {
      setLoading(true);
      const periodType = getPeriodType();
      const resp = await getOrderReportOverview(mainPeriod, comparePeriod, periodType, storeIds);
      setCurrentPeriod(resp!.current as PeriodProps[]);
      setPreviousPeriod(resp!.previous as PeriodProps[]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const selectStores = (stores) => {
    setStoreIdsFilter(stores.map((store) => store.value));
    if (stores.length) {
      setCurrency(stores[0].currency);
    }
  };

  const onDateChange = (start: moment.Moment, end: moment.Moment, nextPeriod) => {
    setCurrentPeriodFilter({ startDate: start, endDate: end });
    if (nextPeriod) {
      setPreviousPeriodFilter(nextPeriod);
    }
  };

  const setInitialCurrency = (currency) => {
    setCurrency(currency);
  };

  const renderReportsOverview = (
    <Grid container>
      <GenericTableTitle
        title={'Orders'}
        subTitle={renderPeriodString(currentPeriodFilter, previousPeriodFilter)}
        navTo={`orders${search}`}
        fdKey={'Orders_overview'}
      />
      <ErrorBoundary identifier="reports-overview-order">
        <PaperContainer fluid>
          <List component="nav" style={{ padding: '0px' }}>
            <OrderOverview
              loading={loading}
              currency={currency}
              languageCode={languageCode}
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
            />
          </List>
        </PaperContainer>
      </ErrorBoundary>
    </Grid>
  );

  const renderFilters = (
    <GridContainer>
      <ErrorBoundary identifier="reports-overview-filters">
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <DateRangeSelector translate={translate} onChange={onDateChange} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <StoreByCurrencyFilter
            onSelectStore={selectStores}
            setInitialCurrency={setInitialCurrency}
          />
        </Grid>
      </ErrorBoundary>
    </GridContainer>
  );

  return (
    <PageLayout header={renderFilters} title={<Translate id="Reports" />} documentTitle="Reports">
      {renderReportsOverview}
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    languageCode: getActiveLanguage(locale),
    translate: getTranslate(locale),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getOrderReportOverview: (mainPeriod, comparePeriod, periodType, storeIds) =>
    dispatch(getOrderReportOverview(mainPeriod, comparePeriod, periodType, storeIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsOverview);
