import React from 'react';

import { styled } from '@mui/material/styles';
import { useField, useFormikContext } from 'formik';

import { type CheckboxFieldProps, CheckboxField } from '../atoms/CheckboxField/CheckboxField';

const StyledCheckbox = styled(CheckboxField)`
  margin: ${({ theme }) => theme.spacing(1.5)};
`;

type FkCheckboxProps = Omit<CheckboxFieldProps, 'checked' | 'onChange' | 'fdKey'> & {
  /**
   * Field name in Formik form
   */
  fieldName: string;
  fdKey: string;
  /**
   * Optional callback to set other form values when checkbox changes
   * @param checked - The new checkbox state
   * @param setFieldValue - Formik's setFieldValue function
   */
  onCheckboxChange?: (checked: boolean, setFieldValue: (field: string, value: any) => void) => void;
};

/**
 * Formik-connected checkbox component
 * Uses the base CheckboxField component with Formik integration
 */
export const FkCheckbox: React.FC<FkCheckboxProps> = ({
  fieldName,
  fdKey,
  onCheckboxChange,
  ...props
}) => {
  const [field, , helpers] = useField(fieldName);
  const { setFieldValue } = useFormikContext();

  const handleChange = (checked: boolean) => {
    helpers.setValue(checked);
    onCheckboxChange?.(checked, setFieldValue);
  };

  return <StyledCheckbox {...props} checked={field.value} onChange={handleChange} fdKey={fdKey} />;
};

export default FkCheckbox;
