import React from 'react';

import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import MultiSelect from '@fd/ui/Select/Select';

export type Props = {
  options: Array<{
    label?: string;
    salesChannelType?: string;
    scId?: string;
    value?: number;
  }>;
};
const SalesChannelGroupSelector = ({ options }: Props) => {
  return (
    <FormItemLayout label={<Translate id="Sales_channels" />}>
      <Field name="salesChannels">
        {({ field, form }: FieldProps) => (
          <MultiSelect
            dataFd="sales-channels-select"
            isClearable={false}
            isDisabled={form.isSubmitting}
            isMulti
            maxChips={5}
            value={field.value}
            options={options}
            variant="standard"
            TextFieldProps={{
              style: { paddingRight: 16 },
            }}
            onChange={(options: Props['options']) => {
              form.setFieldValue(field.name, options);
            }}
          />
        )}
      </Field>
    </FormItemLayout>
  );
};

export default SalesChannelGroupSelector;
