import React, { useMemo } from 'react';

import { OpeningHour, OpeningHourDispatchTypesEnum } from '@flipdish/orgmanagement';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Form, Formik } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { Button } from '@fd/ui/atoms';
import FkCheckbox from '@fd/ui/FkCheckbox/FkCheckbox';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { weekDaysLocal } from '../../selectors/storeOpeningHours.selector';
import PreventNavigation from '../Finance/Banking/components/PreventNavigation';
import Legend from '../OpeningHours/components/Legend';
import CopyToDeliveryHoursButton from './components/CopyToDeliveryHoursButton';
import WeekdayFields from './components/WeekdayFields';
import {
  type DailyOpeningHoursTimeStrings,
  convertDailyOpeningHoursTimeArraysToStrings,
  defaultDayValues,
  mapStringOpeningHoursToOpeningHours,
  mapTimeSlotsToDailyOpeningHours,
} from './utils';

const FieldsGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    '& > div': {
      paddingRight: theme.spacing(2),
      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
}));

const StyledLegendContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(1.5),
  },
}));

const StyledSaveContainer = styled(Grid)(({ theme }) => ({
  justifyContent: 'flex-end',
  paddingTop: theme.spacing(1),
}));

const StyledSaveButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: 'fit-content',
  },
}));

const getInitialValues = (
  openingHours: DailyOpeningHoursTimeStrings,
  inheritedOpeningHours: DailyOpeningHoursTimeStrings,
  shouldInheritOpeningHours: boolean
) => {
  if (inheritedOpeningHours?.Collection?.Monday && shouldInheritOpeningHours) {
    return { openingHours: inheritedOpeningHours, shouldInheritOpeningHours: true };
  }

  if (openingHours?.Collection?.Monday) {
    return { openingHours, shouldInheritOpeningHours: false };
  }
  return { openingHours: defaultDayValues, shouldInheritOpeningHours: false };
};

type OpeningHoursFormProps = {
  openingHours: OpeningHour[];
  inheritedOpeningHours?: OpeningHour[];
  isLoading: boolean;
  isSaving: boolean;
  updateOpeningHours: ({
    hours,
    shouldInheritOpeningHours,
  }: {
    hours: OpeningHour[];
    shouldInheritOpeningHours?: boolean;
  }) => Promise<void>;
  shouldInheritOpeningHours?: boolean;
  salesChannelId?: string;
};

type Props = OpeningHoursFormProps & MappedState;
const OpeningHoursForm = ({
  salesChannelId = '',
  openingHours,
  translate,
  isLoading,
  isSaving,
  updateOpeningHours,
  shouldInheritOpeningHours = true,
  inheritedOpeningHours,
  weekDays,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const openingHoursFromApi = useMemo(
    () =>
      convertDailyOpeningHoursTimeArraysToStrings(
        mapTimeSlotsToDailyOpeningHours(openingHours || [], translate)
      ),
    [openingHours]
  );

  const inheritedOpeningHoursFormatted = useMemo(
    () =>
      convertDailyOpeningHoursTimeArraysToStrings(
        mapTimeSlotsToDailyOpeningHours(inheritedOpeningHours || [], translate)
      ),
    [inheritedOpeningHours]
  );

  const handleInheritChange = (
    checked: boolean,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const newOpeningHours = checked ? inheritedOpeningHoursFormatted : openingHoursFromApi;
    if (newOpeningHours) {
      setFieldValue('openingHours', newOpeningHours);
    }
  };

  const initialValues = getInitialValues(
    openingHoursFromApi,
    inheritedOpeningHoursFormatted,
    shouldInheritOpeningHours
  );

  return (
    <PaperContainer>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (values) => {
          const arrayFormat = mapStringOpeningHoursToOpeningHours(values?.openingHours, translate);
          if (values?.shouldInheritOpeningHours) {
            await updateOpeningHours({
              hours: arrayFormat,
              shouldInheritOpeningHours: values?.shouldInheritOpeningHours,
            });
          } else {
            await updateOpeningHours({
              hours: arrayFormat,
            });
          }
        }}
      >
        {({ values, dirty }) => (
          <Form>
            <PreventNavigation when={dirty} />
            <FieldsGrid container>
              {!isMobile && (
                <StyledLegendContainer item xs={12} md={8}>
                  <Legend />
                </StyledLegendContainer>
              )}
              <StyledSaveContainer item container xs={12} md={4}>
                <StyledSaveButton
                  disabled={isSaving || isLoading || !dirty}
                  fullWidth={isMobile}
                  fdKey="submit"
                  type="submit"
                  name="Save"
                >
                  {translate('Save')}
                </StyledSaveButton>
                {isMobile && (
                  <StyledLegendContainer item xs={12} marginTop={2}>
                    <Legend />
                  </StyledLegendContainer>
                )}
              </StyledSaveContainer>
              {salesChannelId && (
                <Grid item xs={12}>
                  <FkCheckbox
                    fieldName="shouldInheritOpeningHours"
                    fdKey="shouldInheritOpeningHours"
                    ariaLabel={translate('Inherit_opening_hours') as string}
                    label={translate('Inherit_opening_hours') as string}
                    onCheckboxChange={handleInheritChange}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <WeekdayFields
                  isDisabled={values?.shouldInheritOpeningHours}
                  title="Opening_hours_collection_title"
                  type={OpeningHourDispatchTypesEnum.Collection}
                  isLoading={isLoading}
                  isSaving={isSaving}
                  showLabels={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <WeekdayFields
                  isDisabled={values?.shouldInheritOpeningHours}
                  title="Opening_hours_delivery_title"
                  type={OpeningHourDispatchTypesEnum.Delivery}
                  isLoading={isLoading}
                  isSaving={isSaving}
                  showLabels={isMobile}
                />
              </Grid>
            </FieldsGrid>
            <CopyToDeliveryHoursButton
              weekDays={weekDays}
              disabled={values?.shouldInheritOpeningHours}
            />
          </Form>
        )}
      </Formik>
    </PaperContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state),
  weekDays: weekDaysLocal(state),
});

export default connect(mapStateToProps)(OpeningHoursForm);
