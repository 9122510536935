const formatDynamicValue = (value: string | undefined, key: TranslationId): string => {
  if (value === undefined || value === null) {
    return '';
  }

  if (key === 'Iban') {
    return formatIBAN(value);
  }

  return value;
};

function formatIBAN(iban) {
  // Remove any existing spaces or non-alphanumeric characters
  const cleanedIBAN = iban.replace(/[^A-Za-z0-9]/g, '');
  // Add a space every four characters
  return cleanedIBAN.match(/.{1,4}/g)?.join(' ') || '';
}

export default formatDynamicValue;
