import React, { useCallback, useEffect, useState } from 'react';

import { RetentionCampaign } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';
import { FkNumeric } from '@fd/ui/FkNumeric/FkNumeric';
import { FkSwitch } from '@fd/ui/FkSwitch/FkSwitch';
import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import { ModalContent } from '@fd/ui/Modal/ModalContent';
import { OptionType } from '@fd/ui/Select/Select';

import { FIELDS } from '../../../models/fields';
import { flagService } from '../../../services';
import { getSalesChannelType } from '../../../ui/utils/generateSalesChannelsTypes';
import useStoreToSalesChannelTransformerWithQuery from '../../Settings/utils/useStoreToSalesChannelTransformerWithQuery';
import { getStores, getStoreSelectOptions } from '../selectors';
import ActionButtonGroup from './ActionButtonGroup';
import AssignStoresField from './AssignStoresField';

const useStyles = makeStyles({
  switch: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  lowerCase: {
    textTransform: 'lowercase',
  },
  removeDialog: {
    whiteSpace: 'pre-line',
  },
});

type InnerProps = MappedProps & FormikProps<FormValues>;
type OuterProps = {
  editMode: boolean;
  campaignData?: RetentionCampaign;
  onCancel: () => void;
  onRemove: (CampaignId: number) => void;
  open: boolean;
  onCreate: (values: FormValues) => void;
};

type Props = InnerProps & OuterProps;

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = ({ campaignData = {} as RetentionCampaign, editMode }) => {
  const formState = {
    IsEnabled: true,
    StoreIds: [],
    NotifyCustomerAfterMinutes: 28,
    PercentDiscountAmount: 20,
    VoucherValidPeriodDays: 7,
    IncludeDeliveryFee: false,
    AutoApplyResultingVouchers: false,
    IncludeExistingOrders: false,
  } as RetentionCampaign;

  if (editMode) {
    return {
      ...formState,
      ...campaignData,
    };
  }
  return formState;
};

type State = {
  storesOptions: OptionType[];
  isRemoveDialogVisible: boolean;
};

const RetentionCampaignModal = (props: Props) => {
  const { allStores, editMode, initialValues, isLoyaltyMaxDiscountOn, onCancel, onRemove } = props;
  const classes = useStyles();
  const [selectedStores, setSelectedStores] = useState<OptionType[]>();
  const [state, setState] = useState<State>({
    storesOptions: [],
    isRemoveDialogVisible: false,
  });
  const titleTextId = editMode ? 'Modify_retention_campaign' : 'Create_new_retention_campaign';

  const storeIdAssociations = useStoreToSalesChannelTransformerWithQuery();
  useEffect(() => {
    const stores = allStores.filter(
      (store) =>
        initialValues.StoreIds?.indexOf(store.StoreId as number) !== -1 ||
        !store.HasRetentionCampaign
    );
    if (initialValues.StoreIds?.length) {
      const assignedStores = allStores
        .filter((all) => initialValues.StoreIds?.some((assigned) => assigned === all.StoreId))
        .map((s) => {
          const salesChannelInfo = storeIdAssociations.find(
            (association) => association.storeId === s.StoreId
          );
          return {
            label: `${s.StoreName as string}${getSalesChannelType(salesChannelInfo?.salesChannelType ?? '')}`,
            value: s.StoreId as number,
          };
        });
      setSelectedStores(assignedStores);
    }

    setState({
      ...state,
      storesOptions: stores.map(getStoreSelectOptions),
    });
  }, [allStores, storeIdAssociations]);

  const handleRemoveClick = useCallback(() => {
    setState({
      ...state,
      isRemoveDialogVisible: true,
    });
  }, []);

  return (
    <>
      <Modal titleTextId={titleTextId as TranslationId} open={props.open} onClose={onCancel}>
        <Form>
          <ModalContent>
            <Divider />
            <FormItemLayout
              labelId="Is_the_campaign_enabled"
              bottomBorder
              smProportion="10x2"
              mdProportion="10x2"
              removeRootPaddings={true}
              noWrap
            >
              <Box className={classes.switch}>
                <FkSwitch {...FIELDS['CampaignIsEnabled']} name={'IsEnabled'} />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              descriptionId="Retention_campaign_sales_channel"
              labelId="Sales_channels"
              mdProportion="12x12"
              removeRootPaddings
            >
              <AssignStoresField fieldName={'StoreIds'} preSelectedStores={selectedStores} />
            </FormItemLayout>

            <FormItemLayout
              labelId="Send_discount_code_after"
              bottomBorder
              descriptionId="if_user_has_not_placed_any_orders"
              mdProportion="8x4"
              removeRootPaddings
            >
              <FkNumeric
                name={'NotifyCustomerAfterMinutes'}
                {...FIELDS['CampaignNotifyCustomerAfterMinutes']}
                renderEndAdornmen={() => (
                  <span className={classes.lowerCase}>
                    <Translate id="Days" />
                  </span>
                )}
              />
            </FormItemLayout>

            <FormItemLayout
              labelId="Amount_of_discount"
              bottomBorder
              mdProportion="8x4"
              removeRootPaddings
            >
              <FkNumeric
                name={'PercentDiscountAmount'}
                {...FIELDS['CampaignPercentDiscountAmount']}
                renderEndAdornmen={() => '%'}
              />
            </FormItemLayout>

            <FormItemLayout
              labelId="Voucher_will_be_valid_for"
              bottomBorder
              mdProportion="8x4"
              removeRootPaddings
            >
              <FkNumeric
                name={'VoucherValidPeriodDays'}
                {...FIELDS['CampaignVoucherValidPeriodDays']}
                renderEndAdornmen={() => (
                  <span className={classes.lowerCase}>
                    <Translate id="Days" />
                  </span>
                )}
              />
            </FormItemLayout>

            <FormItemLayout
              labelId="Does_it_cover_delivery_fees"
              bottomBorder
              smProportion="10x2"
              mdProportion="10x2"
              removeRootPaddings
              noWrap
            >
              <Box className={classes.switch}>
                <FkSwitch {...FIELDS['CampaignIncludeDeliveryFee']} name={'IncludeDeliveryFee'} />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              labelId="Does_it_apply_automatically_to_orders"
              bottomBorder
              smProportion="10x2"
              mdProportion="10x2"
              removeRootPaddings
              noWrap
            >
              <Box className={classes.switch}>
                <FkSwitch
                  {...FIELDS['CampaignAutomaticallyApplyExistingOrders']}
                  name={'AutoApplyResultingVouchers'}
                />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              bottomBorder
              labelId="Force_discount"
              descriptionId="Force_discount_description"
              mdProportion="10x2"
              noWrap
              removeRootPaddings
              smProportion="10x2"
            >
              <Box className={classes.switch}>
                <FkSwitch {...FIELDS['CampaignForceDiscount']} name={'ForceDiscount'} />
              </Box>
            </FormItemLayout>

            {isLoyaltyMaxDiscountOn && (
              <FormItemLayout
                bottomBorder
                descriptionId="Max_discount_amount_description"
                labelId="Max_discount_amount"
                mdProportion="8x4"
                removeRootPaddings
                showBetaTag
              >
                <FkNumeric {...FIELDS['CampaignMaxDiscount']} name={'MaxDiscount'} />
              </FormItemLayout>
            )}
          </ModalContent>

          <ModalActions>
            <ActionButtonGroup
              onCancel={onCancel}
              editMode={editMode}
              onRemove={handleRemoveClick}
            />
          </ModalActions>
        </Form>
      </Modal>
      <YesNoDialog
        open={state.isRemoveDialogVisible}
        onYes={() => {
          setState({ ...state, isRemoveDialogVisible: false });
          onRemove(initialValues.CampaignId as number);
        }}
        onNo={() => setState({ ...state, isRemoveDialogVisible: false })}
        yesTextId={'Remove'}
        noTextId={'Cancel'}
        titleTextId={'Remove_retention_campaign'}
      >
        <Typography variant="body1" className={classes.removeDialog}>
          <Translate id="Pressing_remove_will_delete" />
        </Typography>
      </YesNoDialog>
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  allStores: getStores(state),
  isLoyaltyMaxDiscountOn: flagService.isFlagOn(state, 'loyaltyMaxDiscount'),
});

export default compose<InnerProps, OuterProps>(
  connect(mapStateToProps, null),
  withFormik<Props, FormValues>({
    displayName: 'RetentionCampaignForm',
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return getDefaultFormState(props);
    },
    handleSubmit: async (values, formikBag) => {
      await formikBag.props.onCreate(values);
      formikBag.setSubmitting(false);
    },
  })
)(RetentionCampaignModal);
