import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';

import { ReactComponent as ForkIcon } from '../../../../assets/images/app_logo_fork.svg';
import { ImgixLogoWrapper } from '../../../../ui/Imgix/ImgixLogoWrapper';

const LogoContainer = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(1.5),
}));

const BrandFieldInfo = ({ currentApp }: MappedProps) => {
  return (
    <FormItemLayout labelId="Brand" bottomBorder>
      <Box mr={2}>
        <TextField
          disabled
          variant="standard"
          value={`${currentApp?.Name} - ${currentApp?.AppId}`}
          fullWidth
          InputProps={{
            startAdornment: (
              <LogoContainer>
                {currentApp?.LogoImageUrl ? (
                  <ImgixLogoWrapper src={currentApp?.LogoImageUrl} alt={currentApp?.Name} />
                ) : (
                  <ForkIcon />
                )}
              </LogoContainer>
            ),
          }}
        />
      </Box>
    </FormItemLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentApp: state.currentApp,
});

export default connect(mapStateToProps)(BrandFieldInfo);
