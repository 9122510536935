import React from 'react';

import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldProps, FormikProps } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';

import StoreSelect from '../../../AppStore/components/AppStoreAppConfigForm/fields/StoreSelect';

type InnerProps = ReturnType<typeof mapStateToProps>;
type OuterProps = {
  storeIds: number[] | undefined;
};
type Props = InnerProps & OuterProps;

const useStyles = makeStyles((theme: Theme) => ({
  helperText: {
    marginBottom: theme.spacing(2),
  },
}));

const StoreSelectField = ({ storeIds, translate }: Props) => {
  const classes = useStyles();

  const validate = (value: string) => {
    if (!value) {
      return <Translate id="Required" />;
    }
  };

  const fieldName = 'StoreIds';

  const handleStoreSelect = (formikForm: FormikProps<any>, stores: number | number[]) => {
    let newStores = [] as number[];
    if (Array.isArray(stores) && stores?.length === 1) {
      newStores = stores;
      formikForm.setFieldValue('StoreSelectorType', 'Single', false);
    } else if (Array.isArray(stores) && stores?.length > 0) {
      newStores = stores;
      formikForm.setFieldValue('StoreSelectorType', 'Multiple', false);
    } else if (!Array.isArray(stores) && stores) {
      newStores = [stores];
      formikForm.setFieldValue('StoreSelectorType', 'Single', false);
    } else {
      formikForm.setFieldValue('StoreSelectorType', 'None', false);
    }
    formikForm.setFieldValue(fieldName, newStores, false);
  };

  return (
    <Field name={fieldName} validate={validate}>
      {({ field, form }: FieldProps) => {
        const { errors } = form;
        const fieldError = errors[field.name] as string | undefined;
        return (
          <FormItemLayout label={translate('OrderStatus_Form_AssociatedStores')}>
            <Typography variant="caption" className={classes.helperText} component="p">
              {translate('OrderStatus_Form_AssociatedStores_HelperMessage')}
            </Typography>
            <StoreSelect
              isMulti={true}
              initialSelectedStoreIds={storeIds}
              placeholder={translate('All_sales_channels_later') as string}
              fieldError={fieldError}
              variant="outlined"
              onSelectStore={(stores) => handleStoreSelect(form, stores)}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps))(StoreSelectField);
