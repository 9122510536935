import React from 'react';

import { App, AssignedBankAccount } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Form, FormikProps, withFormik } from 'formik';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import Button from '../../../ui/Button/Button';
import LinkButton from '../../../ui/Button/LinkButton';
import StoreBankingSettingsAccountsFormField from './StoreBankingSettingsAccountsFormField';

export interface IStoreBankingSettingsProps {
  submit: (changes, storeId) => Promise<void>;
  storeId?: number;
  appId?: string;
  initialValues?: AssignedBankAccount;
  classes: any;
}

export type FormValues = ReturnType<typeof getDefaultFormState>;
export const getDefaultFormState = (initialValues?: AssignedBankAccount) => {
  return {
    selectedAccount: initialValues
      ? { label: initialValues.BankAccountName, value: initialValues.Id }
      : undefined,
  };
};
const StoreBankingSettingsForm = (props: FormikProps<FormValues> & IStoreBankingSettingsProps) => {
  const { classes, appId } = props;
  return (
    <Form>
      <Grid container>
        <Grid item xs={12}>
          <StoreBankingSettingsAccountsFormField />
        </Grid>

        <Permissions allowed={[App.AppResourceSetEnum.UpdateBankAccounts]}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-end"
              alignContent="center"
              m={-1}
              width={`calc(100% + 16px)`}
            >
              <Grid item className={classes.item} p={1}>
                <Grid justifyContent="space-between" container className={classes.buttons}>
                  <LinkButton
                    color="primary"
                    type="button"
                    className={classes.textButton}
                    to={`/${appId}/finance/bank-accounts`}
                    fdKey="go-to-banking"
                  >
                    <Translate id="Manage_bank_accounts" />
                  </LinkButton>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    fdKey="btn-attach-bank-account"
                    disabled={props.isSubmitting}
                  >
                    <Translate id="Save" />
                  </Button>
                </Grid>
              </Grid>
              <Grid item p={1}>
                <Typography variant="caption" color="textSecondary">
                  <Translate id="Bank_account_detail_payout_warning" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Permissions>
      </Grid>
    </Form>
  );
};

export default withFormik<IStoreBankingSettingsProps, FormValues>({
  displayName: 'StoreBankingSettingsForm',
  mapPropsToValues: (props) => {
    return getDefaultFormState(props.initialValues);
  },
  enableReinitialize: false,
  handleSubmit: (values, formikBag) => {
    const { selectedAccount } = values;
    formikBag.props.submit(selectedAccount, formikBag.props.storeId).catch((err) => {
      // formikBag.setFieldError('selectedAccount', err.message);
      formikBag.setSubmitting(false);
    });
  },
})(StoreBankingSettingsForm);
