import {
  CAAddressMapper,
  DEAddressMapper,
  DefaultAddressMapper,
  ESAddressMapper,
  FRAddressMapper,
  GBAddressMapper,
  IEAddressMapper,
  ITAddressMapper,
  PRAddressMapper,
  PTAddressMapper,
  USAddressMapper,
} from './AddressMappers';

export const getInternationalisedAddressFields = (address, countryCode) => {
  switch (countryCode) {
    case 'CA': {
      return CAAddressMapper(address);
    }
    case 'DE': {
      return DEAddressMapper(address);
    }
    case 'ES': {
      return ESAddressMapper(address);
    }
    case 'FR': {
      return FRAddressMapper(address);
    }
    case 'GB': {
      return GBAddressMapper(address);
    }
    case 'IE': {
      return IEAddressMapper(address);
    }
    case 'IT': {
      return ITAddressMapper(address);
    }
    case 'PR': {
      return PRAddressMapper(address);
    }
    case 'PT': {
      return PTAddressMapper(address);
    }
    case 'US': {
      return USAddressMapper(address);
    }
    default: {
      return DefaultAddressMapper(address);
    }
  }
};
