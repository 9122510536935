import React from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { type TranslatePlaceholderData, Translate } from 'react-localize-redux';

import EmptyListIcon from '../assets/images/anim_empty_list.gif';
import Button from './Button/Button';
import GridContainer from './Layout/GridContainer';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  root: {
    [breakpoints.up('lg')]: {
      padding: '32px 24px 40px',
    },
    [breakpoints.down('md')]: {
      padding: '8px 0 40px',
    },
  },
  imageWrapper: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
    justifyContent: 'center',
  },
  title: {
    fontSize: 36,
    lineHeight: '48px',
    color: '#3743B1',
    marginBottom: 8,

    [breakpoints.down('md')]: {
      fontSize: 24,
      lineHeight: '32px',
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
    marginBottom: 12,
  },
  image: {
    width: '100%',
    objectFit: 'contain',

    [breakpoints.up('md')]: {
      marginLeft: 56,
      marginRight: 56,
    },

    [breakpoints.down('sm')]: {
      width: '75%',
      marginBottom: 24,
    },
  },
  content: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
    [breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
  buttonContainer: {
    [breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

type Props = {
  title?: string;
  titleData?: TranslatePlaceholderData;
  subtitle?: string;
  subtitleData?: object;
  icon?: string;
  noLink?: boolean;
  action?: Function;
  shouldTranslate?: boolean;
};

const EmptyComponent: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const classes = useStyles();
  const {
    title,
    titleData,
    subtitle,
    subtitleData = null,
    icon = EmptyListIcon,
    noLink,
    action,
    shouldTranslate = true,
  } = props;

  return (
    <div className={classes.root} data-fd="empty-component">
      <GridContainer>
        <Grid
          item
          xs={12}
          sm={4}
          md={6}
          container
          className={clsx(classes.imageWrapper, classes.gridItem)}
        >
          <img src={icon} className={classes.image} alt="No_data" />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          container
          direction="column"
          className={clsx(classes.content, classes.gridItem)}
          style={{ alignSelf: 'center' }}
        >
          {title && (
            <Typography className={classes.title}>
              {shouldTranslate ? <Translate id={title as TranslationId} data={titleData} /> : title}
            </Typography>
          )}
          {subtitle && (
            <Typography className={classes.subtitle}>
              {shouldTranslate ? (
                <Translate id={subtitle as TranslationId} data={subtitleData} />
              ) : (
                subtitle
              )}
            </Typography>
          )}
          {!noLink && (
            <Typography>
              <a
                href="https://help.flipdish.com"
                style={{ color: '#05149e', textDecoration: 'none' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                help.flipdish.com
              </a>
            </Typography>
          )}
          {action && (
            <Grid container className={classes.buttonContainer}>
              <Button
                style={{ marginLeft: -9 }}
                color="primary"
                onClick={() => action()}
                fdKey="retry"
              >
                <Translate id="Retry" />
              </Button>
            </Grid>
          )}
        </Grid>
      </GridContainer>
    </div>
  );
};

export default EmptyComponent;
