import React, { forwardRef, useState } from 'react';

import { useAppDispatch } from '@fd/customHooks/useAppDispatch';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText, { type ListItemTextProps } from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';

import { ImgixLogoWrapper } from '@fd/ui/Imgix/ImgixLogoWrapper';
import { copyToClipboard } from '@fd/ui/utils';

import { ReactComponent as ForkIcon } from '../../../assets/images/app_logo_fork.svg';
import { notify, notifyError } from '../../../layouts/Notify/actions';

const StyledListItem = styled(ListItemButton)<{
  ownerState: { isActive: boolean; isHighlighted: boolean };
}>(({ ownerState }) => ({
  minHeight: 50,
  overflow: 'hidden',
  flexShrink: 0,
  flexGrow: 0,
  backgroundColor: ownerState.isActive ? 'rgba(63,38,178,0.07)' : 'initial',
  '&:hover .StyledIconButton': {
    visibility: 'visible',
  },
  ':hover': {
    backgroundColor: ownerState.isHighlighted ? 'initial' : 'rgba(63,38,178,0.07)',
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: 40,
  height: 40,
  overflow: 'hidden',
  paddingRight: theme.spacing(2),
}));

const StyledListItemText = styled(ListItemText)({
  wordBreak: 'break-word',
  color: 'rgba(0, 0, 0, 0.87)',
  userSelect: 'text',
});

type StyledIconButtonProps = {
  isMobile: boolean;
};

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<StyledIconButtonProps>(({ theme, isMobile }) => ({
  height: '16px',
  width: '16px',
  marginLeft: theme.spacing(1),
  visibility: isMobile ? 'visible' : 'hidden',
}));

const StyledCopyIcon = styled(CopyIcon)({
  fontSize: '16px',
});

type Props = {
  handleItemClick?: (shouldOpenLinkInNewTab: boolean) => void;
  dataFd: string;
  isActive?: boolean;
  isHighlighted?: boolean;
  imgUrl?: string;
  imgName?: string;
} & ListItemTextProps;

const OrgAndBrandSelectorItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      dataFd,
      handleItemClick,
      imgName,
      imgUrl,
      isActive = false,
      isHighlighted = false,
      primary,
      primaryTypographyProps = {},
      secondaryTypographyProps = {},
      secondary,
    },
    ref
  ) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useAppDispatch();
    const translate = useSelector((state) => getTranslate(state));
    const [isSelectingText, setIsSelectingText] = useState(false);

    const handleOnClick = async (event) => {
      if (!isSelectingText && handleItemClick) {
        const shouldOpenLinkInNewTab = event.ctrlKey || event.metaKey;
        handleItemClick(shouldOpenLinkInNewTab);
      }
    };

    const handleTextMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setIsSelectingText(true);
    };

    const handleTextMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        setIsSelectingText(true);
      } else {
        setIsSelectingText(false);
      }
    };

    const handleIconButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      try {
        secondary && (await copyToClipboard(e, secondary.toString()));
        dispatch(
          notify({
            message: `${secondary} ${translate('copied_to_clipboard')}`,
            variant: 'success',
            persist: false,
          })
        );
      } catch (e) {
        dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      }
    };

    return (
      <StyledListItem
        data-fd={dataFd}
        ref={ref}
        onClick={handleOnClick}
        ownerState={{ isActive, isHighlighted }}
      >
        <StyledListItemIcon>
          {imgUrl ? (
            <ImgixLogoWrapper src={imgUrl} alt={imgName} />
          ) : (
            <ForkIcon height={40} width={40} />
          )}
        </StyledListItemIcon>
        <StyledListItemText
          primaryTypographyProps={{
            variant: 'subtitle1',
            display: 'block',
            marginBottom: '4px',
            ...primaryTypographyProps,
          }}
          secondaryTypographyProps={{
            variant: 'body2',
            display: 'flex',
            justifyContent: 'space-between',
            id: secondary,
            ...secondaryTypographyProps,
          }}
          primary={primary}
          secondary={
            <>
              {secondary}

              <StyledIconButton
                isMobile={isMobile}
                className="StyledIconButton"
                aria-label="copy id to clipboard"
                onClick={handleIconButtonClick}
                data-fd={`copy-id-${primary}${isHighlighted ? '-highlighted' : ''}`}
              >
                <StyledCopyIcon />
              </StyledIconButton>
            </>
          }
          onMouseDown={handleTextMouseDown}
          onMouseUp={handleTextMouseUp}
        ></StyledListItemText>
      </StyledListItem>
    );
  }
);

export default OrgAndBrandSelectorItem;
