import React, { useEffect } from 'react';

import { useAppDispatch } from '@fd/customHooks/useAppDispatch';
import { useAppSelector } from '@fd/customHooks/useAppSelector';
import { App } from '@flipdish/api-client-typescript';
import {
  CreatePropertyForOrg201Response,
  GetPropertiesByOrgId200Response,
} from '@flipdish/orgmanagement';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import DangerActionButton from '@fd/ui/molecules/DangerZone/DangerActionButton';
import DangerActionCard from '@fd/ui/molecules/DangerZone/DangerActionCard';
import DangerZoneSimpleActionCard from '@fd/ui/molecules/DangerZone/DangerZoneSimpleActionCard';

import { notifyError, notifySaved } from '../../../layouts/Notify/actions';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import {
  archiveProperty,
  getAllPropertiesForOrg,
  getAllPropertiesForOrgKey,
  getPropertyById,
  getPropertyByIdKey,
  unarchiveProperty,
} from '../properties.services';
import { getAssociationsKey } from '../../RMS/rms.services';

type CardData = {
  cardHeader: TranslationId;
  doThisIf: TranslationId[];
  itWillMean: TranslationId[];
  buttonText: TranslationId;
  divider: boolean;
};

const propertyCardData: (isFlipdishStaff: boolean) => CardData[] = (isFlipdishStaff) => [
  {
    cardHeader: 'Archive_this_property',
    doThisIf: [
      'property_was_created_in_error_or_closed_permanently',
      'Youll_never_want_to_receive_orders_for_any_sales_channels_in_this_property',
    ],
    itWillMean: [
      'this_property_and_its_sales_channels_will_no_longer_be_accessible_in_your_flipdish_portal',
      'all_sales_channels_within_this_property_will_no_longer_be_visible_on_your_website_or_apps',
      'customers_will_no_longer_be_able_to_order_from_any_sales_channel_in_this_property_forever',
      'orders_and_audit_logs_related_to_this_property_and_its_sales_channels_will_be_kept',
    ],
    buttonText: 'Archive_property',
    divider: isFlipdishStaff,
  },
];

const DangerZoneList = () => {
  const currentApp = useAppSelector((state) => state.currentApp);
  const currentOrg = useAppSelector((state) => state.currentApp.OrgId);
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { propertyId } = useParams<{ propertyId: string }>();

  const isFlipdishStaff = useAppSelector((state) =>
    permissionsSelector.hasPermissionFactory(['FlipdishStaff'])(state)
  );

  useEffect(() => {
    trackEvent('portal_properties_dangerZone', {
      action: 'logged_in',
    });
  }, []);

  const orgId = currentOrg ?? '';

  const { data: getPropertyData, isLoading } = useQuery({
    queryKey: [getPropertyByIdKey, orgId, propertyId],
    queryFn: () => getPropertyById(orgId, propertyId),
    enabled: !!propertyId,
  });
  const propertyData = getPropertyData?.data?.data || {};

  const handlePropertyMutation = async (isArchived: boolean) => {
    const previousListData = queryClient.getQueryData<{
      data: GetPropertiesByOrgId200Response;
    }>([getAllPropertiesForOrgKey, currentOrg]);

    const previousPropertyData = queryClient.getQueryData<{
      data: CreatePropertyForOrg201Response;
    }>([getPropertyByIdKey, currentOrg, propertyId]);

    const updatedProperties = [
      ...(previousListData?.data?.data?.filter((p) => p.propertyId !== propertyData?.propertyId) ||
        []),
      { ...propertyData, isArchived },
    ];

    queryClient.setQueryData([getAllPropertiesForOrgKey, currentOrg], {
      ...previousListData,
      data: { ...previousListData?.data, data: updatedProperties },
    });

    queryClient.setQueryData([getPropertyByIdKey, currentOrg, propertyId], {
      ...previousPropertyData,
      data: { ...previousPropertyData?.data, data: { ...propertyData, isArchived } },
    });

    return { previousListData, previousPropertyData };
  };

  const { mutateAsync: mutateArchive } = useMutation({
    mutationFn: async () => {
      await archiveProperty(currentApp?.OrgId ?? '', propertyData?.propertyId ?? '');
    },
    onSuccess: () => {
      dispatch(notifySaved());
      queryClient.invalidateQueries({ queryKey: [getAssociationsKey] });
      history.push(`/${currentApp.AppId}/properties`);
    },
    onMutate: () => handlePropertyMutation(true),
    onError: (
      error,
      variables,
      context: {
        previousListData?: { data: GetPropertiesByOrgId200Response };
        previousPropertyData?: { data: CreatePropertyForOrg201Response };
      }
    ) => {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      if (context?.previousListData) {
        queryClient.setQueryData(
          [getAllPropertiesForOrg, currentOrg, propertyId],
          context.previousListData
        );
        queryClient.setQueryData(
          [getPropertyByIdKey, currentOrg, propertyId],
          context.previousPropertyData
        );
      }
    },
  });

  const { mutateAsync: mutateUnarchive } = useMutation({
    mutationFn: async () => {
      await unarchiveProperty(currentApp?.OrgId ?? '', propertyData?.propertyId ?? '');
    },

    onSuccess: () => {
      dispatch(notifySaved());
    },

    onMutate: () => handlePropertyMutation(false),

    onError: (
      error,
      variables,
      context: {
        previousListData?: { data: GetPropertiesByOrgId200Response };
        previousPropertyData?: { data: CreatePropertyForOrg201Response };
      }
    ) => {
      dispatch(notifyError({ message: 'Error_please_try_again_later', translate: true }));
      if (context?.previousListData) {
        queryClient.setQueryData(
          [getAllPropertiesForOrg, currentOrg, propertyId],
          context.previousListData
        );
        queryClient.setQueryData(
          [getPropertyByIdKey, currentOrg, propertyId],
          context.previousPropertyData
        );
      }
    },
  });

  return (
    <PageLayout
      title={<Translate id="Danger_zone_title" />}
      documentTitle="Property_Danger_Zone"
      caption={propertyData.name}
      toParent={`/${currentApp?.AppId}/properties/${propertyData.propertyId}`}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: '10px' }} size={50} />
      ) : (
        <>
          {propertyCardData(isFlipdishStaff).map((card) => {
            return (
              <DangerActionCard
                cardHeader={card.cardHeader}
                doThisIf={card.doThisIf}
                itWillMean={card.itWillMean}
                divider={card.divider}
                button={
                  <>
                    {
                      <Permissions allowed={[App.AppResourceSetEnum.EditStores]}>
                        <DangerActionButton
                          buttonText="Archive_property"
                          dialogTitle="Archive_Property?"
                          dialogDescription="please_type_in_the_name_of_the_store_to_confirm"
                          helperText="Property_name_does_not_match"
                          handleSubmit={mutateArchive}
                          itemName={propertyData.name ?? ''}
                          isDisabled={propertyData.isArchived}
                          introText="If_you_are_sure_you_want_to_archive"
                          dataFdPrefix="archive-property"
                          confirmButtonText="Archive"
                        />
                      </Permissions>
                    }
                  </>
                }
                key={card.cardHeader}
              />
            );
          })}
          {isFlipdishStaff && (
            <DangerZoneSimpleActionCard
              onClick={mutateUnarchive}
              disabled={!propertyData.isArchived}
              title="Unarchive_this_property"
              description="This_property_will_be_visible_and_available"
              buttonText="Unarchive_property"
              dataFdPrefix="unarchive-property"
              showStaffTag={true}
            />
          )}
        </>
      )}
    </PageLayout>
  );
};

export default DangerZoneList;
