import * as React from 'react';

import { StoreGroup } from '@flipdish/api-client-typescript';
import { OrgCountryCodeEnum } from '@flipdish/orgmanagement';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getSymbol as getCurrencySymbol } from '../../helpers/currency';
import { CircleIcon, PolygonIcon, PostcodeIcon } from '../../icons';
import { type NotifyProps, notifyError } from '../../layouts/Notify/actions';
import {
  fetchMultiplePostcodePolygonsForPostcodes,
  fetchPostcodePolygonsForPostcodes,
} from '../../services/deliveryZoneService';
import { flagService } from '../../services/flagService/flagService';
import Button from '../../ui/Button/Button';
import {
  getPostcodeValidationMessage,
  PostcodeValidationStatus,
  validatePostcodes,
} from '../../utils/postcodeValidator';

const styles = createStyles({
  root: {
    '& .MuiInputAdornment-positionStart': {
      whiteSpace: 'nowrap',
    },
  },
  row: {
    padding: '4px 0',
  },
  radioRow: {
    padding: '14px 0 0 0',
  },
  radio: {
    padding: '6px 12px',
  },
  actions: {
    margin: '-10px 12px 16px 4px',
  },
  button: {
    padding: '8px',
  },
  title: {
    paddingBottom: '0',
  },
});

export interface ICreateDeliveryZoneDialogProps {
  className: string;
  currency: StoreGroup.CurrencyEnum;
  onCreate(zone: {
    DeliveryFee: number;
    MinimumDeliveryOrderAmount: number;
    shape: string;
    postcodes: string;
    postcodePolygons: string[];
  }): void;
}
type IInjectedProps = ICreateDeliveryZoneDialogProps &
  WithStyles<typeof styles> &
  MappedState & { className: string } & MappedDispatch;

interface IState {
  open: boolean;
  zone: {
    DeliveryFee: number;
    MinimumDeliveryOrderAmount: number;
    shape: string;
    postcodes: string;
    postcodePolygons: string[];
  };
  postcodeError: string;
}
class CreateDeliveryZoneDialog extends React.Component<IInjectedProps, IState> {
  public state = {
    open: false,
    zone: {
      DeliveryFee: 0,
      MinimumDeliveryOrderAmount: 0,
      shape: 'CIRCLE',
      postcodes: '',
      postcodePolygons: [],
    },
    postcodeError: '',
  };

  public handleClickOpen = () => {
    this.setState({ open: true });
  };

  public handleClose = () => {
    this.setState({
      open: false,
      zone: {
        DeliveryFee: 0,
        MinimumDeliveryOrderAmount: 0,
        shape: 'CIRCLE',
        postcodes: '',
        postcodePolygons: [],
      },
      postcodeError: '',
    });
  };

  public handleInputChange = (e) => {
    const { name, value } = e.target;
    const { zone } = this.state;
    this.setState({ zone: { ...zone, [name]: value } });
  };

  public handleRadioChange = (event) => {
    const { zone } = this.state;
    this.setState({ zone: { ...zone, shape: event.target.value } });
  };

  private readonly validatePostcodesInput = (): boolean => {
    const postcodeValidationStatus = validatePostcodes(this.state.zone.postcodes);
    if (postcodeValidationStatus !== PostcodeValidationStatus.VALID) {
      const postcodeError = getPostcodeValidationMessage(
        postcodeValidationStatus,
        this.props.translate
      );
      this.setState({ postcodeError });
      return false;
    }
    return true;
  };

  private async fetchPostcodePolygons(): Promise<string[] | null> {
    const { postcodePolygonsEnabled, postcodePolygonsMultipleEnabled, currentOrg } = this.props;
    const { postcodes } = this.state.zone;

    if (!postcodePolygonsEnabled) return null;

    let postcodePolygons: string[] | string = [];
    if (postcodePolygonsMultipleEnabled) {
      const postcodePolygonsFromAPI = await fetchMultiplePostcodePolygonsForPostcodes(
        currentOrg?.orgId ?? '',
        postcodes
      );
      if (typeof postcodePolygonsFromAPI === 'string') {
        this.handleError(postcodePolygonsFromAPI);
        return null;
      }
      postcodePolygons = postcodePolygonsFromAPI.map((postcode) => postcode.wktList).flat();
    } else {
      postcodePolygons = await fetchPostcodePolygonsForPostcodes(
        currentOrg?.orgId ?? '',
        postcodes
      );
      if (typeof postcodePolygons === 'string') {
        this.handleError(postcodePolygons);
        return null;
      }
    }

    if (postcodePolygons.length === 0) {
      this.setState({
        postcodeError: getPostcodeValidationMessage(
          PostcodeValidationStatus.INVALID_POSTCODES,
          this.props.translate
        ),
      });
      return null;
    }

    return postcodePolygons;
  }

  private handleError(message: string) {
    this.props.dispatchNotifyError({ message });
  }

  public handleCreate = async () => {
    if (this.state.zone.shape !== 'POSTCODES') {
      this.props.onCreate({
        ...this.state.zone,
        postcodePolygons: [],
      });
      this.handleClose();
      return;
    }

    if (!this.validatePostcodesInput()) return;

    const postcodePolygons = await this.fetchPostcodePolygons();
    if (postcodePolygons === null) return;

    this.props.onCreate({
      ...this.state.zone,
      postcodePolygons,
    });

    this.handleClose();
  };

  public render() {
    const { className, currency, classes } = this.props;
    const {
      open,
      zone: { DeliveryFee, MinimumDeliveryOrderAmount, shape, postcodes },
      postcodeError,
    } = this.state;
    const currencySymbol = getCurrencySymbol(currency);
    const disabled = false;
    const disabledCreate = isNaN(DeliveryFee) || isNaN(MinimumDeliveryOrderAmount);

    return (
      <div>
        <Tooltip title={<Translate id="Add_delivery_zone" />}>
          <Translate>
            {(translate) => (
              <Fab
                className={className}
                aria-label={translate('Add_delivery_zone') as string}
                aria-haspopup="true"
                onClick={this.handleClickOpen}
                color="secondary"
                disabled={disabled}
                data-fd="delivery_zones_create"
              >
                <AddIcon />
              </Fab>
            )}
          </Translate>
        </Tooltip>

        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className={classes.title}>
            <Typography component="span" variant="h5">
              {<Translate id="Create_new_delivery_zone" />}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column">
              <Grid item xs={12} className={classes.row}>
                <TextField
                  variant="standard"
                  className={classes.root}
                  label={<Translate id="Minimum_amount" />}
                  name="MinimumDeliveryOrderAmount"
                  type="number"
                  margin="dense"
                  value={MinimumDeliveryOrderAmount}
                  onChange={this.handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{currencySymbol}</InputAdornment>
                    ),
                    inputProps: {
                      min: '0',
                      step: (MinimumDeliveryOrderAmount as number) % 1 !== 0 ? '0.01' : '1',
                      'data-fd': 'delivery_zones_create_minimum_amount',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <TextField
                  variant="standard"
                  className={classes.root}
                  label={<Translate id="Delivery_fee" />}
                  name="DeliveryFee"
                  type="number"
                  margin="dense"
                  value={DeliveryFee}
                  onChange={this.handleInputChange}
                  inputProps={{
                    min: 0,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{currencySymbol}</InputAdornment>
                    ),
                    inputProps: {
                      min: '0',
                      step: (DeliveryFee as number) % 1 !== 0 ? '0.01' : '1',
                      'data-fd': 'delivery_zones_create_delivery_fee',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} className={classes.radioRow}>
                <RadioGroup
                  data-fd="delivery_zones_create_shape"
                  aria-label="Shape"
                  name="shape"
                  value={shape}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    value="CIRCLE"
                    control={
                      <Radio
                        data-fd="delivery_zones_create_circle"
                        color="default"
                        classes={{
                          root: classes.radio,
                        }}
                        checkedIcon={
                          <>
                            <CircleIcon />
                            <RadioButtonCheckedIcon />
                          </>
                        }
                        icon={
                          <>
                            <CircleIcon />
                            <RadioButtonUncheckedIcon />
                          </>
                        }
                      />
                    }
                    label={<Translate id="Circle_shape" />}
                  />
                  <FormControlLabel
                    value="POLYGON"
                    control={
                      <Radio
                        data-fd="delivery_zones_create_polygon"
                        color="default"
                        classes={{
                          root: classes.radio,
                        }}
                        checkedIcon={
                          <>
                            <PolygonIcon />
                            <RadioButtonCheckedIcon />
                          </>
                        }
                        icon={
                          <>
                            <PolygonIcon />
                            <RadioButtonUncheckedIcon />
                          </>
                        }
                      />
                    }
                    label={<Translate id="Polygon_shape" />}
                  />
                  {this.props.postcodePolygonsEnabled &&
                    this.props.currentOrg?.countryCode === OrgCountryCodeEnum.Gb && (
                      <FormControlLabel
                        value="POSTCODES"
                        control={
                          <Radio
                            data-fd="delivery_zones_create_postcodes"
                            color="default"
                            classes={{
                              root: classes.radio,
                            }}
                            checkedIcon={
                              <>
                                <PostcodeIcon />
                                <RadioButtonCheckedIcon />
                              </>
                            }
                            icon={
                              <>
                                <PostcodeIcon />
                                <RadioButtonUncheckedIcon />
                              </>
                            }
                          />
                        }
                        label={<Translate id="Postcodes" />}
                      />
                    )}
                </RadioGroup>
              </Grid>
              {shape === 'POSTCODES' && (
                <Grid item xs={12} className={classes.row}>
                  <TextField
                    variant="standard"
                    className={classes.root}
                    data-fd="delivery_zones_create_postcodes_textfield"
                    label={<Translate id="Postcodes" />}
                    name="postcodes"
                    type="text"
                    margin="dense"
                    value={postcodes}
                    onChange={this.handleInputChange}
                    error={!!postcodeError}
                    helperText={postcodeError}
                    placeholder="e.g. L1,L2,L3"
                    inputProps={{
                      'aria-invalid': !!postcodeError,
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              onClick={this.handleClose}
              color="primary"
              className={classes.button}
              fdKey="delivery_zones_create_cancel"
            >
              <Translate>{(translate) => translate('Cancel')}</Translate>
            </Button>
            <Button
              onClick={this.handleCreate}
              color="primary"
              disabled={disabledCreate}
              autoFocus
              className={classes.button}
              fdKey="delivery_zones_create_save"
            >
              <Translate>{(translate) => translate('Add_delivery_zone')}</Translate>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentOrg: state.rms.currentOrg,
    postcodePolygonsEnabled: flagService.isFlagOn(state, 'enablePostcodePolygons'),
    postcodePolygonsMultipleEnabled: flagService.isFlagOn(state, 'enablePostcodePolygonsMultiple'),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default compose<IInjectedProps, ICreateDeliveryZoneDialogProps>(
  withStyles(styles, {
    name: 'CreateDeliveryZoneDialog',
    withTheme: true,
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(CreateDeliveryZoneDialog);
