import React, { useEffect } from 'react';

import { Field, FieldProps, useFormikContext } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import TextField from '@fd/ui/TextField/TextField';

import FormItemLayout from '../FormItemLayout';

interface VoucherFormValues {
  Description: string;
  [key: string]: any;
}

export enum DiscountType {
  PercentageDiscount = 'percentage',
  LumpSumDiscount = 'lumpsum',
}

interface DescriptionFieldProps {
  defaultValue?: string;
  isEditMode: boolean;
  discountType?: DiscountType;
  discountValue?: number;
  translate: TranslateFunction;
}

const validate = (value: string) => {
  if (value.length > 100) {
    return 'Max_length_100';
  }
  return;
};

const generateDescriptionFromDiscountType = (
  type: DiscountType,
  value: number,
  translate: TranslateFunction
): string => {
  switch (type) {
    case DiscountType.PercentageDiscount:
      return translate('Percentage_discount_dynamic', { percentage: value }) as string;

    case DiscountType.LumpSumDiscount: {
      let formattedAmount = Number(value).toFixed(2);
      // Remove trailing '.00'
      if (formattedAmount.endsWith('.00')) {
        formattedAmount = formattedAmount.slice(0, -3);
      }
      return translate('Discount_amount_dynamic', { amount: formattedAmount }) as string;
    }

    default:
      return '';
  }
};

export const DescriptionField: React.FC<DescriptionFieldProps> = ({
  defaultValue,
  isEditMode,
  translate,
  discountType,
  discountValue,
}) => {
  const { values, setFieldValue } = useFormikContext<VoucherFormValues>();

  // Keep track of whether the field has been manually edited
  const [isManuallyEdited, setIsManuallyEdited] = React.useState(false);

  const updateDescriptions = () => {
    if (isManuallyEdited) return;

    // Set initial default value if provided and field is empty
    if (defaultValue && !values.Description) {
      setFieldValue('Description', defaultValue);
      return;
    }

    if (discountValue && discountType) {
      const description = generateDescriptionFromDiscountType(
        discountType,
        discountValue,
        translate
      );
      setFieldValue('Description', description);
    }
  };

  // Only run auto-update effects if not in edit mode
  useEffect(() => {
    if (isEditMode) return;
    updateDescriptions();
  }, [isEditMode, discountValue]);

  return (
    <Field name="Description" validate={validate}>
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;
        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <FormItemLayout
            description={<Translate id="Voucher_description_description" />}
            label={<Translate id="Voucher_form_description" />}
          >
            <TextField
              {...field}
              autoComplete="off"
              disabled={isSubmitting}
              error={showError}
              fdKey="Voucher_form_description"
              fullWidth
              helperText={showError ? <Translate id={fieldError as 'Max_length_100'} /> : undefined}
              variant="outlined"
              onChange={(e) => {
                setIsManuallyEdited(true);
                field.onChange(e);
              }}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};
