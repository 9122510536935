import React from 'react';

import { FulfillmentStatesConfigurationSummary } from '@flipdish/api-client-typescript';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { SkeletonLoader } from '@fd/ui/atoms/SkeletonLoader';

import { OrderFulfillmentStatusListNoResults } from './OrderFulfillmentStatusListNoResults';

const useStyles = makeStyles(() => ({
  cell: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  nameCell: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  row: {
    '&:hover': {
      background: '#eaf2ff',
    },
  },
}));

export type OrderFulfillmentStatusTableProps = {
  appId: string;
  orderFulfillmentStatusConfigurations?: FulfillmentStatesConfigurationSummary[];
  isLoading: boolean;
  translate: TranslateFunction;
};

export const OrderFulfillmentStatusTable = ({
  appId,
  orderFulfillmentStatusConfigurations,
  isLoading,
  translate,
}: OrderFulfillmentStatusTableProps) => {
  const classes = useStyles();

  const location = useLocation();

  const renderSkeletonRows = (): JSX.Element[] => {
    return Array.from(Array(6)).map((_, i) => (
      <TableRow key={`skeleton-row-${i}`}>
        <TableCell>
          <SkeletonLoader fdKey={`skeleton-name-${i}`} rows={[{ height: '34px', width: '50%' }]} />
        </TableCell>
        <TableCell>
          <SkeletonLoader fdKey={`skeleton-type-${i}`} rows={[{ height: '34px', width: '100%' }]} />
        </TableCell>
        <TableCell align="right">
          <SkeletonLoader fdKey={`skeleton-usage-${i}`} rows={[{ height: '34px', width: '50%' }]} />
        </TableCell>
      </TableRow>
    ));
  };

  const renderOrderStatusRows = () => {
    return orderFulfillmentStatusConfigurations?.map((config) => {
      const pathname = location.pathname;
      const url = `${pathname}${pathname.endsWith('/') ? '' : '/'}${config.ConfigurationUid}`;
      return (
        <TableRow className={classes.row} key={config.ConfigurationUid}>
          <TableCell className={classes.nameCell}>
            <Link className={classes.link} to={url} />
            <Translate id="Name" />
            <Typography variant="caption">{config.Name}</Typography>
          </TableCell>
          <TableCell className={classes.cell}>
            <Link className={classes.link} to={url} />
            {config.Stores ? config.Stores.map((store) => store.Name).join(', ') : 'All Stores'}
          </TableCell>
          <TableCell align="right" className={classes.cell}>
            <Link className={classes.link} to={url} />
            {'-'}
          </TableCell>
        </TableRow>
      );
    });
  };

  if (orderFulfillmentStatusConfigurations?.length == 0) {
    return <OrderFulfillmentStatusListNoResults translate={translate} />;
  }

  return (
    <Paper>
      <Table data-fd={appId}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Translate id="Config_name" />
            </TableCell>
            <TableCell>
              <Translate id="Associated_sales_channels" />
            </TableCell>
            <TableCell align="right">
              <Translate id="Enable_disable" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{isLoading ? renderSkeletonRows() : renderOrderStatusRows()}</TableBody>
      </Table>
    </Paper>
  );
};
