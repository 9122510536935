export const deliveryZonesConstants = {
  DELIVERY_ZONE_CREATE: 'DELIVERY_ZONE_CREATE',
  DELIVERY_ZONE_CREATE_REQUEST: 'DELIVERY_ZONE_CREATE_REQUEST',
  DELIVERY_ZONE_CREATE_SUCCESS: 'DELIVERY_ZONE_CREATE_SUCCESS',
  DELIVERY_ZONE_CREATE_FAILURE: 'DELIVERY_ZONE_CREATE_FAILURE',

  DELIVERY_ZONE_LOAD: 'DELIVERY_ZONE_LOAD',
  DELIVERY_ZONE_LOAD_REQUEST: 'DELIVERY_ZONE_LOAD_REQUEST',
  DELIVERY_ZONE_LOAD_SUCCESS: 'DELIVERY_ZONE_LOAD_SUCCESS',
  DELIVERY_ZONE_LOAD_FAILURE: 'DELIVERY_ZONE_LOAD_FAILURE',

  DELIVERY_ZONE_UPDATE: 'DELIVERY_ZONE_UPDATE',
  DELIVERY_ZONE_UPDATE_REQUEST: 'DELIVERY_ZONE_UPDATE_REQUEST',
  DELIVERY_ZONE_UPDATE_SUCCESS: 'DELIVERY_ZONE_UPDATE_SUCCESS',
  DELIVERY_ZONE_UPDATE_FAILURE: 'DELIVERY_ZONE_UPDATE_FAILURE',

  DELIVERY_ZONE_LOAD_ALL: 'DELIVERY_ZONE_LOAD_ALL',
  DELIVERY_ZONE_LOAD_ALL_REQUEST: 'DELIVERY_ZONE_LOAD_ALL_REQUEST',
  DELIVERY_ZONE_LOAD_ALL_SUCCESS: 'DELIVERY_ZONE_LOAD_ALL_SUCCESS',
  DELIVERY_ZONE_LOAD_ALL_FAILURE: 'DELIVERY_ZONE_LOAD_ALL_FAILURE',

  DELIVERY_ZONE_REMOVE: 'DELIVERY_ZONE_REMOVE',
  DELIVERY_ZONE_REMOVE_REQUEST: 'DELIVERY_ZONE_REMOVE_REQUEST',
  DELIVERY_ZONE_REMOVE_SUCCESS: 'DELIVERY_ZONE_REMOVE_SUCCESS',
  DELIVERY_ZONE_REMOVE_FAILURE: 'DELIVERY_ZONE_REMOVE_FAILURE',

  DELIVERY_ZONE_SELECTION_CHANGE: 'DELIVERY_ZONE_SELECTION_CHANGE',

  DELIVERY_ZONE_ERROR_RESET: 'DELIVERY_ZONE_ERROR_RESET',
  DELIVERY_ZONE_STATE_RESET: 'DELIVERY_ZONE_STATE_RESET',

  DELIVERY_ZONE_LOCAL_EDIT_FLAG: 'DELIVERY_ZONE_LOCAL_EDIT_FLAG',

  SALES_CHANNELS_LOAD_REQUEST: 'SALES_CHANNELS_LOAD_REQUEST',
  SALES_CHANNELS_LOAD_SUCCESS: 'SALES_CHANNELS_LOAD_SUCCESS',
  SALES_CHANNELS_LOAD_FAILURE: 'SALES_CHANNELS_LOAD_FAILURE',
};

const fillOpacity = 0.3;
const strokeOpacity = 0.7;
const strokeWeight = 3;

export const deliveryZonesColors = [
  {
    fillColor: '#b0a0fa',
    strokeColor: '#5549F3',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#f8da87',
    strokeColor: '#EBB632',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#f9a0ab',
    strokeColor: '#EF444D',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#86dbf8',
    strokeColor: '#24B5E9',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#86f6d5',
    strokeColor: '#16DFA0',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#f7a4de',
    strokeColor: '#EC4DB2',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#a4f18a',
    strokeColor: '#91D773',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#9fc6fa',
    strokeColor: '#3A82F3',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#d9a2f9',
    strokeColor: '#A64DF1',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
  {
    fillColor: '#87f5f7',
    strokeColor: '#0CDADC',
    fillOpacity,
    strokeWeight,
    strokeOpacity,
  },
];

export const disabledColor = {
  fillColor: '#bbbbbb',
  strokeColor: '#888888',
  fillOpacity,
  strokeWeight,
  strokeOpacity,
};
