import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedCurrency } from 'fd-react-intl';
import Skeleton from 'react-loading-skeleton';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import InfoIcon from '../../../../ui/Tooltip/InfoIcon';
import Tooltip from '../../../../ui/Tooltip/Tooltip';
import HelpDrawer from '../../../HelpDrawer/HelpDrawer';
import HelpDrawerLink from '../../../HelpDrawer/HelpDrawerLink';
import { getCustomerOrderValue } from '../../Customers.actions';
import { getCustomerOrderValuesSelector } from '../../Customers.selectors';

const useStyles = makeStyles(() => ({
  icon: {
    display: 'inline',
    marginLeft: '6px',
  },
  learnMoreLink: {
    color: '#061c9d',
    cursor: 'pointer',
  },
}));

type PropsSection = {
  helpdeskLabel?: string;
  languageCode: string;
  loading: boolean;
  title: TranslationId;
  values: Reports.OrderValue[];
  tooltipDescription?: TranslationId;
};
export const RenderSection = (props: PropsSection) => {
  const { languageCode, helpdeskLabel, loading, title, values, tooltipDescription } = props;
  const classes = useStyles();
  const [helpdrawerOpen, setHelpDrawerOpen] = useState(false);

  const LearnMoreLink = () => (
    <HelpDrawerLink setIsOpen={setHelpDrawerOpen}>
      <span className={classes.learnMoreLink}>
        <Translate id="Learn_more" />
      </span>
    </HelpDrawerLink>
  );

  return (
    <>
      {helpdeskLabel && (
        <HelpDrawer
          label={helpdeskLabel}
          externalIsOpen={helpdrawerOpen}
          externalSetIsOpen={setHelpDrawerOpen}
        />
      )}
      <Typography variant="caption">
        <Translate id={title} />
      </Typography>
      {tooltipDescription && helpdeskLabel && (
        <Tooltip
          fdKey={`${title}-tooltip`}
          interactive
          messageId={tooltipDescription}
          messageSuffix={helpdeskLabel ? <LearnMoreLink /> : undefined}
          titleId={title}
          id={tooltipDescription}
        >
          <div className={classes.icon}>
            <InfoIcon size={20} />
          </div>
        </Tooltip>
      )}

      <Typography variant="h5" style={{ marginTop: 5 }} aria-describedby={tooltipDescription}>
        {/* Primary value is always returned first from api */}
        {loading ? (
          <Skeleton width={80} height={21} />
        ) : values && values.length ? (
          <FormattedCurrency
            value={values[0].value!}
            locales={languageCode}
            currency={values[0].currency!}
          />
        ) : (
          '-'
        )}
      </Typography>
      {values && values.length
        ? values.map(
            (sv, idx) =>
              idx > 0 && (
                <Typography variant="caption" key={idx}>
                  <FormattedCurrency
                    value={sv.value!}
                    locales={languageCode}
                    currency={sv.currency!}
                  />{' '}
                </Typography>
              )
          )
        : ''}
    </>
  );
};

type InnerProps = MappedProps & MappedDispatch;
type OuterProps = {
  activeLanguage: string;
  customerId: string;
  loading: boolean;
  storeFilter?: number[];
};
type Props = InnerProps & OuterProps;
const MainCard = (props: Props) => {
  const { averageOrderValues, estimatedAnnualValues, orderValues, totalOrders } =
    props.customerOrderValues;
  const { activeLanguage, customerId, getCustomerOrderValues, loading, storeFilter, translate } =
    props;

  const theme = useTheme();
  const isMobileOrTablet = !useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    getCustomerOrderValues(customerId, storeFilter);
  }, [storeFilter]);

  return (
    <Paper style={{ marginBottom: isMobileOrTablet ? 0 : 24 }}>
      <Grid
        container
        style={{
          paddingTop: 24,
          paddingBottom: 24,
          paddingLeft: isMobileOrTablet ? 16 : 40,
          paddingRight: 16,
        }}
      >
        <Grid item xs={6} style={{ paddingBottom: 20, paddingRight: 16 }}>
          <RenderSection
            title={'Total_order_value'}
            values={orderValues}
            languageCode={activeLanguage}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingBottom: 20 }}>
          <Typography variant="caption">
            <Translate id="Order_count" />
          </Typography>
          <Typography variant="h5" style={{ marginTop: 5 }}>
            {loading ? (
              <Skeleton width={80} height={21} />
            ) : totalOrders ? (
              new Intl.NumberFormat(activeLanguage).format(totalOrders)
            ) : (
              '-'
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ paddingRight: 16 }}>
          <RenderSection
            title={'Average_order_value'}
            values={averageOrderValues}
            languageCode={activeLanguage}
            loading={loading}
          />
        </Grid>
        <Grid item xs={6}>
          <RenderSection
            title={'Estimated_annual_value'}
            values={estimatedAnnualValues}
            languageCode={activeLanguage}
            loading={loading}
            tooltipDescription={'Estimated_annual_value_tooltip'}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  customerOrderValues: getCustomerOrderValuesSelector(state),
  translate: getTranslate(state.locale),
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    getCustomerOrderValues: (customerId: string, storeFilter?: number[]) =>
      dispatch(getCustomerOrderValue(customerId, storeFilter)),
  };
};

export default compose<InnerProps, OuterProps>(connect(mapStateToProps, mapDispatchToProps))(
  MainCard
);
