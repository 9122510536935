import React from 'react';

import { type FieldProps, Field } from 'formik';
import { Translate } from 'react-localize-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import MultiSelect from '@fd/ui/Select/Select';

const SalesChannelSelector = () => {
  return (
    <FormItemLayout label={<Translate id="Sales_channels" />}>
      <Field name="salesChannels">
        {({ field }: FieldProps) => (
          <MultiSelect
            dataFd="sales-channels-select"
            isClearable={false}
            isMulti
            maxChips={5}
            value={field.value}
            variant="standard"
            TextFieldProps={{
              style: { paddingRight: 16 },
            }}
          />
        )}
      </Field>
    </FormItemLayout>
  );
};

export default SalesChannelSelector;
