import React, { useMemo, useState } from 'react';

import { useAppSelector } from '@fd/customHooks/useAppSelector';
import type { CreateProperty, Property } from '@flipdish/orgmanagement';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import orderBy from 'lodash/orderBy';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';
import PropertiesListAdminOptions from '@fd/ui/molecules/AdminOnlyToggleFilter';

import EmptyIcon from '../../assets/images/empty_store_list.svg';
import {
  closeNotifyLoading,
  notifyError,
  notifyLoading,
  NotifyProps,
} from '../../layouts/Notify/actions';
import { permissionsSelector } from '../../selectors/permissions.selector';
import EmptyComponent from '../../ui/EmptyComponent';
import GridContainer, { useCardStyles } from '../../ui/Layout/GridContainer';
import CreateNewPropertyDialog from './components/CreateNewPropertyDialog';
import PropertyCard from './components/PropertyCard';
import {
  createPropertyForOrg,
  getAllPropertiesForOrg,
  getAllPropertiesForOrgKey,
} from './properties.services';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

const StyledCircularProgressContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  width: '100%',
}));

type Props = MappedProps & MappedDispatch;

export const PropertiesList = (props: Props) => {
  const { currentOrg, closeNotifyUpdating, notifyPublishError, notifyUpdating } = props;
  const [showArchived, setShowArchived] = useState(false);

  const cardClasses = useCardStyles();
  const classes = useStyles();
  const isFlipdishStaff = useAppSelector((state) =>
    permissionsSelector.hasPermissionFactory(['FlipdishStaff'])(state)
  );

  const orgId = currentOrg?.orgId || '';

  const {
    data: getAllProperties,
    isPending,
    isFetching,
  } = useQuery({
    queryKey: [getAllPropertiesForOrgKey, orgId],
    queryFn: () => getAllPropertiesForOrg(orgId),
    enabled: !!orgId,
  });

  const createProperty = useMutation({
    mutationFn: ({ orgId, property }: { orgId: string; property: CreateProperty }) => {
      notifyUpdating();
      return createPropertyForOrg(orgId, property);
    },
    onSuccess: () => {
      closeNotifyUpdating();
      handleDialogClose();
    },
    onError: () => {
      closeNotifyUpdating();
      notifyPublishError({ message: 'Error_please_try_again_later', translate: true });
    },
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSaveProperty = (property: CreateProperty) => {
    createProperty.mutate({ orgId, property });
  };
  const properties = getAllProperties?.data?.data ?? [];

  const sortKeys: Array<keyof Property> = ['isArchived', 'name'];

  const filteredProperties = useMemo(
    () =>
      orderBy(
        properties.filter(({ isArchived }) => !isArchived || (isFlipdishStaff && showArchived)),
        sortKeys,
        ['asc', 'asc']
      ),
    [properties, showArchived]
  );

  return (
    <PageLayout
      actionBtnTitle={'Add_Property'}
      documentTitle="Properties"
      showActionButton
      showAddIcon
      title={<Translate id="Properties" />}
      userPermissions={'Owner'}
      onClick={handleDialogOpen}
    >
      {isFlipdishStaff && (
        <PropertiesListAdminOptions
          showArchived={showArchived}
          onShowArchivedChange={setShowArchived}
        />
      )}

      <GridContainer alignItems="stretch" cards key="list">
        {filteredProperties.map((p) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={p.name}
            className={clsx(cardClasses.card, classes.gridItem)}
          >
            <PropertyCard propertyData={p} />
          </Grid>
        ))}
      </GridContainer>

      {(isPending || isFetching) && (
        <StyledCircularProgressContainer>
          <CircularProgress />
        </StyledCircularProgressContainer>
      )}

      {(!getAllProperties || filteredProperties.length === 0) && !isPending && !isFetching && (
        <EmptyComponent
          title="No_properties_found"
          subtitle="Add_properties_to_your_organization"
          icon={EmptyIcon}
          noLink
        />
      )}

      <CreateNewPropertyDialog
        open={isDialogOpen}
        onCancel={handleDialogClose}
        onSave={handleSaveProperty}
        propertiesData={filteredProperties}
      />
    </PageLayout>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  currentOrg: state.rms.currentOrg,
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  closeNotifyUpdating: () => dispatch(closeNotifyLoading()),
  notifyPublishError: (data: NotifyProps) => dispatch(notifyError(data)),
  notifyUpdating: () => dispatch(notifyLoading({ persist: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesList);
