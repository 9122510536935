import { useQuery } from '@tanstack/react-query';

import {
  getAllPropertiesForOrg,
  getAllPropertiesForOrgKey,
} from '../components/Properties/properties.services';

const useQueryPropertiesByOrgIdHook = (orgId: string) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: [getAllPropertiesForOrgKey, orgId],
    queryFn: () => getAllPropertiesForOrg(orgId),
    enabled: !!orgId,
  });

  return {
    properties: data?.data?.data || [],
    isPending,
    isFetching,
  };
};

export default useQueryPropertiesByOrgIdHook;
