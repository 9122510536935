import React, { useMemo, useState } from 'react';

import { SubscriptionInvoice } from '@flipdish/api-client-typescript';
import moment from 'moment';
import { getActiveLanguage, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import TableQuery from '@fd/ui/GenericTable/TableQuery';
import PageLayout from '@fd/ui/Layout';

import { useInfiniteQueryAllInvoicesHook } from '../../../custom-hooks/useInfiniteQueryAllInvoices';
import { notifyError } from '../../../layouts/Notify/actions';
import { showPausedPayouts } from '../../../selectors/app.selector';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import { flagService } from '../../../services';
import DynamicAlertBanner from '../../../ui/molecules/DynamicAlertBanner/DynamicAlertBanner';
import Tag from '../../common/Tag';
import { formatCurrency } from '../../Finance/Payouts/helpers';
import { FetchDataProps } from '../../Finance/Payouts/types';
import { getBillingInvoices } from '../billing.service';
import { invoiceDefaultColumns, invoiceTableMeta } from '../data/InvoiceMeta';
import InvoiceFilters from './InvoiceFilters';

export type PeriodProps = {
  dateFrom: moment.Moment | string | undefined;
  dateTo: moment.Moment | string | undefined;
};
type CurrencyEnum = Required<Flipdish.OrderSummary>['Currency'];
type InnerProps = MappedState & MappedDispatch;
type OuterProps = { overdueInvoices?: (string | undefined)[] };

type Props = InnerProps & OuterProps;

const InvoicesList = (props: Props) => {
  const {
    appId,
    dtUtils,
    isAlertBannerInvoiceOn,
    languageCode,
    overdueInvoices: overdueInvoicesParent,
    showPausedPayouts = false,
  } = props;

  const [currency, setCurrency] = useState<CurrencyEnum>('EUR');
  const [subscription, setSubscription] = useState<string>();
  const [showOnlyMyInvoicesChecked, setShowOnlyMyInvoicesChecked] = useState<boolean>(false);
  const [currentPeriodFilter, setCurrentPeriodFilter] = useState<PeriodProps>({
    dateFrom: undefined,
    dateTo: undefined,
  });
  const [searchInvoicesFilter, setSearchInvoicesFilter] = useState<string | undefined>();

  const getTableData = async (props: FetchDataProps) => {
    const data = await getBillingInvoices({ ...props.filter, ...props });

    let tableData = [] as any;
    if (data.Data) {
      tableData = data.Data.map((invoice) => {
        const FinalisedAt = invoice?.FinalisedAt && (
          <>
            {`${dtUtils.format(
              dtUtils.utcToZonedTime(invoice.FinalisedAt, dtUtils.userIanaTimeZone),
              dtUtils.LOCAL_DATE_TIME_FORMAT
            )}`}

            {invoice?.Overdue && <Tag alignSelf="center" tagName={'Overdue'} showYellowTag />}
          </>
        );
        const Total = invoice?.Currency && invoice.Total && (
          <>{formatCurrency(invoice.Total, languageCode, invoice.Currency.toString())}</>
        );
        return { ...invoice, FinalisedAt, Total };
      });
    }

    // Filter out invoices with status "void"
    const filteredTableData = tableData.filter(
      (invoice) => invoice.Status !== SubscriptionInvoice.StatusEnum.Void
    );

    return {
      Data: filteredTableData,
      Page: props.page,
      Limit: props.limit,
      TotalRecordCount: data.TotalRecordCount,
    };
  };

  const filterQueryParams = useMemo(
    () => ({
      subscriptionId: subscription,
      excludeNotOwnedInvoices: showOnlyMyInvoicesChecked,
      dateFrom: currentPeriodFilter.dateFrom,
      dateTo: currentPeriodFilter.dateTo,
      invoiceNumber: searchInvoicesFilter,
    }),
    [subscription, showOnlyMyInvoicesChecked, currentPeriodFilter, searchInvoicesFilter]
  );

  const onDateChange = (start: moment.Moment, end: moment.Moment) => {
    setCurrentPeriodFilter({
      dateFrom: start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      dateTo: end.format('YYYY-MM-DDTHH:mm:ss[Z]'),
    });
  };

  //#region DynamicAlertBanner
  const { overdueInvoices } = useInfiniteQueryAllInvoicesHook({
    appId: appId,
    isEnabled: !overdueInvoicesParent,
  });

  const showBanner =
    isAlertBannerInvoiceOn &&
    !showPausedPayouts &&
    (overdueInvoices?.length > 0 || (overdueInvoicesParent && overdueInvoicesParent?.length > 0));
  //#endregion

  return (
    <PageLayout
      title={<Translate id="Invoices" />}
      documentTitle="Invoices"
      showActionButton
      actionBtnTitle={'Contact_support'}
      hrefBtnLink={'https://support.flipdish.com/hc/en-us/requests/new?ticket_form_id=360003936037'}
      userPermissions={'ViewSubscriptions'}
      openLinkInNewTab
    >
      {showBanner && (
        <DynamicAlertBanner
          backgroundColor="yellow"
          bannerText={'You_have_1_or_more_invoices_overdue_payment'}
          buttonText={'Go_to_invoices'}
          showButton={true}
          toUrl={`/${appId}/finance/invoices`}
        />
      )}
      <InvoiceFilters
        setSubscription={setSubscription}
        onDateChange={onDateChange}
        showOnlyMyInvoicesChecked={showOnlyMyInvoicesChecked}
        setShowOnlyMyInvoicesChecked={setShowOnlyMyInvoicesChecked}
        searchInvoicesFilter={searchInvoicesFilter}
        setSearchInvoicesFilter={setSearchInvoicesFilter}
      />
      <TableQuery
        columnLocalStorageKey={'fd-invoice-table'}
        currency={currency}
        defaultTableColumns={invoiceDefaultColumns}
        emptyTableTitle={'No_invoices_found'}
        fetchData={getTableData}
        filter={filterQueryParams}
        languageCode={languageCode}
        metadata={invoiceTableMeta}
        pageLocalStorageKey={'fd-invoice-table-page'}
        rowsPerPageLocalStorageKey={'fd-invoice-table-rows-per-page'}
        storageKey={'fd-invoice-table'}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;

  return {
    appId: currentApp.AppId!,
    isAlertBannerInvoiceOn: flagService.isFlagOn(state, 'alertBannerInvoice'),
    languageCode: getActiveLanguage(state.locale),
    dtUtils: dateTimeUtils(state),
    showPausedPayouts: showPausedPayouts(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: () =>
    dispatch(
      notifyError({
        message: 'Error_please_try_again_later',
        translate: true,
      })
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(InvoicesList);
