import moment from 'moment';
import { TranslateFunction } from 'react-localize-redux';

const timeLocal = (time: string) => {
  return moment(time, ['h:mm A', 'H:mm']);
};

export const validateOpeningHours = (value: string, translate: TranslateFunction) => {
  if (!value) return undefined; // Allow empty values
  const trimmedValue = value?.trim();
  if (trimmedValue.length < 3) {
    return translate('Time_range_is_too_short');
  }

  if (
    (translate('Closed') as string)?.toLowerCase() === trimmedValue?.toLowerCase() ||
    (translate('All_day') as string)?.toLowerCase() === trimmedValue?.toLowerCase()
  ) {
    return undefined;
  }

  const earlyLateParts = trimmedValue.split(',');
  if (earlyLateParts.length > 2) {
    return translate('Maximum_of_two_time_ranges_allowed');
  }

  const valid = earlyLateParts.every((rawRange: string) => {
    const leftRightTime = rawRange.split('-');
    if (leftRightTime.length !== 2) {
      return false;
    }

    const lTime = timeLocal(leftRightTime[0].trim());
    const rTime = timeLocal(leftRightTime[1].trim());
    if (lTime.isValid() && rTime.isValid()) {
      return true;
    }
    return false;
  });

  return valid ? '' : translate('Invalid_format');
};
