import React from 'react';

import ErrorIcon from '../assets/images/illust_something_went_wrong.svg';
import EmptyComponent from './EmptyComponent';

type Props = {
  title?: TranslationId | string;
  subtitle?: TranslationId | string;
  action?: Function;
  shouldTranslate?: boolean;
};

export default function ErrorComponent(props: Props) {
  const {
    action,
    title = 'Error_component_title',
    subtitle = 'Error_component_subtitle',
    shouldTranslate = true,
  } = props;

  return (
    <EmptyComponent
      title={title}
      subtitle={subtitle}
      icon={ErrorIcon}
      action={action}
      noLink
      shouldTranslate={shouldTranslate}
    />
  );
}
