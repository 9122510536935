import React, { useEffect, useMemo, useState } from 'react';

import { Store } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import { useInfiniteQuery } from '@tanstack/react-query';
import { type FieldProps, Field } from 'formik';
import debounce from 'lodash/debounce';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Select, { OptionType } from '@fd/ui/Select/Select';

import FieldWrapper from '../../components/Finance/Banking/components/fields/FieldWrapper';
import useStoreToSalesChannelTransformerWithQuery from '../../components/Settings/utils/useStoreToSalesChannelTransformerWithQuery';
import { getStoresByAppId } from '../../services/banking.service';
import { flagService } from '../../services/flagService';
import FormItemLayout from '../Forms/FormItemLayout';
import { getSalesChannelType } from '../utils/generateSalesChannelsTypes';

export enum SalesChannelTypeEnum {
  Kiosk = 'Kiosk',
  Web = 'Web',
  Pos = 'Pos',
  App = 'App',
}

type OuterProps = {
  dataFd?: string;
  description?: TranslationId;
  enableShrink?: boolean;
  fieldLabel: TranslationId;
  fieldName: string;
  placeholder?: string;
  showValueEndAdornment?: boolean;
  salesChannelType?: SalesChannelTypeEnum;
};

type InnerProps = MappedState;
type Props = InnerProps & OuterProps;

const AssignStoresField: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    currentBrandId,
    addSalesChannelTypeToStoreVisibility,
    dataFd,
    description,
    enableShrink,
    fieldLabel,
    fieldName,
    placeholder,
    showValueEndAdornment,
    salesChannelType,
  } = props;
  const [query, setQuery] = useState('');
  const [storeSelectValue, setStoreSelectValue] = useState<OptionType[]>();
  const [storeCurrency, setStoreCurrency] = useState<Store.CurrencyEnum | string>('');
  const [storeOptions, setStoreOptions] = useState<OptionType[]>();
  const [shrink, setShrink] = useState(false);

  const storeIdAssociations = useStoreToSalesChannelTransformerWithQuery();

  const salesChannel = addSalesChannelTypeToStoreVisibility ? salesChannelType : undefined;

  // #region useInfiniteQuery
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useInfiniteQuery({
    queryKey: ['getStoresByAppId', currentBrandId, query, salesChannel],
    queryFn: async ({ pageParam = 1 }) => {
      return getStoresByAppId({
        appId: currentBrandId,
        query,
        salesChannelType: salesChannel,
        page: pageParam,
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage && lastPage.Data.length === 0) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    enabled: !!currentBrandId,
  });

  useEffect(() => {
    if (!data?.pages) return;

    const createStoreOptions = (store) => {
      const association = storeIdAssociations
        .filter((association) => association?.brandId === currentBrandId)
        .find((assoc) => assoc.storeId === store.StoreId);
      const salesChannelType = association ? association.salesChannelType : undefined;

      return {
        label: `${store.Name}${getSalesChannelType(salesChannelType ?? '')}`,
        value: String(store.StoreId),
        valueEndAdornment: showValueEndAdornment ? store.Currency : undefined,
        isDisabled: false,
        storeSCType: store.SalesChannelTypes,
        salesChannelType,
      };
    };

    const newStoreOptions = data.pages.flatMap((page) => page.Data.map(createStoreOptions));

    setStoreOptions(newStoreOptions);
  }, [data?.pages, storeIdAssociations, currentBrandId]);

  const handleScroll = ({ target }: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, clientHeight, scrollTop } = target as HTMLElement;

    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (hasScrollBar && hasNextPage && Math.ceil(scrollTop) >= scrollTopMax) {
      fetchNextPage();
    }
  };
  //#endregion

  useEffect(() => {
    if (storeOptions && storeSelectValue && storeCurrency === '' && storeSelectValue.length === 0) {
      const options = storeOptions.map((store) => {
        return {
          ...store,
          isDisabled: (store.isDisabled = false),
        };
      });
      return setStoreOptions(options);
    }

    if (storeOptions && data?.pageParams.length) {
      const options = storeOptions.map((store) => {
        return {
          ...store,
          isDisabled: store.valueEndAdornment !== storeCurrency,
        };
      });

      return setStoreOptions(options);
    }
  }, [storeCurrency]);

  const onSearchQueryChange = useMemo(
    () =>
      debounce((query: string) => {
        setQuery(query);
      }, 500),
    []
  );

  const validate = (value: string[]) => {
    if (!value || value.length === 0) {
      return <Translate id="Please_select_a_store" />;
    }
    return;
  };

  return (
    <FormItemLayout
      label={<Translate id={fieldLabel} />}
      description={description && <Translate id={description} />}
    >
      <Box onScroll={handleScroll}>
        <FieldWrapper>
          <Field name={fieldName} validate={validate}>
            {({ field, form }: FieldProps) => {
              const { errors, touched, isSubmitting } = form;
              const fieldError = errors[field.name] as string | undefined;
              const showError = !!fieldError && touched[field.name];

              const handleSelectedStoreChange = (values: OptionType[]) => {
                setStoreSelectValue(values);
                if (values.length > 0 && values[0].valueEndAdornment) {
                  setStoreCurrency(values[0].valueEndAdornment);
                } else {
                  setStoreCurrency('');
                }
                const storeId = values.map((v) => v.value);

                setShrink(true);
                form.setFieldValue(fieldName, storeId, false);
                form.setFieldError(fieldName, undefined);
              };

              return (
                <Select
                  {...field}
                  dataFd={dataFd}
                  fieldError={showError ? fieldError : undefined}
                  isClearable
                  isDisabled={isSubmitting}
                  isLoading={isPending || isFetchingNextPage}
                  isMulti
                  menuPlacement="auto"
                  onFocus={() => {
                    setQuery('');
                    setShrink(true);
                  }}
                  onChange={(values: OptionType[]) => {
                    handleSelectedStoreChange(values);
                    form.setFieldError('Store', undefined);
                  }}
                  options={storeOptions}
                  placeholder={placeholder}
                  setSearchQuery={onSearchQueryChange}
                  TextFieldProps={{
                    fdKey: 'stores-dropdown',
                    name: 'selected-stores',
                    label: <Translate id={'Select_sales_channels(s)'} />,
                    InputLabelProps: { shrink: enableShrink ? shrink : true },
                  }}
                  value={storeSelectValue}
                  variant="standard"
                />
              );
            }}
          </Field>
        </FieldWrapper>
      </Box>
    </FormItemLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale, currentApp } = state;
  return {
    translate: getTranslate(locale),
    currentBrandId: currentApp.AppId!,
    addSalesChannelTypeToStoreVisibility: flagService.isFlagOn(
      state,
      'addSalesChannelTypeToStoreVisibility'
    ),
  };
};

const EnhancedComponent = connect(mapStateToProps)(AssignStoresField);

export default EnhancedComponent;
