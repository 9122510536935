import { CSSProperties } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const StyledTag = styled(Typography)<{
  ownerState: { backgroundColor: CSSProperties['backgroundColor']; color?: CSSProperties['color'] };
}>(({ theme, ownerState }) => ({
  minWidth: 'fit-content',
  display: 'inline-flex',
  padding: theme.spacing(0.5, 1),
  borderRadius: '4px',
  backgroundColor: ownerState.backgroundColor,
  color: ownerState?.color ? ownerState.color : '#000',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: theme.spacing(2),
}));

export default StyledTag;
