import React from 'react';

import LocationIcon from '@mui/icons-material/Room';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import type { AddressSuggestionPropsType } from './types/GoogleMapTypes';

export const AddressSuggestion = ({
  option,
  optionProps,
}: AddressSuggestionPropsType): JSX.Element | null => {
  if (!option) {
    return null;
  }

  const { key, className, ...optionPropsCopy } = optionProps;

  return (
    <MenuList>
      <MenuItem {...optionPropsCopy} style={{ overflow: 'hidden' }}>
        <ListItemIcon>
          <LocationIcon fontSize="medium" />
        </ListItemIcon>
        <ListItemText>{option.description}</ListItemText>
      </MenuItem>
    </MenuList>
  );
};
