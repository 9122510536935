import * as React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const PostcodeIcon = () => {
  return (
    <SvgIcon
      data-fd="postcode_icon"
      viewBox="0 0 24 24"
      style={{
        width: '30px',
        height: '30px',
        marginRight: '12px',
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#9CA1D0"
          d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5S13.38 11.5 12 11.5z"
        />
      </g>
    </SvgIcon>
  );
};

export default PostcodeIcon;
