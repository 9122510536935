import React from 'react';

import { Brand } from '@flipdish/orgmanagement';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BRAND_SEARCH_PREFIX } from './constants';
import { OrgAndBrandSelectorItems } from './OrgAndBrandSelectorItems';

type Props = {
  brands: Brand[];
  hasSearched?: boolean;
  open: boolean;
  searchQuery: string;
  toggleOrgDrawer: () => void;
};

const BrandSelectorItems = ({
  brands,
  hasSearched,
  open,
  searchQuery,
  toggleOrgDrawer,
}: Readonly<Props>) => {
  const history = useHistory();
  const translate = useSelector(getTranslate);

  const handleBrandSelect = (brand: Brand, shouldOpenLinkInNewTab: boolean) => {
    if (!brand?.brandId) return;
    if (shouldOpenLinkInNewTab) {
      window.open(`/${brand.brandId}/home`, '_blank');
      return;
    }
    history.push(`/${brand.brandId}/home`);
    toggleOrgDrawer();
  };

  const searchTerm = searchQuery.replace(new RegExp(`^${BRAND_SEARCH_PREFIX}`, 'i'), '').trim();

  return (
    <OrgAndBrandSelectorItems<Brand>
      items={brands}
      onItemSelect={handleBrandSelect}
      noResultsText={translate('No_brands_found_for_{searchTerm}', { searchTerm }) as string}
      getItemId={(brand) => brand.brandId}
      getDisplayProps={(brand) => ({
        primary: brand.name,
        secondary: brand.brandId ?? '',
        imgUrl: brand.logoImageUrl,
      })}
      hasSearched={hasSearched}
      open={open}
    />
  );
};

export default BrandSelectorItems;
