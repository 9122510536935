import React, { useEffect } from 'react';

import { App } from '@flipdish/api-client-typescript';
import Loading from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps } from 'react-router';
import { type UnpackICEWP, compose, setDisplayName, withStateHandlers } from 'recompose';

import { useRedirectIfStoresPageHidden } from '../../custom-hooks/useRedirectIfStoresPageHidden';
import CreateNewButton from '../../layouts/Portal/CreateNewButton';
import { useTracking } from '../../services/amplitude/useTracking';
import PageLayout from '../../ui/Layout';
import GridContainer from '../../ui/Layout/GridContainer';
import PaperContainer from '../../ui/Layout/PaperContainer';
import SearchTextField from '../../ui/SearchTextField/SearchTextField';
import Spacer from '../../ui/Spacer';
import WindowInfiniteScroll from '../../ui/WindowInfiniteScroll';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../WithRouteSearchParams';
import { CreateStoreGroupDialog } from './components/CreateStoreGroupDialog';
import StoreGroupCard from './components/StoreGroupCard';
import withStoreGroupData, { StoreGroupDataComponentProps } from './withStoreGroupData';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

const Loader = () => (
  <Grid key="loader" container justifyContent="center" style={{ padding: `10px 0` }}>
    <Loading />
  </Grid>
);

const StoreGroupList = (
  props: StoreGroupDataComponentProps & WithRouteSearchParamsProps<string>
) => {
  const { storeGroupData, storeGroupeTotalCount, storeGroupDataLoading, match, history } = props;
  const storeGroup = storeGroupData[0];

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_list', {
      action: 'logged_in',
    });
  }, []);

  const loadMore = async (page) => {
    try {
      await props.loadNext(page, 20);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return () => {
      props.storeGroupSetSearch(props.search);
    };
  }, []);
  useEffect(() => {
    if (!storeGroupDataLoading && storeGroup && storeGroupData.length === 1) {
      // @ts-ignore
      if (storeGroup.AppId === match.params.appId) {
        history.replace(`/${match.params.appId}/storegroups/${storeGroup.StoreGroupId}/stores`);
      }
    }
  }, [match.params.appId, storeGroup, storeGroupDataLoading]);

  return (
    <WindowInfiniteScroll
      pageStart={0}
      loadMore={loadMore}
      hasMore={storeGroupData.length !== storeGroupeTotalCount}
      loader={Loader}
      initialLoad
    >
      {storeGroupData.length ? (
        <PaperContainer fluid>
          <List>
            {storeGroupData.map((g, index) => {
              if (!g) {
                return null;
              }
              return (
                <StoreGroupCard
                  isLast={index === storeGroupData.length - 1}
                  key={g.StoreGroupId}
                  storeGroupId={g.StoreGroupId as number}
                />
              );
            })}
          </List>
        </PaperContainer>
      ) : null}
    </WindowInfiniteScroll>
  );
};

type AddStoreGroupDialogState = UnpackICEWP<typeof useAddStoreGroupDialogState> & {
  dialogOn(): boolean;
  dialogOff(): boolean;
};

const useAddStoreGroupDialogState = withStateHandlers(
  () => ({
    dialog: false,
  }),
  {
    dialogOn: () => () => ({
      dialog: true,
    }),
    dialogOff: () => () => ({
      dialog: false,
    }),
  }
);

type Props = StoreGroupDataComponentProps &
  AddStoreGroupDialogState &
  RouteComponentProps &
  WithRouteSearchParamsProps<string>;
const StoreGroups = compose<Props, {}>(
  setDisplayName('StoreGroups'),
  withRouteSearchParams({
    name: 'filter',
  }),
  withStoreGroupData,
  useAddStoreGroupDialogState
)((props: Props) => {
  const { dialog, dialogOn, dialogOff, location, staticContext, search, setSearch, ...data } =
    props;

  const classes = useStyles();
  const isRedirecting = useRedirectIfStoresPageHidden();
  if (isRedirecting) {
    return null;
  }

  const onSearch = (search: string) => {
    setSearch(search);
    props.storeGroupSetSearch(search);
    props.loadNext(1, 20);
  };
  const CreateNewStoreGroup = () => (
    <Permissions allowed={[App.AppResourceSetEnum.CreateStoreGroups]}>
      <CreateNewButton translateId="New_store_group" onClick={dialogOn} />
    </Permissions>
  );

  const handleStoreGroupCreated = (newStoreGroupId) => {
    dialogOff();
    data.history.push(`/${data.match.params.appId}/storegroups/${newStoreGroupId}/stores`);
  };

  const header = (
    <GridContainer>
      <Grid item xs={12} sm={6} className={classes.gridItem}>
        <Collapse in={data.storeGroupShowSearch} mountOnEnter unmountOnExit>
          {data.storeGroupShowSearch && (
            <>
              <SearchTextField
                fdKey="Search_storegroups"
                label={<Translate id="Search" />}
                defaultValue={search}
                onChange={(e) => onSearch(e)}
                fullWidth
              />
              <Spacer size={32} />
            </>
          )}
        </Collapse>
      </Grid>
    </GridContainer>
  );

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'store.*' }}
      actions={CreateNewStoreGroup}
      documentTitle={'Store_groups'}
      fluid
      header={header}
      title={<Translate id="Store_groups" />}
    >
      <StoreGroupList {...data} search={search} setSearch={setSearch} />
      <CreateStoreGroupDialog
        open={dialog}
        handleClose={dialogOff}
        onStoreGroupCreated={handleStoreGroupCreated}
      />
    </PageLayout>
  );
});

export default StoreGroups;
