import React, { useRef, useState } from 'react';

import { MetafieldDefinition, PendingMenuChanges, Product } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { TranslateFunction } from 'react-localize-redux';

import { InputField, MessageBanner, Typography } from '@fd/ui/atoms';
import { FormikInputField, FormSection } from '@fd/ui/molecules';

import { priceRegExp } from '../../../../../helpers/validation';
import PreventNavigation from '../../../../Finance/Banking/components/PreventNavigation';
import { CatalogMetafieldsFormSection } from '../../../components/CatalogMetafieldsFormSection';
import { ImagerySection } from '../../../components/ImagerySection';
import { InternalNoteFormSection } from '../../../components/InternalNoteFormSection';
import { PendingMenuChangesBanner } from '../../../components/PendingMenuChangesBanner';
import { SaveCatalogChangesButton } from '../../../components/SaveCatalogChangesButton/SaveCatalogChangesButton';
import { INTERNAL_NOTES_METAFIELD_KEY } from '../../../constants';
import { catalogImagesService } from '../../../services/catalogImages.service';
import { setMetafield, validateCatalogItemForm } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  formContent: {
    paddingBottom: theme.spacing(3),
  },
  field: {
    minHeight: '5rem',
    marginTop: theme.spacing(1),
  },
  priceField: {
    width: '200px',
    minHeight: '5rem',
  },
  productId: {
    alignSelf: 'center',
    marginTop: theme.spacing(3),
    marginBottm: theme.spacing(2),
  },
  saveButtonContainer: {
    marginTop: theme.spacing(14),
    paddingRight: theme.spacing(3),
  },
}));

export type EditFormProps = {
  appId: string;
  canEdit: boolean;
  isLoading: boolean;
  metafields?: MetafieldDefinition[];
  modifier?: Product;
  onSaveChanges: (updatedModifier, saveType: number) => void;
  pendingMenuChanges?: PendingMenuChanges[];
  translate: TranslateFunction;
};

const EditModifierForm = (props: EditFormProps): JSX.Element => {
  const {
    appId,
    canEdit,
    isLoading,
    metafields,
    modifier,
    onSaveChanges,
    pendingMenuChanges = [],
    translate,
  } = props;

  const classes = useStyles();
  const [saveType, setSaveType] = useState<number>(0);
  const formRef = useRef<any>(null);
  const disabled = !canEdit || modifier?.IsArchived;
  const showInternalNotes =
    metafields &&
    metafields?.findIndex((metafield) => metafield.Key === INTERNAL_NOTES_METAFIELD_KEY) !== -1;
  const customMetafields = metafields?.filter(
    (metafield) => metafield.Key !== INTERNAL_NOTES_METAFIELD_KEY
  );

  const getInternalNote = (metafields): string => {
    const noteField = metafields.find(
      (metafield) => metafield.Key === INTERNAL_NOTES_METAFIELD_KEY
    );
    return noteField?.Value || '';
  };
  const setMetafieldValue = (key: string, value: string) => {
    const metafieldIndex = formRef.current?.values.Metafields?.findIndex(
      (metafield) => metafield.Key === key
    );
    if (metafieldIndex !== -1 && value != '') {
      formRef.current?.setFieldValue(`Metafields[${metafieldIndex}].Value`, value);
    } else {
      const updatedMetafields = setMetafield(key, value, formRef.current?.values.Metafields);
      formRef.current?.setFieldValue('Metafields', updatedMetafields);
    }
  };

  return (
    <Paper>
      {pendingMenuChanges.length > 0 && (
        <PendingMenuChangesBanner
          appId={appId}
          catalogElementId={modifier?.CatalogItemId as string}
          changesList={pendingMenuChanges}
          translate={translate}
        />
      )}
      {modifier?.IsArchived && (
        <MessageBanner
          fdKey="modifier-archived-banner"
          title={translate('Products_EditProductForm_ArchiveBannerTitle') as string}
          message={translate('Products_EditProductForm_ArchiveBannerMessage') as string}
        />
      )}
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={{
          ImageFileName: props.modifier?.ImageFileName || '',
          Metafields: props.modifier?.Metafields || [],
          Name: props.modifier?.Name || '',
          Price: props.modifier?.Price?.toFixed(2) || 0,
          Sku: props.modifier?.Sku || '',
        }}
        validate={(values) => {
          return validateCatalogItemForm(values, translate);
        }}
        onSubmit={async (values, { setFieldError, setSubmitting }) => {
          try {
            const changes = {
              ...values,
              Price: Number(values.Price),
            };
            await onSaveChanges(changes, saveType);
            setSubmitting(false);
          } catch (error) {
            console.log('error', error);
            const errorMessage = translate(
              'Product_CreateProductDialog_DuplicateSkuError'
            ) as string;
            error.message.includes('SKU already exists') && setFieldError('Sku', errorMessage);
            setSubmitting(false);
          }
        }}
      >
        {({ dirty, setFieldValue, values }) => (
          <Form className={classes.formContent}>
            <PreventNavigation when={dirty} />
            <FormSection sectionTitle={translate('Basic') as string}>
              <FormikInputField
                isLoading={isLoading}
                className={classes.field}
                fdKey={'edit-modifier-form-name'}
                name="Name"
                label={translate('Products_EditProductForm_EnterProductName') as string}
                InputProps={{
                  readOnly: disabled,
                }}
              />
            </FormSection>
            {showInternalNotes && (
              <InternalNoteFormSection
                onNoteChange={(newValue) =>
                  setMetafieldValue(INTERNAL_NOTES_METAFIELD_KEY, newValue)
                }
                text={getInternalNote(values.Metafields)}
                translate={translate}
              />
            )}
            <FormSection sectionTitle={translate('Products_EditProductForm_Pricing') as string}>
              <FormikInputField
                isLoading={isLoading}
                className={classes.priceField}
                disableLabelAnimation
                fdKey={'edit-modifier-form-price'}
                name="Price"
                label={translate('Products_EditProductForm_DefaultPrice') as string}
                type="number"
                variant="outlined"
                InputProps={{
                  readOnly: disabled,
                }}
                inputProps={{ inputMode: 'decimal', pattern: priceRegExp, step: 'any' }}
                onBlur={(e) => {
                  const value = Number(e.target.value);
                  const formatted = value.toFixed(2);
                  setFieldValue('Price', formatted);
                }}
              />
            </FormSection>
            <FormSection sectionTitle={translate('Products_EditProductForm_Imagery') as string}>
              <ImagerySection
                appId={appId}
                description={
                  <Typography variant="caption">
                    {translate('Modifiers_EditModifierForm_ImageDescription')}
                  </Typography>
                }
                disabled={disabled}
                imageFileName={values.ImageFileName}
                isLoading={isLoading}
                onImageRemoved={() => setFieldValue('ImageFileName', '')}
                onImageUploaded={(uri: string) => setFieldValue('ImageFileName', uri)}
                translate={translate}
                uploadImage={catalogImagesService.uploadCatalogImage}
              />
            </FormSection>
            <FormSection
              sectionTitle={translate('Products_EditProductForm_Identification') as string}
            >
              <Typography variant="caption">
                {translate('Products_EditProductForm_IdentificationDescription') as string}
              </Typography>
              <Grid container item spacing={3}>
                <Grid item className={classes.productId} xs={6}>
                  <Typography variant="body2">{translate('Flipdish_ID') as string}</Typography>
                </Grid>
                <Grid item className={classes.productId} xs={6}>
                  <InputField
                    disabled
                    fdKey={'flipdish-id-field'}
                    isLoading={isLoading}
                    value={modifier?.CatalogItemId}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikInputField
                    isLoading={isLoading}
                    className={classes.field}
                    fdKey={'edit-modifier-form-sku'}
                    name="Sku"
                    label="SKU"
                    InputProps={{
                      readOnly: disabled,
                    }}
                  />
                </Grid>
              </Grid>
            </FormSection>
            {customMetafields && customMetafields.length > 0 && (
              <CatalogMetafieldsFormSection
                metafields={customMetafields}
                onMetafieldChange={(key, value) => setMetafieldValue(key, value)}
                populatedMetafields={values.Metafields}
                translate={translate}
              />
            )}
            <Grid
              container
              className={classes.saveButtonContainer}
              item
              xs={12}
              justifyContent="flex-end"
            >
              {!disabled && (
                <SaveCatalogChangesButton
                  fdKey="edit_modifier_save_button"
                  disabled={!dirty || disabled}
                  onSaveTypeChange={(newSaveType) => setSaveType(newSaveType)}
                  translate={translate}
                />
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export { EditModifierForm };
