import { TranslateFunction } from 'react-localize-redux';

export enum PostcodeValidationStatus {
  VALID = 'Valid',
  TOO_MANY_POSTCODES = 'Too many postcodes',
  INVALID_POSTCODES = 'Invalid postcodes',
  NO_POSTCODES_FOUND = 'No postcodes found',
}

export const validatePostcodes = (postcodes: string): PostcodeValidationStatus => {
  const bigPostcodeRegex = /^[A-Z]{1,2}\d{1,2}$/i;
  const postcodeArray = postcodes.split(',').map((postcode) => postcode.trim());

  // Check if the number of postcodes exceeds 25
  if (postcodeArray.length > 25) {
    return PostcodeValidationStatus.TOO_MANY_POSTCODES;
  }

  const allValid = postcodeArray.every((postcode) => bigPostcodeRegex.test(postcode));
  return allValid ? PostcodeValidationStatus.VALID : PostcodeValidationStatus.INVALID_POSTCODES;
};

export const getPostcodeValidationMessage = (
  status: PostcodeValidationStatus,
  translate: TranslateFunction
): string => {
  switch (status) {
    case PostcodeValidationStatus.VALID:
      return translate('Postcodes_are_valid') as string;
    case PostcodeValidationStatus.TOO_MANY_POSTCODES:
      return translate('Too_many_postcodes_Maximum_allowed_is_25') as string;
    case PostcodeValidationStatus.INVALID_POSTCODES:
      return translate('Invalid_postcodes_Please_enter_valid_UK_postcodes') as string;
    case PostcodeValidationStatus.NO_POSTCODES_FOUND:
      return translate('No_postcodes_found_Please_enter_valid_UK_postcodes') as string;
    default:
      return translate('Unknown_validation_status') as string;
  }
};
