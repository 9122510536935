import { SalesChannelType } from '../../custom-hooks/useQueryRMSSalesChannels';

export function getSalesChannelType(salesChannelType: string): string {
  switch (salesChannelType) {
    case SalesChannelType.POS:
      return ' [POS]';
    case SalesChannelType.UBER_EATS:
      return ' [Uber]';
    case SalesChannelType.DELIVEROO:
      return ' [Deliveroo]';
    case SalesChannelType.JUST_EATS:
      return ' [JustEat]';
    case SalesChannelType.FLIPDISH_WEB_APP:
      return ' [Web]';
    case SalesChannelType.FLIPDISH_KIOSK:
      return ' [Kiosk]';
    case SalesChannelType.FLIPDISH_MOBILE_APP:
      return ' [Apps]';
    case SalesChannelType.EXTERNAL_APP:
      return ' [ExternalApp]';
    case SalesChannelType.WEB_APP:
      return ' [WebApp]';
    case SalesChannelType.MOBILE_APP:
      return ' [MobileApp]';
    default:
      return '';
  }
}
