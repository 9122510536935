import React, { useEffect } from 'react';

import { useAppDispatch } from '@fd/customHooks/useAppDispatch';
import { useAppSelector } from '@fd/customHooks/useAppSelector';
import { useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';

import { appsActions } from '../actions/apps.actions';
import { brandService } from '../components/Settings/Brands/brands.services';
import { notifyError } from '../layouts/Notify/actions';
import { flagService } from '../services';
import { isOrgInPathName } from './utils';

const RedirectOrgToBrand = () => {
  const dispatch = useAppDispatch();
  const isFlagOn = useAppSelector((state) => flagService.isFlagOn(state, 'redirectOrgToBrand'));
  const translate = useAppSelector((state) => getTranslate(state));

  const locationUrl = new URL(window.location.href);
  let orgId = '';

  if (isOrgInPathName(locationUrl.pathname)) {
    orgId = locationUrl.pathname.slice(1);
  }

  const {
    data: brandsResponse,
    isFetched,
    isLoading,
    error: brandsForOrgError,
  } = useQuery({
    queryKey: [brandService.getBrandsForOrgKey, orgId],
    queryFn: () => brandService.getBrandsForOrg(orgId),
    enabled: !!orgId && isFlagOn,
  });

  useEffect(() => {
    const firstBrandId =
      brandsResponse?.data?.data?.length && brandsResponse?.data?.data[0].brandId;
    if (orgId && firstBrandId && isFetched && !isLoading) {
      dispatch(appsActions.setCurrentApp(firstBrandId));
    } else if (isFetched && !isLoading && !brandsForOrgError && !firstBrandId) {
      dispatch(
        notifyError({
          message: translate('No_brands_found', { orgId }) as string,
          translate: false,
        })
      );
    }
  }, [brandsResponse, isFetched, isLoading, orgId]);

  useEffect(() => {
    const brandsForOrgErrorMessage = brandsForOrgError?.message;
    if (brandsForOrgErrorMessage) {
      dispatch(notifyError({ message: brandsForOrgErrorMessage, translate: false }));
    }
  }, [brandsForOrgError]);

  return <></>;
};

export default RedirectOrgToBrand;
