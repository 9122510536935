import { getActiveLanguage, setActiveLanguage } from 'react-localize-redux';

import { accountService } from '../services/account.service';
import { getSupportedLanguages } from '../utils/languageUtils';

export const languageActions = {
  setLanguage,
};

function setLanguage(lang: string) {
  return (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const activeLanguage = getActiveLanguage(state.locale);

    if (String(activeLanguage) !== lang) {
      const supportedLanguages = getSupportedLanguages();
      if (supportedLanguages.includes(lang)) {
        dispatch(setActiveLanguage(lang));
        setInLocalStorage('flipdish-current-language', lang);
        dispatch(setLanguageWithBackEnd(lang));
      } else {
        console.log('Language not supported', lang);
      }
    }
  };
}

function setLanguageWithBackEnd(lang: string) {
  return (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const accountLanguage = state.account.Language;
    if (accountLanguage && accountLanguage !== lang) {
      accountService.updateAccount({ Language: lang }).catch((err) => {
        console.error(err);
      });
    }
  };
}

export function setInLocalStorage(key: string, value: string) {
  const item = localStorage.getItem(key);
  if (item) {
    localStorage.removeItem(key);
  }
  localStorage.setItem(key, value);
}
