import React, { useEffect, useState } from 'react';

import {
  SalesChannelType,
  useQueryRMSSalesChannels,
} from '@fd/customHooks/useQueryRMSSalesChannels';
import type {
  CloneSalesChannelTypeEnum,
  SalesChannel,
  SalesChannelsApiCloneSalesChannelRequest,
} from '@flipdish/orgmanagement';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Dialog } from '@fd/ui/molecules';
import Select from '@fd/ui/Select/Select';

import { getSalesChannelTypeFromUrl } from './utils';

const StyledCancelButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1.25),
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .MuiPaper-root': {
    zIndex: theme.zIndex.modal + 1,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  minHeight: '335px',
  [theme.breakpoints.down('sm')]: {
    minWidth: '90%!important',
  },
  '& .MuiDialog-paper': {
    overflow: 'visible',
  },
  '& .MuiDialogContent-root': {
    overflow: 'visible',
  },
}));

type CreateNewSalesChannelDialogProps = {
  isLoading: boolean;
  open: boolean;
  onCancel: () => void;
  onSave: (values: SalesChannelsApiCloneSalesChannelRequest) => void;
  salesChannelTypeForAddUrl?: string;
  orgPropertyId?: string;
};

type Props = CreateNewSalesChannelDialogProps & MappedProps;

const CreateNewSalesChannelDialog = (props: Props) => {
  const {
    isLoading,
    orgId = '',
    onCancel,
    onSave,
    open,
    translate,
    salesChannelTypeForAddUrl,
    orgPropertyId = '',
  } = props;
  const history = useHistory();
  const { pathname } = useLocation();
  const isPosPage = pathname.includes('point-of-sale');
  const isMarketplacePage = pathname.includes('marketplaces');
  const [handleValue, setHandleValue] = useState<string>('');

  const [selectedSalesChannelData, setSelectedSalesChannelData] = useState<SalesChannel>({});

  let salesChannelFilters = [
    SalesChannelType.FLIPDISH_KIOSK,
    SalesChannelType.FLIPDISH_MOBILE_APP,
    SalesChannelType.FLIPDISH_WEB_APP,
  ];
  if (isPosPage) {
    salesChannelFilters = [SalesChannelType.POS];
  }
  if (isMarketplacePage) {
    salesChannelFilters = [
      SalesChannelType.DELIVEROO,
      SalesChannelType.JUST_EATS,
      SalesChannelType.UBER_EATS,
    ];
  }

  const { data: salesChannels, isLoading: isSalesChannelsLoading } = useQueryRMSSalesChannels({
    orgId,
    propertyId: orgPropertyId,
    filterByType: salesChannelFilters,
  });

  useEffect(() => {
    if (
      salesChannels?.length &&
      handleValue === 'copy' &&
      !selectedSalesChannelData?.salesChannelId
    ) {
      setSelectedSalesChannelData(salesChannels[0]);
    }
  }, [salesChannels, handleValue]);

  const handleSave = () => {
    const cloneValues = {
      orgId,
      propertyId: selectedSalesChannelData?.propertyId ?? '',
      salesChannelId: selectedSalesChannelData?.salesChannelId ?? '',
    };

    if (!isPosPage && !isMarketplacePage) {
      cloneValues['cloneSalesChannel'] = {
        type: getSalesChannelTypeFromUrl(pathname) as CloneSalesChannelTypeEnum,
      };
    }

    if (handleValue === 'copy') {
      onSave(cloneValues);
    } else if (handleValue === 'new') {
      history.push(`${salesChannelTypeForAddUrl}/property/${orgPropertyId}/add-sales-channel`);
      onCancel();
    }
  };

  const handleCopyFromExistingSalesChannel = (channelId: string) => {
    const selectedSalesChannel = salesChannels?.find((sc) => sc.salesChannelId === channelId);
    if (selectedSalesChannel) {
      setSelectedSalesChannelData(selectedSalesChannel);
    }
  };

  const onChangeCopyControl = (event) => {
    setHandleValue(event.target.value);
  };

  return (
    <StyledDialog
      open={open}
      title={translate('Add_new_sales_channel') as string}
      actions={[
        <StyledCancelButton
          key="cancel-button"
          onClick={onCancel}
          variant="text"
          disabled={isLoading}
          aria-disabled={isLoading}
        >
          <Translate id="Cancel" />
        </StyledCancelButton>,
        <Button
          key="create-button"
          onClick={handleSave}
          disabled={isLoading}
          aria-disabled={isLoading}
        >
          <Translate id="Create" />
        </Button>,
      ]}
    >
      <RadioGroup
        aria-disabled={isLoading}
        aria-label={translate('Add_new_sales_channel') as string}
        name="CreateNewStoreOptions"
        value={handleValue}
        onChange={onChangeCopyControl}
      >
        <FormControlLabel
          disabled={isLoading}
          control={<Radio color="primary" data-fd="create_new_radio" />}
          label={translate('Create_new') as string}
          value="new"
        />
        <FormControlLabel
          disabled={isLoading}
          control={<Radio color="primary" data-fd="copy_store_radio" />}
          label={translate('Copy_data_from') as string}
          value="copy"
        />
      </RadioGroup>

      {handleValue === 'copy' && salesChannels?.length && (
        <StyledSelect
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 9999,
            }),
          }}
          data-fd="copy_sales_channel_select"
          aria-disabled={isLoading}
          isDisabled={isLoading}
          isLoading={isSalesChannelsLoading}
          variant="standard"
          fullWidth
          value={{
            value: selectedSalesChannelData?.salesChannelId,
            label: `${selectedSalesChannelData?.name} - ${selectedSalesChannelData?.type}`,
          }}
          defaultValue={{ value: salesChannels[0]?.salesChannelId, label: salesChannels[0]?.name }}
          onChange={(selectedOption) => handleCopyFromExistingSalesChannel(selectedOption?.value)}
          options={salesChannels?.map((sc) => ({
            value: sc.salesChannelId,
            label: `${sc.name} - ${sc.type}`,
          }))}
        />
      )}
    </StyledDialog>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  translate: getTranslate(state.locale),
  orgId: state.currentApp.OrgId,
});

export default connect(mapStateToProps)(CreateNewSalesChannelDialog);
