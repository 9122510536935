import React from 'react';

import { styled } from '@mui/material/styles';

import generateSrcSet from '../utils/generateSrcSet';
import { ImgixImage } from './ImgixImage';

const StyledImgContainer = styled('div')({
  width: 40,
  height: 40,
  overflow: 'hidden',
  alignContent: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface ImgixLogoWrapperProps {
  src?: string;
  alt?: string;
  className?: string;
}

const DEFAULT_LOGO_URL = 'https://flipdish.imgix.net/gddSky3brWXB4bHWcfax5M130.jpg';
const DEFAULT_ALT_TEXT = 'Logo image';

export const ImgixLogoWrapper: React.FC<ImgixLogoWrapperProps> = ({ src, alt, className }) => {
  const isImgixUrl = src?.includes('imgix.net');

  return (
    <StyledImgContainer className={className}>
      {isImgixUrl ? (
        <ImgixImage
          src={src || DEFAULT_LOGO_URL}
          sizes="40px"
          imgixParams={{
            fit: 'inside',
            auto: 'format,compress',
            fm: 'png',
            bg: 'transparent',
          }}
          alt={alt || DEFAULT_ALT_TEXT}
        />
      ) : (
        <img
          srcSet={generateSrcSet(src || DEFAULT_LOGO_URL, {
            width: 40,
            png: true,
          })}
          src={src || DEFAULT_LOGO_URL}
          alt={alt || DEFAULT_ALT_TEXT}
        />
      )}
    </StyledImgContainer>
  );
};
