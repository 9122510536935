import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { OrderIngestType, SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AccountBalanceOutlined from '@mui/icons-material/AccountBalanceOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import MoreTimeOutlinedIcon from '@mui/icons-material/MoreTimeOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import Warning from '@mui/icons-material/Warning';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import ZoomInMapOutlinedIcon from '@mui/icons-material/ZoomInMapOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useParams } from 'react-router-dom';

import { toKebabCase } from '../../../helpers/strings';
import { permissionsSelector } from '../../../selectors/permissions.selector';
import { flagService } from '../../../services/flagService';
import ListItemLinkButton from '../../../ui/List/ListItemLinkButton';
import { getStoreFrontOperatingConfigurations } from '../rms.services';
import { RMSModules } from '../utils/rmsModules';

const useStyles = makeStyles(() => ({
  listItemDanger: {
    color: '#ff426a',
  },
}));

type POSChannelSettingsListProps = {
  baseUrl: string;
  salesChannelType: SalesChannelType;
} & MappedState;
const POSChannelSettingsList = (props: POSChannelSettingsListProps) => {
  const classes = useStyles();
  const { baseUrl, salesChannelType, appId, isFlipdishStaff, showPropertyOpeningHours } = props;
  const { propertyId, channelId } = useParams<{ channelId: string; propertyId: string }>();
  const { data: rmsCommonData, isLoading: isLoadingRMSCommonData } =
    useQueryRMSCommonDataHook(appId);

  const { data: storefrontInformation } = useQuery({
    queryKey: ['getLocationId', baseUrl, salesChannelType, propertyId, channelId],
    queryFn: async () => {
      const storeFront = rmsCommonData?.associations?.orgHierarchy.properties
        .find((property) => property.code === propertyId)
        ?.storefronts.find((storefront) => storefront.code === channelId);
      if (!storeFront) {
        return {
          isUsingCreditCardTerminals: false,
          locationId: undefined,
          orderIngestType: undefined,
          orderIngestRefPriceDispatchType: undefined,
        };
      }

      const result = await getStoreFrontOperatingConfigurations(
        storeFront.franchisorId,
        storeFront.franchiseeId,
        storeFront.propertyId,
        storeFront.id
      );
      const locationId = result.data?.locationId;
      return {
        isUsingCreditCardTerminals: locationId?.length > 0,
        locationId,
        orderIngestType: storeFront.orderIngestType,
        orderIngestRefPriceDispatchType: storeFront.orderIngestRefPriceDispatchType,
      };
    },
    enabled:
      isFlipdishStaff &&
      (salesChannelType === SalesChannelType.POS ||
        salesChannelType === SalesChannelType.JUST_EATS) &&
      !isLoadingRMSCommonData &&
      !!rmsCommonData?.associations,
  });

  const orderIngestType = storefrontInformation?.orderIngestType;
  const isJustEatUrbanPiper =
    salesChannelType === SalesChannelType.JUST_EATS &&
    orderIngestType?.toLowerCase() === OrderIngestType.URBAN_PIPER.toLowerCase();

  return (
    <>
      <ListItemLinkButton
        title="General_settings"
        subtitle="Set_name_price_bands_and_menu_View_associated_bran"
        link={`${baseUrl}/edit`}
        iconComponent={<SettingsOutlinedIcon />}
      />
      <ListItemLinkButton
        title="Operating_Configuration"
        subtitle="Configure_operating_configuration"
        link={`${baseUrl}/${toKebabCase(RMSModules.OPERATING_CONFIGURATION)}`}
        iconComponent={<SettingsApplicationsOutlinedIcon />}
      />
      {salesChannelType === SalesChannelType.POS && (
        <Permissions allowed={['FlipdishStaff']}>
          <ListItemLinkButton
            tagText="Flipdish_staff_only"
            title="Payout_Configuration"
            subtitle="Configure_payout_configuration"
            link={`${baseUrl}/${toKebabCase(RMSModules.PAYOUT_CONFIGURATION)}`}
            iconComponent={<CreditCardOutlinedIcon />}
          />
        </Permissions>
      )}
      {![
        SalesChannelType.DELIVEROO,
        SalesChannelType.UBER_EATS,
        SalesChannelType.JUST_EATS,
      ].includes(salesChannelType) && (
        <ListItemLinkButton
          title="Banking_title"
          subtitle="Add_and_edit_bank_account_connected_to_this_sales"
          link={`${baseUrl}/bankingsettings`}
          iconComponent={<AccountBalanceOutlined />}
        />
      )}
      {([
        SalesChannelType.FLIPDISH_KIOSK,
        SalesChannelType.FLIPDISH_MOBILE_APP,
        SalesChannelType.FLIPDISH_WEB_APP,
      ].includes(salesChannelType) ||
        (salesChannelType === SalesChannelType.JUST_EATS && !isJustEatUrbanPiper)) && (
        <ListItemLinkButton
          title="Opening_Hours"
          subtitle="Configure_sales_channel_opening_hours"
          link={
            showPropertyOpeningHours
              ? `${baseUrl}/opening-hours-v2`
              : `${baseUrl}/${toKebabCase(RMSModules.OPENING_HOURS)}`
          }
          iconComponent={<AccessTimeOutlinedIcon />}
        />
      )}
      {([SalesChannelType.DELIVEROO, SalesChannelType.UBER_EATS].includes(salesChannelType) ||
        isJustEatUrbanPiper) && (
        <ListItemLinkButton
          title="Opening_Hours"
          subtitle="Configure_sales_channel_opening_hours"
          link={`${baseUrl}/opening-hours`}
          iconComponent={<AccessTimeOutlinedIcon />}
        />
      )}

      {[
        SalesChannelType.FLIPDISH_KIOSK,
        SalesChannelType.FLIPDISH_MOBILE_APP,
        SalesChannelType.FLIPDISH_WEB_APP,
      ].includes(salesChannelType) && (
        <ListItemLinkButton
          title="Opening_Hours_Overrides"
          subtitle="Configure_sales_channel_opening_hours_overrides"
          link={`${baseUrl}/${toKebabCase(RMSModules.OPENING_HOURS_OVERRIDES)}`}
          iconComponent={<AccessTimeFilledOutlinedIcon />}
        />
      )}
      <ListItemLinkButton
        title="Delivery_Charges"
        subtitle="Configure_delivery_charges"
        link={`${baseUrl}/${toKebabCase(RMSModules.DELIVERY_CHARGES)}`}
        iconComponent={<MopedOutlinedIcon />}
      />
      {salesChannelType === SalesChannelType.POS && (
        <ListItemLinkButton
          title="Tip_Configuration"
          subtitle="Configure_tip_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.TIP_CONFIGURATION)}`}
          iconComponent={<AttachMoneyOutlinedIcon />}
        />
      )}
      {[
        SalesChannelType.UBER_EATS,
        SalesChannelType.JUST_EATS,
        SalesChannelType.DELIVEROO,
        SalesChannelType.FLIPDISH_KIOSK,
        SalesChannelType.FLIPDISH_MOBILE_APP,
        SalesChannelType.FLIPDISH_WEB_APP,
        SalesChannelType.EXTERNAL_APP,
      ].includes(salesChannelType) && (
        <Permissions allowed={['FlipdishStaff']}>
          <ListItemLinkButton
            tagText="Flipdish_staff_only"
            title="Order_Ingest"
            subtitle="Configure_order_ingest"
            link={`${baseUrl}/${toKebabCase(RMSModules.ORDER_INGEST)}`}
            iconComponent={<ZoomInMapOutlinedIcon />}
          />
        </Permissions>
      )}
      <ListItemLinkButton
        title="Timings_Configuration"
        subtitle="Configure_timings_configuration"
        link={`${baseUrl}/${toKebabCase(RMSModules.TIMINGS_CONFIGURATION)}`}
        iconComponent={<MoreTimeOutlinedIcon />}
        isLast={
          ![SalesChannelType.POS, SalesChannelType.WEB_APP, SalesChannelType.MOBILE_APP].includes(
            salesChannelType
          )
        }
      />
      {salesChannelType === SalesChannelType.POS && (
        <ListItemLinkButton
          title="Over_Phone_Payment"
          subtitle="Configure_over_phone_payment"
          link={`${baseUrl}/${toKebabCase(RMSModules.OVER_PHONE_PAYMENT)}`}
          iconComponent={<LocalPhoneOutlinedIcon />}
          isLast={!storefrontInformation?.isUsingCreditCardTerminals}
        />
      )}
      {salesChannelType === SalesChannelType.POS &&
        storefrontInformation?.isUsingCreditCardTerminals && (
          <Permissions allowed={['FlipdishStaff']}>
            <ListItemLinkButton
              tagText="Flipdish_staff_only"
              title="Sales_channel_customise_terminal_title"
              subtitle="Sales_channel_customise_terminal_description"
              link={`/${appId}/marketing/${propertyId}/stripe-location/${storefrontInformation.locationId}/branding`}
              iconComponent={<SmartphoneIcon />}
              isLast={false}
            />
          </Permissions>
        )}
      {salesChannelType === SalesChannelType.WEB_APP && (
        <ListItemLinkButton
          title="Web_App_Configuration"
          subtitle="Configure_web_app_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.WEB_APP_CONFIGURATION)}`}
          iconComponent={<WebOutlinedIcon />}
          isLast={true}
        />
      )}
      {salesChannelType === SalesChannelType.MOBILE_APP && (
        <ListItemLinkButton
          title="Mobile_App_Configuration"
          subtitle="Configure_mobile_app_configuration"
          link={`${baseUrl}/${toKebabCase(RMSModules.MOBILE_APP_CONFIGURATION)}`}
          iconComponent={<PhoneIphoneOutlinedIcon />}
          isLast={true}
        />
      )}
      {salesChannelType === SalesChannelType.POS && (
        <ListItemLinkButton
          title="Danger_zone_title"
          subtitle="Danger_zone_description_2"
          dataFd="btn-danger-zone"
          link={`${baseUrl}/dangerzone`}
          iconComponent={<Warning />}
          classesFromParent={{
            logo: classes.listItemDanger,
            primary: classes.listItemDanger,
            secondary: classes.listItemDanger,
          }}
          isLast={true}
        />
      )}
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory(['FlipdishStaff']);

  return {
    appId: currentApp.AppId ?? '',
    isFlipdishStaff: getPermissionsSelector(state),
    showPropertyOpeningHours: flagService.isFlagOn(state, 'showPropertyOpeningHours'),
  };
};

export default connect(mapStateToProps)(POSChannelSettingsList);
