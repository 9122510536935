import React from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down(813)]: { borderTop: '1px solid #dbdcdf' },
    [theme.breakpoints.up('md')]: { maxWidth: '916px' },
    [theme.breakpoints.up('lg')]: { maxWidth: '780px' },
  },
  gridContainer: {
    [theme.breakpoints.down(640)]: { padding: '16px' },
    [theme.breakpoints.up('sm')]: { padding: '24px 24px 0 24px' },
  },
  cardHeader: {
    marginBottom: '16px',
  },
  list: {
    listStyleType: 'disc',
    paddingLeft: '16px',
    padding: 0,
    marginBottom: '16px',
  },
  listHeader: {
    fontWeight: 500,
    marginBottom: '4px',
  },
  listItem: {
    display: 'list-item',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
    lineHeight: '20px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '0.25px',
  },
  actionsGrid: {
    textAlign: 'end',
    [theme.breakpoints.down(640)]: {
      marginBottom: '8px',
    },
  },
}));

type Props = {
  cardHeader: TranslationId;
  doThisIf: TranslationId[];
  itWillMean: TranslationId[];
  divider: boolean;
  button: React.ReactElement;
};

const DangerActionCard = ({ cardHeader, doThisIf, itWillMean, button, divider }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Card
      className={classes.container}
      square
      elevation={isMobile ? 0 : 2}
      style={divider ? { borderBottom: '1px solid lightgray' } : {}}
    >
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={7} md={7} lg={8} style={{ marginBottom: '24px' }}>
          <Typography variant="h5" className={classes.cardHeader}>
            <Translate id={cardHeader} />
          </Typography>
          <Typography variant="body2" className={classes.listHeader}>
            <Translate id="Do_this_if" />
          </Typography>
          <List classes={{ root: classes.list }} dense>
            {doThisIf.map((text) => {
              return (
                <ListItem classes={{ root: classes.listItem }} disableGutters key={text}>
                  <ListItemText
                    primary={<Translate id={text} />}
                    classes={{
                      root: classes.listItemText,
                      primary: classes.listItemText,
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
          <Typography variant="body2" className={classes.listHeader}>
            <Translate id="It_will_mean" />
          </Typography>
          <List classes={{ root: classes.list }} dense>
            {itWillMean.map((text) => {
              return (
                <ListItem classes={{ root: classes.listItem }} disableGutters key={text}>
                  <ListItemText
                    primary={<Translate id={text} />}
                    classes={{
                      root: classes.listItemText,
                      primary: classes.listItemText,
                    }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} classes={{ root: classes.actionsGrid }}>
          {button}
        </Grid>
      </Grid>
    </Card>
  );
};

export default DangerActionCard;
