import React, { useEffect, useMemo, useState } from 'react';

import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import { Redirect, Route, Switch } from 'react-router';

import { appsActions } from '../../actions/apps.actions';
import { generalConstants } from '../../constants/general.constants';
import { portalRoutes } from '../../routes/portal.routes';
import ScrollToTop from './ScrollToTop';
import SideNav from './SideNav';
import TopNav from './TopNav';

const { SCROLLABLE_CONTAINER } = generalConstants;

const tutorialDrawerWidth = 408;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  switch: {
    zIndex: 1,
    flexGrow: 1,
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    flexDirection: 'column',
    '-webkit-overflow-scrolling': 'touch',
  },
  tutorialDrawerOpen: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      marginRight: tutorialDrawerWidth,
    },
  },
  tutorialDrawerClose: {
    marginRight: 0,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const createRoute = (
  props: PortalRoutePropsExtended | PortalRedirectPropsExtended,
  appPermissions: AppState['permissions']
) => {
  if ('to' in props) {
    // only Redirect has `to` prop required
    const { name, key = '', ...rest } = props;
    return <Redirect {...rest} key={`${name}.${key}`} />;
  }

  if ('permissions' in props) {
    const { permissions, name, key = '', ...rest } = props;
    if (checkVisibility(appPermissions, permissions, [])) {
      return <Route {...rest} key={`${name}.${key}`} />;
    }
    return null;
  }

  const { name, key = '', ...rest } = props;
  return <Route {...rest} key={`${name}.${key}`} />;
};

type Props = MappedDispatch & MappedState;

const Portal = (props: Props) => {
  const { currentApp, getApps, isHelpDrawerOpen, permissions } = props;

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [open, setOpen] = useState(false);
  const [openSubDrawer, setOpenSubDrawer] = useState(false);
  const [tutorialIsOpen, setTutorialIsOpen] = useState<boolean | undefined>(undefined);
  const [tutorialHasNotification] = useState<boolean | undefined>(undefined);
  // HIDING Tutorial until work is done to update its api calls etc CLX-1508
  // const tutorialStore = useTutorialStore(currentApp.AppId).data;
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    getApps();
  }, []);

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  const toggleSideNav = () => {
    if (isMobile) {
      setOpen((prevOpen) => !prevOpen);
    } else if (!openSubDrawer) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const drawerOpen = tutorialIsOpen === true || isHelpDrawerOpen === true;
  const drawerClosed = tutorialIsOpen === false && isHelpDrawerOpen === false;

  const routes = useMemo(() => {
    if (!currentApp.AppId) {
      return null;
    }
    return portalRoutes(currentApp.AppId).map((r) => createRoute(r, permissions));
  }, [currentApp.AppId, permissions]);

  return (
    <div className={classes.root}>
      <TopNav
        open={open}
        toggleSideNav={toggleSideNav}
        tutorialIsOpen={tutorialIsOpen}
        setTutorialIsOpen={setTutorialIsOpen}
        tutorialHasNotification={tutorialHasNotification}
      />
      <div className={classes.wrapper}>
        <SideNav
          open={open}
          toggleSideNav={toggleSideNav}
          openSubDrawer={openSubDrawer}
          setOpenSubDrawer={setOpenSubDrawer}
        />
        <main className={classes.content}>
          <div
            className={clsx({
              [classes.switch]: true,
              [classes.tutorialDrawerOpen]: drawerOpen,
              [classes.tutorialDrawerClose]: drawerClosed,
            })}
            id={SCROLLABLE_CONTAINER}
          >
            <ScrollToTop onMount>
              <Switch>{routes}</Switch>
            </ScrollToTop>
          </div>

          {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
          {/* {tutorialStore && (
            <TutorialDrawer
              isOpen={tutorialIsOpen}
              setIsOpen={setTutorialIsOpen}
              drawerWidth={tutorialDrawerWidth}
              hasNotification={tutorialHasNotification}
              setHasNotification={setTutorialHasNotification}
              tutorialStore={tutorialStore}
            />
          )} */}
        </main>
      </div>
    </div>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  return {
    currentApp: state.currentApp,
    isHelpDrawerOpen: state.drawer.helpDrawerOpen,
    permissions: state.permissions,
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
function mapDispatchToProps(dispatch: ThunkDispatch) {
  return {
    getApps: () => dispatch(appsActions.getApps(undefined, false, undefined, 1, 20)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
