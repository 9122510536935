import React from 'react';

import type { DeliveryZone, StoreGroup } from '@flipdish/api-client-typescript';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import * as Circle from './components/Circle';
import { getDefaultPolygon } from './components/Polygon';
import DeliveryZoneList from './DeliveryZoneList';
import GMap from './Map';

export const TMP_EDIT_ID = -1;

const styles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: '2em',
    width: '100%',
    height: 'calc(100vh - 35vh)',
    margin: 0,
    position: 'relative',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  mapContainer: {
    overflow: 'hidden',
    borderRadius: '5px',
    flex: '1 1 60%',
  },
  listContainer: {
    flex: '0 0 auto',
    width: '380px',
    position: 'absolute',
    top: '102px',
    right: '32px',
    zIndex: 100,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      position: 'relative',
      flex: '1 1 0',
      order: 1,
    },
    [theme.breakpoints.down('md')]: {
      top: '0',
      right: '0',
    },
  },
  smallScreenWarning: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '12px',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '2.33',
    letterSpacing: '0.4px',
    textAlign: 'center',
    color: '#ff395f',
  },
  learnMoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 32,
  },
  learnMoreLink: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

type DeliveryZoneProps = {
  add: (storeId: string, zone: any, Id: number) => void;
  initLoad: boolean;
  isSmallScreen: boolean;
  remove: (storeId: any, storeDeliveryZoneId: any, undo: any) => void;
  select: (deliveryZoneId: number) => void;
  storeGroupCurrency: StoreGroup.CurrencyEnum | null | undefined;
  storeMapCenter: {
    lat: number;
    lng: number;
  };
  storeName: string | undefined;
  update: (storeId: any, zone: any, undo: any) => void;
  zones: DeliveryZone[];
  storeId?: number;
};

const DeliveryZones = (props: DeliveryZoneProps) => {
  const classes = styles();

  const {
    add,
    initLoad,
    isSmallScreen,
    remove,
    select,
    storeGroupCurrency,
    storeId,
    storeMapCenter,
    storeName,
    update,
    zones,
  } = props;

  const onCreate = async ({ DeliveryFee, MinimumDeliveryOrderAmount, shape, postcodePolygons }) => {
    try {
      const center = new google.maps.LatLng(storeMapCenter.lat, storeMapCenter.lng);
      let id = TMP_EDIT_ID;
      id--;

      const zone = {
        DeliveryFee,
        MinimumDeliveryOrderAmount,
        IsEnabled: true,
        WellKnownText: '',
      };

      if (shape === 'POSTCODES') {
        if (postcodePolygons.length > 0) {
          postcodePolygons.forEach((wellKnownText: string) => {
            zone.WellKnownText = wellKnownText;
            add(storeId?.toString() ?? '', zone, id);
            id--;
          });
        }
      } else {
        const radius = 3000;
        zone.WellKnownText =
          shape === 'POLYGON'
            ? getDefaultPolygon(center, radius)
            : Circle.serialize(new google.maps.Circle({ center, radius }));

        add(storeId?.toString() ?? '', zone, id);
      }
    } catch (error) {
      console.error('Error creating delivery zone:', error);
    }
  };

  const onChange = (changes) => {
    const undo = zones.find((z) => z.Id === changes.Id);
    update(storeId, changes, undo);
  };

  const onSelect = (storeDeliveryZoneId) => {
    select(storeDeliveryZoneId);
  };

  const onRemove = (storeDeliveryZoneId) => {
    const undo = zones.find((z) => z.Id === storeDeliveryZoneId);
    remove(storeId, storeDeliveryZoneId, undo);
  };

  if (!initLoad) {
    return <CircularProgress data-fd="loading-in-progress" style={{ margin: '10px' }} size={50} />;
  }

  return (
    <Grid container spacing={0} className={classes.container}>
      <Grid item className={classes.listContainer}>
        <Grid container spacing={0} direction="column">
          <Grid item xs={12}>
            <DeliveryZoneList
              storeId={storeId ?? 0}
              subtitle={storeName as string}
              onCreate={onCreate}
              onChange={onChange}
              onSelect={onSelect}
              onRemove={onRemove}
              currency={storeGroupCurrency as StoreGroup.CurrencyEnum}
              isSmallScreen={isSmallScreen}
            />
          </Grid>
        </Grid>
        {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
        {/* {storeId && (
          <TutorialNotifier
            storeId={storeId}
            onboardingItemId={201}
            status={'Completed' as OnboardingItemUpdate}
          />
        )} */}
      </Grid>
      <Grid item className={classes.mapContainer}>
        <GMap
          mapCenter={storeMapCenter}
          storeId={storeId}
          onChange={onChange}
          onSelect={onSelect}
          isSmallScreen={isSmallScreen}
        />
      </Grid>
    </Grid>
  );
};

export default DeliveryZones;
