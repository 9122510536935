import React, { useEffect } from 'react';

import { Subscription } from '@flipdish/api-client-typescript';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { getActiveLanguage, getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { DescriptiveCard } from '@fd/ui/molecules/DescriptiveCard';

import { notifyError, NotifyProps } from '../../../layouts/Notify/actions';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';
import PageLayout from '../../../ui/Layout';
import { billingService } from '../billing.service';
import { SubscriptionCancelledBanner } from '../components/SubscriptionCancelledBanner';
import { getStatusTag } from './components/GetStatusTag';
import ProductTable from './components/ProductTable';
import { subscriptionPrice } from './components/SubscriptionPrice';
import UpcomingInvoiceTable from './components/UpcomingInvoiceTable';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  gridItem: {
    display: 'flex',
  },
  sectionContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    justifyContent: 'center',
  },
  detailsGrouping: {
    marginBottom: theme.spacing(1),
  },
  taxText: {
    alignSelf: 'center',
    marginTop: theme.spacing(0.4),
  },
  statusTag: {
    marginTop: theme.spacing(1),
  },
  dateMargin: {
    marginBottom: theme.spacing(1.5),
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  bold: {
    fontWeight: 500,
  },
  tableContainer: {
    overflow: 'scroll',
  },
  invoiceText: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      padding: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(2),
    },
  },
  productTitle: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
    },
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  cancelledSubscriptionDetails: {
    marginBottom: theme.spacing(2),
  },
}));

const SubscriptionDetails = (props: MappedState & MappedDispatch) => {
  const { appId, dtUtils, languageCode, translate, dispatchNotifyError } = props;
  const classes = useStyles();
  const history = useHistory();
  const subscriptionId = history.location.pathname.split('/')?.pop();

  const {
    data: subscription,
    isPending,
    isFetching,
    isError,
  } = useQuery({
    queryKey: [billingService.getSubscriptionByIdQueryKey, appId],
    queryFn: () => billingService.getSubscriptionById(appId!, subscriptionId!),
  });

  useEffect(() => {
    if (isError) {
      dispatchNotifyError({ message: 'Something_went_wrong' });
    }
  }, [isError]);

  const fetchingSubscription = isPending || isFetching;
  const invoicesForSubscriptionUrl = `/${appId}/finance/invoices?subscriptionFilter=${subscriptionId}`;

  const isCancelledSubscription = subscription?.Status === Subscription.StatusEnum.Canceled;

  const invoiceDate =
    subscription?.NextInvoiceBillingDate &&
    dtUtils.format(new Date(subscription?.NextInvoiceBillingDate), dtUtils.LOCAL_DATE_FORMAT);

  const showSubscriptionPriceField =
    subscription?.NextInvoiceAmount || subscription?.NextInvoiceAmount === 0;
  return (
    <PageLayout
      toParent={`/${appId}/subscriptions`}
      title={<Translate id="Subscription_details" />}
      documentTitle="Subscription_details"
      showActionButton
      actionBtnTitle={'Contact_support'}
      hrefBtnLink={'https://support.flipdish.com/hc/en-us/requests/new?ticket_form_id=360003936037'}
      userPermissions={'ViewSubscriptions'}
      openLinkInNewTab
    >
      {isCancelledSubscription && (
        <SubscriptionCancelledBanner toUrl={invoicesForSubscriptionUrl} />
      )}

      {!isCancelledSubscription && (
        <Grid className={classes.sectionContainer} container direction="row" spacing={3}>
          <Grid className={classes.gridItem} item xs={11} sm={4}>
            <DescriptiveCard isLoading={fetchingSubscription} showBlueCard>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="caption">{translate('Payment_due')}</Typography>
                </Grid>
                <Grid container xs={12} direction="row" spacing={1}>
                  <Grid item className={classes.breakWord}>
                    {showSubscriptionPriceField
                      ? subscriptionPrice(
                          subscription?.NextInvoiceAmount as unknown as number,
                          subscription?.Currency,
                          languageCode,
                          'h4'
                        )
                      : '-'}
                  </Grid>
                  <Grid item className={classes.taxText}>
                    <Typography variant="caption">{translate('inc_tax')}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.statusTag}>
                  {getStatusTag(subscription?.Status, true)}
                </Grid>
              </Grid>
            </DescriptiveCard>
          </Grid>
          <Grid className={classes.gridItem} item xs={11} sm={4}>
            <DescriptiveCard isLoading={fetchingSubscription}>
              <Grid item xs={12}>
                <Typography variant="caption">{translate('Next_payment_on')}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.dateMargin}>
                <Typography variant="body2">{invoiceDate ?? '-'}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Button
                  color="primary"
                  data-fd="View_invoices_button"
                  onClick={() => {
                    window.open(invoicesForSubscriptionUrl, '_blank', 'noopener, noreferrer');
                  }}
                  variant="outlined"
                >
                  {translate('View_invoices')}
                </Button>
              </Grid>
            </DescriptiveCard>
          </Grid>
          <Grid className={classes.gridItem} item xs={11} sm={4}>
            <DescriptiveCard isLoading={fetchingSubscription}>
              <Grid item xs={12}>
                <Typography variant="caption">{translate('Payment_method')}</Typography>
              </Grid>
              <Grid item xs={12} className={classes.detailsGrouping}>
                <Typography className={classes.breakWord} variant="body2">
                  {subscription?.DefaultPaymentDescription ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">{translate('User')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.breakWord} variant="body2">
                  {subscription?.User ?? '-'}
                </Typography>
              </Grid>
            </DescriptiveCard>
          </Grid>
        </Grid>
      )}

      {isCancelledSubscription && (
        <div className={classes.cancelledSubscriptionDetails}>
          <DescriptiveCard>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="caption">{translate('Payment_method')}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">{translate('User')}</Typography>
              </Grid>
              <Grid item xs={6} className={classes.detailsGrouping}>
                <Typography className={classes.breakWord} variant="body2">
                  {subscription?.DefaultPaymentDescription ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.breakWord} variant="body2">
                  {subscription?.User ?? '-'}
                </Typography>
              </Grid>
            </Grid>
          </DescriptiveCard>
        </div>
      )}
      <Typography variant="body1" className={clsx(classes.bold, classes.productTitle)}>
        {translate('Your_subscription_products')}
      </Typography>
      <ProductTable subscription={subscription} isLoading={fetchingSubscription} />
      {!isCancelledSubscription && (
        <>
          <Grid container className={classes.invoiceText}>
            <Grid item xs={12}>
              <Typography className={classes.bold} variant="h3">
                {translate('Upcoming_invoice_breakdown')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {translate('Invoice_breakdown_description', {
                  billingDate: invoiceDate ?? '-',
                })}
              </Typography>
            </Grid>
          </Grid>
          <UpcomingInvoiceTable subscription={subscription} isLoading={fetchingSubscription} />
        </>
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    appId: currentApp.AppId,
    dtUtils: dateTimeUtils(state),
    languageCode: getActiveLanguage(state.locale),
    translate: getTranslate(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetails);
