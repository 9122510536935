import React, { ChangeEvent, KeyboardEvent, MouseEvent, useEffect, useMemo, useState } from 'react';

import type { Org } from '@flipdish/orgmanagement';
import SearchIcon from '@mui/icons-material/Search';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';

import { ImgixImage } from '@fd/ui/Imgix/ImgixImage';

import { appsActions } from '../../../actions/apps.actions';
import { ReactComponent as ForkIcon } from '../../../assets/images/app_logo_fork.svg';
import { THIRTY_MINUTES } from '../../../helpers/timeConstants';
import { notifyError } from '../../../layouts/Notify/actions';
import generateSrcSet from '../../../ui/utils/generateSrcSet';
import OrgAndBrandSelector from '../OrgAndBrandSelector/OrgAndBrandSelector';
import { getBrandsForOrg, getBrandsForOrgKey, getOrgByBrandId } from '../organisation.services';
import { setCurrentOrg } from '../rms.actions';
import { renderBrandItem } from './renderBrandItem';

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  whiteSpace: 'nowrap',
  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledMenuContainer = styled('div')({
  ':focus': {
    outline: 'none',
  },
  maxWidth: 400,
  minWidth: 304,
});

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isCurrent' && prop !== 'isSelected',
})<{ isCurrent?: boolean; isSelected?: boolean }>(({ isCurrent, isSelected }) => ({
  backgroundColor: isCurrent ? '#eaf2ff' : 'transparent',
  '&:hover': {
    backgroundColor: '#eaf2ff',
  },
  ...(isSelected && {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  }),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledLoadingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  marginBottom: theme.spacing(1),
}));

const StyledImgContainer = styled('div')({
  width: 40,
  height: 40,
  overflow: 'hidden',
  alignContent: 'center',
  display: 'flex',
  alignItems: 'center',
});

const StyledMessageContainer = styled('div')({
  padding: 16,
});

const SearchContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: '#fff',
}));

const StyledIconButton = styled(IconButton)({
  overflow: 'hidden',
});

const BrandSelector: React.FC<MapDispatchToPropsType & MapStateToPropsType> = ({
  currentApp,
  dispatchSetCurrentApp,
  dispatchSetCurrentOrg,
  dispatchNotifyError,
  currentOrg,
  translate,
}: MapStateToPropsType & MapDispatchToPropsType) => {
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const history = useHistory();
  const params = useParams<{ appId: string }>();
  const location = useLocation();
  const menuOpen = Boolean(anchorEl);

  const { data: orgData, isPending: loadingOrg } = useQuery({
    queryKey: [getOrgByBrandId, params?.appId],
    queryFn: () => getOrgByBrandId(params?.appId || ''),
    retry: 1,
  });

  const orgId = orgData?.data?.data?.[0]?.orgId;

  const {
    data: brandsResponse,
    isLoading: loadingBrands,
    error: brandsForOrgError,
  } = useQuery({
    queryKey: [getBrandsForOrgKey, currentOrg?.orgId],
    queryFn: () => getBrandsForOrg(currentOrg?.orgId),
    enabled: !!currentOrg?.orgId && menuOpen,
    // only reload the brands list when menu is open and org has changed
    staleTime: THIRTY_MINUTES,
    retry: 1,
  });

  useEffect(() => {
    const brandsForOrgErrorMessage = brandsForOrgError?.message;
    if (brandsForOrgErrorMessage) {
      dispatchNotifyError(brandsForOrgErrorMessage);
    }
  }, [brandsForOrgError]);

  useEffect(() => {
    if (location?.search.includes('orgSearch')) {
      const params = new URLSearchParams(location.search);
      const orgSearch = params.get('orgSearch');
      orgSearch && setDrawerOpen(true);
    }
  }, []);

  useEffect(() => {
    // when we update the url directly, we need to update the current app
    if (params?.appId && params?.appId !== currentApp?.AppId) {
      // on first render after login => url is http://portal.flipdish.com/auth0login
      if (params?.appId === 'auth0login') {
        return;
      }
      dispatchSetCurrentApp(params?.appId);
    }
  }, [currentApp?.AppId, params?.appId]);

  useEffect(() => {
    const org = orgData?.data?.data?.[0];
    if (org && org.orgId !== currentOrg?.orgId) {
      dispatchSetCurrentOrg(org);
    }
  }, [orgData, currentOrg]);

  const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedIndex(-1);
  };

  const debouncedSetSearchTerm = useMemo(
    () => debounce((value) => setSearchTerm(value.toLowerCase()), 300),
    []
  );

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(event.target.value);
    setSelectedIndex(-1);
  };

  const brands = brandsResponse?.data?.data || [];

  const filteredBrands = useMemo(
    () =>
      brands.filter(
        (brand) =>
          brand?.name?.toLowerCase()?.includes(searchTerm) ||
          brand?.brandId?.toLowerCase()?.includes(searchTerm)
      ),
    [brands, searchTerm]
  );

  const handleChange = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
    brand: any,
    newTab = false
  ) => {
    const brandId = brand?.brandId;
    if (brandId === 'switch-org') {
      setDrawerOpen(true);
    } else if (brandId && brandId !== 'account-details' && brandId !== 'sign-out') {
      if (newTab || (event as MouseEvent).metaKey || (event as MouseEvent).ctrlKey) {
        window.open(`/${brandId}/home`, '_blank');
      } else {
        history.push(`/${brandId}/home`);
      }
    }
    handleMenuClose();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLUListElement>) => {
    const hasFilteredBrands = filteredBrands?.length;

    const totalItems = filteredBrands.length + 1;
    const isSwitchOrg = selectedIndex === filteredBrands.length || !filteredBrands.length;
    const isCommandEnter = event.metaKey || event.ctrlKey;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setSelectedIndex((prevIndex) => {
          if (prevIndex === -1) {
            return 0;
          }
          return prevIndex < totalItems - 1 ? prevIndex + 1 : prevIndex;
        });
        break;
      case 'ArrowUp':
        event.preventDefault();
        hasFilteredBrands && setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
        break;
      case 'Enter':
        event.preventDefault();
        if (isSwitchOrg) {
          handleChange(event, { brandId: 'switch-org' });
        } else {
          const selectedBrand = filteredBrands[selectedIndex];
          if (selectedBrand) {
            handleChange(event, selectedBrand, isCommandEnter);
          }
        }
        break;
      default:
        break;
    }
  };

  const toggleOrgDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && (event as React.KeyboardEvent).key === 'Tab') {
      return;
    }
    setDrawerOpen(open);
    handleMenuClose();
  };

  const filteredBrandsLength = filteredBrands?.length;
  // Calculate the height of the List dynamically
  const itemSize = 60;
  const maxVisibleItems = isMobile ? 6 : 10;
  const listHeight =
    filteredBrandsLength < maxVisibleItems
      ? filteredBrandsLength * itemSize
      : maxVisibleItems * itemSize;

  const renderBrandsOrMessage = () => {
    if (!filteredBrandsLength) {
      return (
        <StyledMessageContainer>
          <Typography variant="body1">
            {translate('No_brands_found', { orgId: currentOrg?.orgId || orgId })}
          </Typography>
        </StyledMessageContainer>
      );
    }
    return (
      <List height={listHeight} itemCount={filteredBrands?.length} itemSize={itemSize} width="100%">
        {renderBrandItem({
          currentApp,
          selectedIndex,
          filteredBrands,
          handleChange,
        })}
      </List>
    );
  };

  const isImgixUrl = currentApp.LogoImageUrl?.includes('imgix.net');
  const hasLogoImage = Boolean(currentApp?.LogoImageUrl);

  return (
    <>
      <StyledIconButton
        onClick={handleButtonClick}
        color="inherit"
        aria-label="Open App menu"
        data-fd="brand-selector"
      >
        <StyledImgContainer>
          {hasLogoImage ? (
            isImgixUrl ? (
              <ImgixImage
                src={currentApp.LogoImageUrl}
                sizes="40px"
                imgixParams={{
                  fit: 'inside',
                  auto: 'format,compress',
                  fm: 'png',
                  bg: 'transparent',
                }}
                alt={currentApp.Name}
              />
            ) : (
              <img
                srcSet={generateSrcSet(currentApp.LogoImageUrl, {
                  width: 40,
                  png: true,
                })}
                src={currentApp.LogoImageUrl}
                alt={currentApp.Name}
              />
            )
          ) : (
            <ForkIcon />
          )}
        </StyledImgContainer>
      </StyledIconButton>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          autoFocus: true,
          'aria-labelledby': 'fade-button',
          onKeyDown: handleKeyDown,
        }}
      >
        <StyledMenuContainer>
          {brands?.length > maxVisibleItems && (
            <>
              <SearchContainer>
                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  placeholder={translate('Search_brands') as string}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                  inputProps={{
                    'data-fd': 'brand-selector-search-input',
                  }}
                />
              </SearchContainer>
              <Divider />
            </>
          )}
          {loadingBrands || loadingOrg ? (
            <StyledLoadingContainer>
              <CircularProgress />
            </StyledLoadingContainer>
          ) : (
            renderBrandsOrMessage()
          )}
          <StyledDivider />
          <StyledMenuItem
            key="switch-org"
            onClick={toggleOrgDrawer(true)}
            data-fd="brand-selector-switch-org"
            isSelected={
              selectedIndex === filteredBrands?.length ||
              (!filteredBrands?.length && selectedIndex === 0)
            }
          >
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
            <StyledListItemText primary={translate('Switch_organisation')} />
          </StyledMenuItem>
        </StyledMenuContainer>
      </Menu>

      <ClickAwayListener
        onClickAway={() => drawerOpen && setDrawerOpen(false)}
        touchEvent="onTouchStart"
        mouseEvent="onMouseDown"
      >
        <OrgAndBrandSelector toggleOrgDrawer={() => setDrawerOpen(false)} open={drawerOpen} />
      </ClickAwayListener>
    </>
  );
};

type MapDispatchToPropsType = ReturnType<typeof MapDispatchToProps>;
const MapDispatchToProps = (dispatch: any) => ({
  dispatchSetCurrentApp: (appId: string | undefined) => dispatch(appsActions.setCurrentApp(appId)),
  dispatchSetCurrentOrg: (org: Org) => dispatch(setCurrentOrg(org)),
  dispatchNotifyError: (message) => dispatch(notifyError({ message, translate: false })),
});

type MapStateToPropsType = ReturnType<typeof MapStateToProps>;
const MapStateToProps = (state) => ({
  currentApp: state.currentApp,
  currentOrg: state.rms.currentOrg,
  translate: getTranslate(state.locale),
});

export default connect(MapStateToProps, MapDispatchToProps)(BrandSelector);
