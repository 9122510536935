import moment from 'moment';

import { BusinessHoursPeriodBase } from '../types.d';

export const generatePayload = (
  dayName: string,
  value: string,
  {
    time,
    timeLocal,
    timePeriod,
    isClosedTester,
    isAllDayTester,
  }: {
    time: any;
    timeLocal: any;
    timePeriod: any;
    isClosedTester: RegExp;
    isAllDayTester: RegExp;
  }
): BusinessHoursPeriodBase => {
  const payload: BusinessHoursPeriodBase = {
    DayOfWeek: dayName as unknown as BusinessHoursPeriodBase['DayOfWeek'],
    StartTime: '00:00:00',
    Period: '00:00:00',
    StartTimeEarly: '00:00:00',
    PeriodEarly: '00:00:00',
  };

  if (value !== '' && !isClosedTester.test(value)) {
    if (isAllDayTester.test(value)) {
      payload.Period = '23:59:59';
    } else {
      const earlyLateParts: string[] = value.split(',');
      const parsed = earlyLateParts.map((rawRange: string) => {
        const leftRightTime = rawRange.split('-');
        const lTime = timeLocal(leftRightTime[0].trim()) as moment.Moment;
        const rTime = timeLocal(leftRightTime[1].trim()) as moment.Moment;
        return {
          start: time(lTime) as string,
          end: time(rTime) as string,
          period: timePeriod(lTime, rTime),
        };
      });

      if (parsed.length === 2) {
        payload.StartTimeEarly = parsed[0].start;
        payload.PeriodEarly = parsed[0].period;
        payload.StartTime = parsed[1].start;
        payload.Period = parsed[1].period;
      } else {
        payload.StartTimeEarly = parsed[0].start;
        payload.StartTime = parsed[0].start;
        payload.Period = parsed[0].period;
      }
    }
  }

  return payload;
};
