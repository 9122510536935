import React, { useEffect, useState } from 'react';

import { App, Group } from '@flipdish/api-client-typescript';
import Add from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { compose } from 'recompose';

import { TextSearchField } from '@fd/ui/atoms/TextSearchField/TextSearchField';
import { Typography } from '@fd/ui/atoms/Typography';
import LinkButton from '@fd/ui/Button/LinkButton';

import { notifyError, NotifyProps } from '../../../../../layouts/Notify/actions';
import PageTitleWithBeta from '../../../../../layouts/Portal/PageTitleWithBeta';
import { isProductBasedMenusEnabled } from '../../../../../selectors/app.selector';
import PageLayout from '../../../../../ui/Layout';
import WithRouteSearchParams, {
  WithRouteSearchParamsProps,
} from '../../../../WithRouteSearchParams';
import { CatalogList } from '../../../components/CatalogList';
import { NoMatchesFoundMessage } from '../../../components/NoMatchesFoundMessage';
import { ProductListLoadingSkeleton } from '../../../components/ProductListLoadingSkeleton';
import { ToggleViewMode } from '../../../components/ToggleViewMode';
import { VIEW_MODE } from '../../../constants';
import { catalogGroupsService } from '../../../services/catalogGroup.service';
import { NoModifierGroupsMessage } from '../../components/NoModifierGroupMessage';

const DEFAULT_LIMIT = 30;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  createButtonContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  searchHolder: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(3),
  },
  searchField: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
}));

type Props = MappedState & MappedDispatch & WithRouteSearchParamsProps<string>;

const ModifierGroups = (props: Props) => {
  const { AppId, isProductBasedMenusOn, notifyError, search, setSearch, translate } = props;
  const classes = useStyles();
  const [page, setPage] = useState<number>(1);
  const defaultSearchTerm = search || '';
  const [searchTerm, setSearchTerm] = useState<string>(defaultSearchTerm);
  const [viewMode, setViewMode] = useState(VIEW_MODE.LIST);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data, isPending, isError } = useQuery({
    queryKey: [
      catalogGroupsService.getModifierGroupsQueryKey,
      AppId,
      page,
      DEFAULT_LIMIT,
      searchTerm,
    ],

    queryFn: () =>
      catalogGroupsService.getCatalogGroups(AppId, page, DEFAULT_LIMIT, searchTerm, [
        'ModifierGroup',
      ]),
  });
  useEffect(() => {
    if (isError) {
      notifyError({ message: 'Error_please_try_again_later', translate: true });
    }
  }, [isError]);
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleViewModeChange = (mode: VIEW_MODE) => {
    setViewMode(mode);
  };

  const renderContent = () => {
    if (isPending) {
      return <ProductListLoadingSkeleton />;
    }
    if (data && data.Data) {
      if (searchTerm !== '' && data.Data.length === 0) {
        return <NoMatchesFoundMessage message="ModifierGroups_NoModifierGroupsFoundMessage" />;
      }

      if (data.Data.length === 0) {
        return <NoModifierGroupsMessage />;
      }

      return (
        <CatalogList
          appId={AppId}
          limit={data.Limit}
          onPageChange={handlePageChange}
          page={page}
          content={data.Data}
          total={data.TotalRecordCount}
          type={Group.GroupTypeEnum.ModifierGroup}
          viewMode={viewMode}
        />
      );
    }
    return null;
  };

  const handleSearchTerm = (value: string) => {
    setPage(1);
    setSearch(value);
    setSearchTerm(value);
  };

  return isProductBasedMenusOn ? (
    <PageLayout
      documentTitle={'Modifier_Groups'}
      title={<PageTitleWithBeta translateId="Modifier_Groups" />}
    >
      <div className={classes.container}>
        <Typography variant="caption">
          <Translate id="ModifierGroups_PageDescription" />
        </Typography>
        <div className={classes.createButtonContainer}>
          <Permissions allowed={[App.AppResourceSetEnum.CreateCatalogElements]}>
            <LinkButton
              fdKey="create-modifier-group-button"
              variant="contained"
              color="primary"
              to={`modifier-groups/create-modifier-group`}
            >
              <Add />
              <Translate id="ModifierGroup_NewModifierGroup" />
            </LinkButton>
          </Permissions>
        </div>
        <Divider className={classes.divider} />
        <Permissions allowed={[App.AppResourceSetEnum.ViewCatalogElements]}>
          <Grid container lg={12} className={classes.searchHolder}>
            <TextSearchField
              className={classes.searchField}
              clearAriaLabel={`${translate('Products_SearchBox_Clear')}`}
              dataFd="modifier-group-search"
              defaultValue={defaultSearchTerm}
              disableLabelAnimation={true}
              label={`${translate('Search')}`}
              onChange={handleSearchTerm}
              placeholder={`${translate('Name')}`}
              variant="outlined"
            />
            {!isMobile && (
              <ToggleViewMode viewMode={viewMode} onViewModeChange={handleViewModeChange} />
            )}
          </Grid>
          {renderContent()}
        </Permissions>
      </div>
    </PageLayout>
  ) : null;
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, locale } = state;
  return {
    AppId: currentApp.AppId as string,
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    translate: getTranslate(locale),
  };
};

const EnhancedComponent = compose<Props, Props>(
  WithRouteSearchParams({
    name: 'searchTerm',
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(ModifierGroups);

export { EnhancedComponent as ModifierGroups };
