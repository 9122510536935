import get from 'lodash/get';

import * as actions from '../actions/deliveryzones.actions';
import { appsConstants } from '../constants/apps.constants';
import { deliveryZonesConstants } from '../constants/deliveryzones.constants';

const {
  DELIVERY_ZONE_LOAD_ALL_SUCCESS,
  DELIVERY_ZONE_LOAD_ALL_FAILURE,
  DELIVERY_ZONE_STATE_RESET,
  DELIVERY_ZONE_CREATE_REQUEST,
  DELIVERY_ZONE_CREATE_FAILURE,
  DELIVERY_ZONE_UPDATE_FAILURE,
  DELIVERY_ZONE_REMOVE_SUCCESS,
  DELIVERY_ZONE_REMOVE_FAILURE,
  DELIVERY_ZONE_SELECTION_CHANGE,
  DELIVERY_ZONE_ERROR_RESET,
  DELIVERY_ZONE_LOCAL_EDIT_FLAG,
} = deliveryZonesConstants;

export type DeliveryZoneState = Readonly<{
  selectedId: number;
  initLoad: boolean;
  error: string | Error | null;
  isLocalEdit?: boolean;
}>;

const initialState: DeliveryZoneState = {
  selectedId: 0,
  initLoad: false,
  error: null,
};

export function deliveryZones(state = initialState, action) {
  switch (action.type) {
    case appsConstants.SELECTED_APP_CHANGED:
      return {
        ...state,
        initLoad: false,
        error: null,
      };
    case DELIVERY_ZONE_LOCAL_EDIT_FLAG:
      return {
        ...state,
        isLocalEdit: action.payload,
      };
    case DELIVERY_ZONE_LOAD_ALL_SUCCESS:
      return {
        ...state,
        initLoad: true,
        error: null,
      };
    case DELIVERY_ZONE_LOAD_ALL_FAILURE:
      return {
        ...state,
        initLoad: true,
        error: `Error loading zones! Reason: ${extractError(action)}`,
      };
    case DELIVERY_ZONE_CREATE_REQUEST:
      return {
        ...state,
        error: null,
      };
    case DELIVERY_ZONE_CREATE_FAILURE:
      return {
        ...state,
        error: `${extractError(action as ReturnType<typeof actions.createFailure>)}`,
      };
    case DELIVERY_ZONE_UPDATE_FAILURE:
      return {
        ...state,
        error: `${extractError(action as ReturnType<typeof actions.updateFailure>)}`,
      };
    case DELIVERY_ZONE_REMOVE_SUCCESS:
      return {
        ...state,
        error: null,
      };
    case DELIVERY_ZONE_REMOVE_FAILURE:
      return {
        ...state,
        error: `${extractError(action as ReturnType<typeof actions.removeFailure>)}`,
      };
    case DELIVERY_ZONE_STATE_RESET:
      return {
        ...state,
        ...initialState,
      };
    case DELIVERY_ZONE_ERROR_RESET:
      return {
        ...state,
        error: null,
      };
    case DELIVERY_ZONE_SELECTION_CHANGE:
      return {
        ...state,
        selectedId: (action as ReturnType<typeof actions.select>).payload,
      };
    default:
      return state;
  }
}

function extractError(action) {
  let error = `Server error`;
  const errMsg = get(action, ['payload', 'response', 'body', 'Message']);
  if (errMsg) {
    error = errMsg;
  } else if (get(action, ['payload', 'response', 'statusCode']) === 404) {
    error = `Not found`;
  }
  return error;
}
