import React from 'react';

import { type FieldProps, Field } from 'formik';
import { Translate, TranslateFunction } from 'react-localize-redux';

import Select, { OptionType } from '@fd/ui/Select/Select';

import FormItemLayout from '../FormItemLayout';

export type Props = {
  translate: TranslateFunction;
};

const CanBeUsedField = (props: Props) => {
  const { translate } = props;

  return (
    <Field name={'CanBeUsed'}>
      {({ field, form }: FieldProps) => {
        const getOptions = (): OptionType[] => [
          {
            label: translate('Only_once') as string,
            value: 'onlyOnce',
          },
          {
            label: translate('Once_per_customer') as string,
            value: 'oncePerCustomer',
          },
          {
            label: translate('Once_per_customer_on_their_first_order_only') as string,
            value: 'oncePerCustomerOnTheirFirstOrderOnly',
          },
          {
            label: translate('Multiple_times') as string,
            value: 'multipleTimes',
          },
        ];

        const getValue = () =>
          getOptions().find((option: OptionType) => option.value === field.value);

        const onChange = (option: OptionType) => {
          switch (option.value) {
            case 'onlyOnce':
              form.setFieldValue('IsValidOnlyOnce', false, false); // Legacy boolean
              form.setFieldValue('IsValidOncePerCustomer', true, false); // Set IsValidOncePerCustomer to true and MaxRedemptions to 1
              form.setFieldValue('IsValidForFirstOrderOnly', false, false);
              break;
            case 'oncePerCustomer':
              form.setFieldValue('IsValidOnlyOnce', false, false);
              form.setFieldValue('IsValidOncePerCustomer', true, false);
              form.setFieldValue('IsValidForFirstOrderOnly', false, false);
              break;
            case 'oncePerCustomerOnTheirFirstOrderOnly':
              form.setFieldValue('IsValidOnlyOnce', false, false);
              form.setFieldValue('IsValidOncePerCustomer', true, false);
              form.setFieldValue('IsValidForFirstOrderOnly', true, false);
              form.setFieldValue('IsDiscoverable', true, false); // When voucher is valid for first order only, it is very likely it will be suitable for IsDiscoverable
              break;
            case 'multipleTimes':
              form.setFieldValue('IsValidOnlyOnce', false, false);
              form.setFieldValue('IsValidOncePerCustomer', false, false);
              form.setFieldValue('IsValidForFirstOrderOnly', false, false);
              break;
          }

          form.setFieldValue('CanBeUsed', option.value);
        };

        return (
          <FormItemLayout label={<Translate id="Voucher_order" />}>
            <Select
              {...field}
              dataFd={'voucher-can-be-used'}
              isDisabled={form.isSubmitting}
              isFullWidth={false}
              minWidth={315}
              onChange={onChange}
              options={getOptions()}
              value={getValue()}
            />
          </FormItemLayout>
        );
      }}
    </Field>
  );
};

export default CanBeUsedField;
