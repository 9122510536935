import React, { createRef, RefObject, useEffect, useState } from 'react';

import { Brand, Org } from '@flipdish/orgmanagement';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import OrgAndBrandSelectorItem from './OrgAndBrandSelectorItem';
import { useKeyboardNavigation } from './useKeyboardNavigation';

const StyledMessageContainer = styled('div')({
  textAlign: 'center',
});

type KeyPressType = { key: string };

type OrgAndBrandSelectorItemsProps<T> = {
  items: T[];
  onItemSelect: (item: T, shouldOpenLinkInNewTab: boolean) => void;
  noResultsText: string;
  getItemId: (item: T) => string | undefined;
  getDisplayProps: (item: T) => {
    primary: string;
    secondary: React.ReactNode | string;
    imgUrl?: string;
  };
  hasSearched?: boolean;
  open: boolean;
};

export const OrgAndBrandSelectorItems = <T extends Org | Brand>({
  items,
  onItemSelect,
  noResultsText,
  getItemId,
  getDisplayProps,
  hasSearched,
  open,
}: Readonly<OrgAndBrandSelectorItemsProps<T>>) => {
  const [refs, setRefs] = useState<Array<RefObject<HTMLDivElement>>>([]);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState(-1);
  const [keyPressed, setKeyPressed] = useState<KeyPressType>({ key: '' });

  const keyPressHandler = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'ArrowUp' || key === 'ArrowDown' || key === 'Escape') {
      e.preventDefault();
      setKeyPressed({ key });
    }
  };

  useEffect(() => {
    if (!open) {
      setCurrentFocusedIndex(-1);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      window.addEventListener('keydown', keyPressHandler);
    }
    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, [open]);

  useEffect(() => {
    setRefs(items.map(() => createRef()));
  }, [items]);

  useKeyboardNavigation<T>(
    items,
    refs,
    keyPressed,
    currentFocusedIndex,
    setCurrentFocusedIndex,
    () => {}
  );

  if (hasSearched && !items.length) {
    return (
      <StyledMessageContainer>
        <Typography variant="body1">{noResultsText}</Typography>
      </StyledMessageContainer>
    );
  }

  return (
    <>
      {items.map((item, index) => {
        const id = getItemId(item);
        if (!id) return null;

        const { primary, secondary, imgUrl } = getDisplayProps(item);

        return (
          <OrgAndBrandSelectorItem
            key={id}
            dataFd={`org-brand-selector-item-${id}`}
            imgUrl={imgUrl}
            imgName={primary}
            primary={primary}
            secondary={secondary}
            handleItemClick={(shouldOpenInNewTab) => onItemSelect(item, shouldOpenInNewTab)}
            ref={refs[index]}
          />
        );
      })}
    </>
  );
};
