import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import LinkButton from '@fd/ui/Button/LinkButton';

import { permissionsSelector } from '../../../selectors/permissions.selector';

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  paperWrapper: {
    backgroundColor: palette.secondary.main,
    marginBottom: spacing(1),
    marginTop: spacing(1),
  },
  invoiceBanner: {
    borderRadius: '8px',
  },
  displayFlex: {
    display: 'flex',
  },
  textContent: {
    color: palette.common.white,
    wordBreak: 'break-word',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  minWidth: {
    minWidth: 'fit-content',
  },
  linkButton: {
    letterSpacing: '1.25px',
  },
}));

const PausedPayoutsBanner = (props: MappedState) => {
  const { appId, hasPermission } = props;
  const classes = useStyles();
  const location = useLocation();

  const toUrl = `/${appId}/finance/invoices`;

  const showBtnIfNoMatchUrl = location.pathname !== toUrl;

  return (
    <Grid
      container
      alignItems="flex-start"
      className={clsx(classes.invoiceBanner, classes.paperWrapper)}
    >
      <Grid item xs={12} className={classes.displayFlex}>
        <Toolbar>
          <Typography variant="body2" className={classes.textContent}>
            <Translate id="You_currently_have_one_or_more_unpaid_subscription" />
          </Typography>

          <div style={{ flexGrow: 1 }} />
          {showBtnIfNoMatchUrl && hasPermission && (
            <Hidden smDown>
              <Box className={classes.minWidth}>
                <LinkButton
                  className={classes.linkButton}
                  color="primary"
                  fdKey="Go_to_invoices_btn"
                  to={toUrl}
                  type="button"
                  variant="text"
                >
                  <Translate id="Go_to_invoices" />
                </LinkButton>
              </Box>
            </Hidden>
          )}
        </Toolbar>
      </Grid>

      {showBtnIfNoMatchUrl && hasPermission && (
        <Hidden smUp>
          <Grid item xs={12}>
            <Box ml={1} mr={2} mb={1}>
              <LinkButton
                className={classes.linkButton}
                color="primary"
                fdKey="Go_to_invoices_btn"
                to={toUrl}
                type="button"
                variant="text"
              >
                <Translate id="Go_to_invoices" />
              </LinkButton>
            </Box>
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'Owner',
    'StoreOwner',
    'StoreManager',
    'ManagedOwner',
    'FinanceManger',
    'FlipdishStaff',
  ]);
  return (state: AppState) => {
    return {
      appId: state.currentApp.AppId!,
      hasPermission: getPermissionsSelector(state),
    };
  };
};

export default connect(mapStateToPropsFactory, {})(PausedPayoutsBanner);
