import {
  type Brand,
  type CreateBrand,
  type CreateBrandForOrg201Response,
  type GetAllBrands200Response,
  type GetBrandsByOrgId200Response,
  BrandsApi,
  BrandsApiGetAllBrandsRequest,
  Configuration,
} from '@flipdish/orgmanagement';

import { getApiEndPoint } from '../../../helpers/apibase';
import { mapServerError } from '../../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const configuration = new Configuration({
  basePath: baseURL,
  useDefaultUserAgent: true,
});

const defaultOptions = {
  withCredentials: true,
};

const brandsApi = new BrandsApi(configuration);

export const getBrandsForOrgKey = 'getBrandsForOrg';
export async function getBrandsForOrg(
  orgId: string
): Promise<{ data: GetBrandsByOrgId200Response }> {
  try {
    return await brandsApi.getBrandsByOrgId({ orgId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const getBrandByIdKey = 'getBrandById';
export async function getBrandById({
  orgId,
  brandId,
}: {
  orgId: string;
  brandId: string;
}): Promise<{ data: CreateBrandForOrg201Response }> {
  try {
    return await brandsApi.getBrandById({ brandId, orgId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const creaetBrandKey = 'createBrand';
export async function createBrand({
  orgId,
  brand,
}: {
  orgId: string;
  brand: CreateBrand;
}): Promise<{ data: CreateBrandForOrg201Response }> {
  try {
    return await brandsApi.createBrandForOrg({ orgId, createBrand: brand }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const updateBrandKey = 'updateBrand';
export async function updateBrand({
  orgId,
  brand,
  brandId,
}: {
  orgId: string;
  brand: Brand;
  brandId: string;
}): Promise<{ data: CreateBrandForOrg201Response }> {
  try {
    return await brandsApi.updateBrandById({ orgId, brand, brandId }, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const getAllBrandsKey = 'getAllBrands';
export async function getAllBrands(
  req: BrandsApiGetAllBrandsRequest = {}
): Promise<{ data: GetAllBrands200Response }> {
  try {
    return await brandsApi.getAllBrands(req, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const brandService = {
  createBrand,
  getBrandsForOrg,
  getBrandsForOrgKey,
  updateBrand,
  getBrandById,
  getAllBrands,
  getBrandByIdKey,
};

export default brandService;
