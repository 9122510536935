import React, { useEffect, useMemo } from 'react';

import {
  App,
  BankAccountDetail,
  RestApiErrorResult,
  StripeConnectedAccountInfo,
} from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { type RouteComponentProps } from 'react-router';
import { compose } from 'recompose';

import { FeatureFlag } from '@fd/ui/FeatureFlag';
import PageLayout from '@fd/ui/Layout';
import GridContainer from '@fd/ui/Layout/GridContainer';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import { getSalesChannelType } from '@fd/ui/utils/generateSalesChannelsTypes';

import { notify, notifyError, NotifyProps, notifySaved } from '../../../../layouts/Notify/actions';
import { editBankAccountFeature } from '../../../../selectors/app.selector';
import { bankingServices } from '../../../../services/banking.service';
import { flagService } from '../../../../services/flagService';
import useStoreToSalesChannelTransformerWithQuery from '../../../Settings/utils/useStoreToSalesChannelTransformerWithQuery';
import StoreListTruncated from '../../../StoreListTruncated';
import * as bankingActions from '../banking.actions';
import { countryCodeToCountry, getAccountByRouteId } from '../banking.selectors';
import AlertBankDetails from '../components/AlertBankDetails';
import EditAccountLink from '../components/EditAccountLink';
import EditStripeAccountDetailsButton from '../components/EditStripeAccountDetailsButton';
import GridDivider from '../components/GridDivider';
import RemoveBankAccountButton from '../components/RemoveBankAccountButton';
import StaffVerifyButtons from '../components/StaffVerifyAccount';
import VerifyYourDetails from '../components/VerifyYourDetails';
import { nonVATSupportedCountries } from '../constants';
import DynamicAccountDetails from './components/DynamicAccountDetails';
import { ManualAccountVerification } from './components/ManualAccountVerification';
import LoadingDetails from './LoadingDetails';

const styles = (theme: Theme) =>
  createStyles({
    column: {
      paddingBottom: theme.spacing(2.5),
      paddingTop: theme.spacing(3.25),
      [theme.breakpoints.up('xs')]: {
        paddingTop: theme.spacing(3),
      },
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        textAlign: 'center',
      },
    },
    snackbarContent: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2.5),
      '&>div': {
        padding: theme.spacing(0),
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    snackbarSpan: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.43',
      letterSpacing: '0.3px',
    },
    snackbarIcon: {
      width: '22px',
      marginRight: theme.spacing(1.5),
    },
    accountStatusUnverified: {
      color: '#ff395f',
      fontWeight: 'normal',
    },
    accountStatusPending: {
      color: '#fbcf7c',
      fontWeight: 'normal',
    },
    accountStatusVerified: {
      color: '#86e8bb',
      fontWeight: 'normal',
    },
    storesTruncated: {
      color: '#05149e',
      fontSize: '16px',
      padding: theme.spacing(0),
      textTransform: 'capitalize',
    },
    subHeadingBankDetails: {
      letterSpacing: '0.38px',
      fontSize: '14px',
      color: '#000000',
    },
    editStripeButton: {
      [theme.breakpoints.only('xs')]: {
        paddingTop: theme.spacing(1),
      },
    },
    bottomGridDivider: {
      paddingTop: theme.spacing(2),
      width: '100%',
    },
    stripeHelpText: {
      color: theme.palette.text.secondary,
    },
    gridItem: {
      padding: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
    },
  });

export type Props = WithStyles<typeof styles> &
  RouteComponentProps<{ appId: string; accountId: string }>;
const BankingDetails = (props: Props & MappedState & MappedDispatch) => {
  const {
    appId,
    account,
    classes,
    editBankAccountFeature,
    getBankAccountDetails,
    removeBankAccount,
    dispatchNotifyError,
    dispatchNotifySaved,
    allowUpdateToBankingVatNumber,
  } = props;
  const [vatNumber, setVatNumber] = React.useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isLoading = account === undefined;

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (variables: { accountId; account }) =>
      bankingServices.updateBankAccountByIdInZeus(appId, variables.accountId, variables.account),

    onSuccess: () => {
      dispatchNotifySaved();
    },
    onError: (error: AxiosError) => {
      if (error instanceof AxiosError) {
        const apiErrorMessage = error.response?.data as RestApiErrorResult;
        dispatchNotifyError({ message: apiErrorMessage.Message });
      } else {
        dispatchNotifyError({ message: 'Something_went_wrong' });
      }
    },
  });
  const isStripeCountryAndVerified =
    account &&
    account.StripeConnectedAccountInfo &&
    account.AccountState === BankAccountDetail.AccountStateEnum.Verified;
  const stripeStatus = account?.StripeConnectedAccountInfo?.AccountStatus;
  const accountStatus = account?.AccountState;
  const showStripeAlert =
    account &&
    account.StripeConnectedAccountInfo &&
    account.StripeConnectedAccountInfo.AccountStatus ===
      StripeConnectedAccountInfo.AccountStatusEnum.UpdateExternalAccount;
  const showVerifyStripeDetails =
    account?.AccountState !== BankAccountDetail.AccountStateEnum.Unverified &&
    (stripeStatus === StripeConnectedAccountInfo.AccountStatusEnum.Unverified ||
      stripeStatus === StripeConnectedAccountInfo.AccountStatusEnum.AdditionalInformationRequired);
  const showEditStripeAccountDetails = account && stripeStatus && !showVerifyStripeDetails;
  const showVerifyActions =
    account && account.AccountState === BankAccountDetail.AccountStateEnum.AwatingVerification;
  const showEditButton =
    stripeStatus !== StripeConnectedAccountInfo.AccountStatusEnum.Rejected &&
    stripeStatus !== StripeConnectedAccountInfo.AccountStatusEnum.Disabled &&
    accountStatus !== BankAccountDetail.AccountStateEnum.Unverified;
  const hasActionButtons = showVerifyActions || (editBankAccountFeature && showEditButton);

  const storeIdAssociations = useStoreToSalesChannelTransformerWithQuery();
  const accountStoreNames = useMemo(() => {
    const associatedStoreNames = account?.StoreIds?.map((storeId) => {
      const association = storeIdAssociations.find(
        (association) => association.storeId === storeId
      );
      return association?.salesChannelName
        ? `${association?.salesChannelName}${getSalesChannelType(association?.salesChannelType ?? '')}`
        : '';
    });
    const removedEmptyStoreNames = associatedStoreNames?.filter((name) => name);
    if ((removedEmptyStoreNames || [])?.length > 0) {
      return removedEmptyStoreNames;
    }
    return account?.StoreNames || [];
  }, [account, storeIdAssociations]);

  useEffect(() => {
    getBankAccountDetails();
  }, []);

  useEffect(() => {
    if (account) {
      setVatNumber(account?.VatNumber);
    }
  }, [account]);

  const handleSaveVatNumber = () => {
    mutateAsync({
      accountId: account.Id,
      account: { ...account, VatNumber: vatNumber },
    });
  };

  const showVatNumberField = !nonVATSupportedCountries.includes(account?.AccountHolderCountryCode);
  return (
    <PageLayout
      documentTitle="Banking"
      strictToParent={true}
      title={account?.AccountName || ''}
      toParent="./"
    >
      <Permissions allowed={[App.AppResourceSetEnum.ViewBankAccounts]}>
        <VerifyYourDetails account={account} showVerifyStripeDetails={showVerifyStripeDetails} />
      </Permissions>
      {editBankAccountFeature && showStripeAlert && <AlertBankDetails />}
      <PaperContainer>
        {isLoading && <LoadingDetails />}

        {account != undefined && (
          <GridContainer>
            <DynamicAccountDetails account={account} />

            <GridDivider />

            <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle1">
                    <Translate id="Account_holder_address" />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    variant="standard"
                    data-fd={account.AccountHolderAddress}
                    defaultValue={account.AccountHolderAddress}
                    fullWidth
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label={<Translate id="Address" />}
                    multiline
                    maxRows="5"
                  />
                  <TextField
                    variant="standard"
                    data-fd={account.AccountHolderCountryCode}
                    defaultValue={countryCodeToCountry(account.AccountHolderCountryCode)}
                    fullWidth
                    InputProps={{ readOnly: true, disableUnderline: true }}
                    label={<Translate id="Country" />}
                    margin="none"
                  />
                </Grid>
              </Grid>
            </Grid>

            <GridDivider />
            {showVatNumberField && (
              <>
                <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
                  <Grid container>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="subtitle1">
                        <Translate id="Vat_details" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        variant="standard"
                        data-fd={'vat-number'}
                        fullWidth
                        value={vatNumber}
                        onChange={(e) => setVatNumber(e.target.value)}
                        label={<Translate id="Vat_number_EU_only" />}
                        margin="none"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <GridDivider />
              </>
            )}
            <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
              <Grid container>
                <Grid item xs={12} sm={5}>
                  <Typography variant="subtitle1">
                    <Translate id="Associated_sales_channels" />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  {accountStoreNames?.length > 0 ? (
                    <>
                      <StoreListTruncated
                        btnFontSize={'16px'}
                        color="textPrimary"
                        minWidth={'large'}
                        storeNames={accountStoreNames}
                        textPrefix={false}
                        variant={'body1'}
                      />
                    </>
                  ) : (
                    <Typography className={classes.subHeadingBankDetails}>
                      <Translate id="No_associated_stores" />
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <GridDivider />

            {showEditStripeAccountDetails && (
              <>
                <Grid item xs={12} className={clsx(classes.column, classes.gridItem)}>
                  <Grid container>
                    <Grid item xs={12} sm={5}>
                      <Typography variant="subtitle1">
                        <Translate id="Payouts" />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <Box className={classes.editStripeButton}>
                        <EditStripeAccountDetailsButton
                          bankAccountId={account.Id}
                          stripeAccount={account.StripeConnectedAccountInfo!}
                        />
                      </Box>
                      <Box pt={2}>
                        <Typography variant="body2" className={classes.stripeHelpText}>
                          <Translate id="We_use_Stripe_helptext" />
                        </Typography>
                        <Typography variant="body2" className={classes.stripeHelpText}>
                          <Translate id="Youll_be_redirected_to_Stripe_helptext" />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <GridDivider />
              </>
            )}
            {allowUpdateToBankingVatNumber && showVatNumberField && (
              <Permissions allowed={['Owner', 'ManagedOwner', 'FlipdishStaff']}>
                <Grid
                  display="flex"
                  direction="row"
                  item
                  justifyContent="flex-end"
                  xs={12}
                  className={classes.gridItem}
                >
                  <Box pt={isMobile ? 1.5 : 0} className={classes.button}>
                    <Button
                      disabled={isPending}
                      variant="outlined"
                      color="primary"
                      onClick={handleSaveVatNumber}
                    >
                      <Translate id="Save_VAT_details" />
                    </Button>
                  </Box>
                </Grid>
                <GridDivider />
              </Permissions>
            )}

            <Grid
              display="flex"
              direction="row"
              item
              justifyContent="flex-end"
              xs={12}
              className={classes.gridItem}
            >
              {showVerifyActions && (
                <Permissions allowed={['FlipdishStaff']}>
                  <Box pt={isMobile ? 1.5 : 0} className={classes.button}>
                    <StaffVerifyButtons accountId={account.Id as number} />
                  </Box>
                </Permissions>
              )}
              {/* for stripe accounts we manually approve it first and after we show PayoutSchedule verification */}
              {isStripeCountryAndVerified && (
                <Permissions allowed={['FlipdishStaff']}>
                  <Box pt={isMobile ? 1.5 : 0} className={classes.button}>
                    <ManualAccountVerification
                      stripeAccount={account.StripeConnectedAccountInfo!}
                      appId={appId}
                      getBankAccountDetails={getBankAccountDetails}
                    />
                  </Box>
                </Permissions>
              )}

              <FeatureFlag flag="edit-bank-account">
                {showEditButton && (
                  <Box pt={isMobile ? 1.5 : 0} className={classes.button}>
                    <EditAccountLink accountId={account.Id as number} />
                  </Box>
                )}
              </FeatureFlag>
            </Grid>

            {hasActionButtons && (
              <Hidden mdUp>
                <div className={classes.bottomGridDivider}>
                  <GridDivider />
                </div>
              </Hidden>
            )}
          </GridContainer>
        )}
      </PaperContainer>

      {account != undefined && (
        <Box pt={isMobile ? 0.5 : 3} className={classes.button}>
          <RemoveBankAccountButton
            handleRemove={removeBankAccount}
            storeNames={account.StoreNames}
          />
        </Box>
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (initialState, ownProps) => {
  const { locale } = initialState;
  return (state: AppState) => {
    return {
      appId: state.currentApp.AppId!,
      account: getAccountByRouteId(state, ownProps.match.params.accountId),
      editBankAccountFeature: editBankAccountFeature(state),
      translate: getTranslate(locale),
      allowUpdateToBankingVatNumber: flagService.isFlagOn(state, 'allowUpdateToBankingVatNumber'),
    };
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch, ownProps: Props) => ({
  getBankAccountDetails: () => {
    dispatch(bankingActions.getBankAccountDetails(ownProps.match.params.accountId));
  },
  removeBankAccount: () =>
    dispatch(bankingActions.removeBankAccount(Number(ownProps.match.params.accountId))).then(() => {
      ownProps.history.replace(`/${ownProps.match.params.appId}/finance/bank-accounts/`);
    }),
  notify: (data: NotifyProps) => dispatch(notify(data)),
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

export default compose<Props, RouteComponentProps<{ appId: string; accountId: string }>>(
  withStyles(styles, {
    name: 'BankingDetails',
    withTheme: true,
  }),
  connect(mapStateToPropsFactory, mapDispatchToProps)
)(BankingDetails);
