import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { type Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import Tag from '../../../components/common/Tag';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '244px',
    color: '#ff395f',
    letterSpacing: '1.25px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    [theme.breakpoints.down(640)]: { width: '100%' },
    '& :hover': { color: 'rgba(0, 0, 0, 0.38)' },
  },
  container: {
    [theme.breakpoints.down(813)]: { borderTop: '1px solid #dbdcdf' },
    [theme.breakpoints.up('md')]: { maxWidth: '916px' },
    [theme.breakpoints.up('lg')]: { maxWidth: '780px' },
  },
  gridContainer: {
    [theme.breakpoints.down(640)]: { padding: '16px' },
    [theme.breakpoints.up('sm')]: { padding: '24px 24px 0 24px' },
  },
  cardHeader: {
    marginBottom: '16px',
  },
  description: {
    marginTop: 0,
    marginBottom: 0,
    lineHeight: '20px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: '0.25px',
  },
  actionsGrid: {
    textAlign: 'end',
    [theme.breakpoints.down(640)]: {
      marginBottom: '8px',
    },
  },
}));

type Props = {
  disabled: boolean;
  onClick: () => void;
  title: TranslationId;
  description: TranslationId;
  buttonText: TranslationId;
  showStaffTag?: boolean;
  divider?: boolean;
  dataFdPrefix?: string;
};

const DangerZoneSimpleActionCard = ({
  disabled,
  onClick,
  title,
  description,
  buttonText,
  showStaffTag = false,
  divider = true,
  dataFdPrefix = '',
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Card
      className={classes.container}
      square
      elevation={isMobile ? 0 : 2}
      style={divider ? { borderBottom: '1px solid lightgray' } : {}}
    >
      <Grid container className={classes.gridContainer}>
        {showStaffTag && (
          <Grid item xs={12} sm={7} md={7} lg={8} style={{ marginBottom: '24px' }}>
            <Tag noMargin showBetaTag tagName="Flipdish_staff_only" />
          </Grid>
        )}
        <Grid item xs={12} sm={7} md={7} lg={8} style={{ marginBottom: '24px' }}>
          <Typography variant="h5" className={classes.cardHeader}>
            <Translate id={title} />
          </Typography>
          <Typography variant="body2" className={classes.description}>
            <Translate id={description} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} classes={{ root: classes.actionsGrid }}>
          <Button
            className={classes.button}
            data-fd={`${dataFdPrefix}-btn`}
            disabled={disabled}
            onClick={onClick}
            variant="outlined"
          >
            <Translate id={buttonText} />
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DangerZoneSimpleActionCard;
