import React, { useEffect } from 'react';

import { CreateVoucher, UpdateVoucher, Voucher } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type FormikProps, Form, withFormik } from 'formik';
import moment from 'moment';
import { Translate, TranslateFunction } from 'react-localize-redux';

import { LoadingButton } from '@fd/ui/LoadingButton';
import Spacer from '@fd/ui/Spacer';

import AdvancedOptionsFormItems from '../../components/AdvancedOptionsFormItems';
import AutomaticallyApplyField from '../../components/fields/AutomaticallyApplyField';
import CanBeUsedField from '../../components/fields/CanBeUsedField';
import ChannelField from '../../components/fields/ChannelField';
import CodeField from '../../components/fields/CodeField';
import { DescriptionField } from '../../components/fields/DescriptionField';
import IsDiscoverableField from '../../components/fields/IsDiscoverableField';
import IsValidField from '../../components/fields/IsValidField';
import StoresSelectField from '../../components/fields/StoresSelectField';
import GridDivider from '../../components/GridDivider';
import { useVoucherForm } from '../../hooks/useVoucherForm';
import { VoucherErrorCodes } from '../../types';
import {
  getCanBeUsed,
  getValidDates,
  getValidPayment,
  getValidType,
} from '../../utils/voucherUtils';
import MenuSelectField from './MenuSelectField';
import PromotionalMenuItemField from './PromotionalMenuItemField';

export interface Props {
  appId: string;
  create: (voucher) => Promise<void>;
  isEditMode: boolean;
  isLoading: boolean;
  isVoucherChannelEnabled: boolean; // TODO - remove when voucherChannel is fully released
  isVoucherDiscoverableEnabled: boolean; // TODO - remove when voucherDiscoverable is fully released
  isVoucherLimitAvailabilityEnabled: boolean; // TODO - remove when voucherLimitAvailability is fully released
  isVoucherValidPeriodEnabled: boolean; // TODO - remove when voucherValidPeriod is fully released
  translate: TranslateFunction;
  update: (voucher) => Promise<void>;
  voucher: Voucher;
}

export type CustomFormValues = {
  Menu?: { label: string; value: number };
};

export type FormValues = ReturnType<typeof getDefaultFormState> & CustomFormValues;
export const getDefaultFormState = (voucher: Voucher) => {
  const start = voucher && voucher.StartDate ? moment(voucher.StartDate) : moment();
  const expiry =
    voucher && voucher.ExpiryDate ? moment(voucher.ExpiryDate) : moment().add(7, 'days');

  return {
    CanBeUsed: getCanBeUsed(voucher),
    ChannelRestrictions: [Voucher.ChannelRestrictionsEnum.Web], // Restricted to only Web
    Code: (voucher && voucher.Code) || '',
    Description: (voucher && voucher.Description) || '',
    ExpiryDate: expiry.toDate(),
    IsAutomaticallyApplied: voucher ? voucher.IsAutomaticallyApplied : false,
    IsDiscoverable: voucher ? voucher.IsDiscoverable : false,
    IsEnabled: voucher ? voucher.IsEnabled : false,
    IsValidForDeliveryOrders: voucher ? voucher.IsValidForDeliveryOrders : true,
    IsValidForFirstOrderOnly: voucher ? voucher.IsValidForFirstOrderOnly : false,
    IsValidForOrdersPayedByCash: voucher ? voucher.IsValidForOrdersPayedByCash : true,
    IsValidForOrdersPayedOnline: voucher ? voucher.IsValidForOrdersPayedOnline : true,
    IsValidForPickupOrders: voucher ? voucher.IsValidForPickupOrders : true,
    IsValidOncePerCustomer: voucher ? voucher.IsValidOncePerCustomer : false,
    IsValidOnlyOnce: voucher ? voucher.IsValidOnlyOnce : true,
    MaxRedemptions: voucher ? voucher.MaxRedemptions : undefined,
    PromotionAwardMenuItemPublicIds: voucher
      ? voucher.PromotionDetails?.PromotionAwards?.map((award) => award.MenuItemPublicId || '')
      : undefined,
    PromotionId: voucher ? voucher.PromotionDetails?.PromotionId : undefined,
    PromotionName: voucher ? voucher.PromotionDetails?.Name : undefined,
    StartDate: start.toDate(),
    Stores: (voucher && voucher.Stores) || [],
    TakesPriority: voucher ? voucher.TakesPriority : false,
    ValidDates:
      (voucher && getValidDates(voucher)) || `${start.format('L')} - ${expiry.format('L')}`,
    ValidityPeriods: (voucher && voucher.ValidityPeriods) || [],
    ValidOnOrdersOver: (voucher && voucher.ValidOnOrdersOver) || 0,
    ValidPayment: getValidPayment(voucher),
    ValidType: getValidType(voucher),
    VoucherType: CreateVoucher.VoucherTypeEnum.AddItem,
  };
};

const MenuItemDiscountForm = ({
  appId,
  errors,
  handleSubmit,
  isEditMode,
  isLoading,
  isSubmitting,
  isVoucherChannelEnabled, // TODO - remove when voucherChannel is fully released
  isVoucherDiscoverableEnabled, // TODO - remove when voucherDiscoverable is fully released
  isVoucherLimitAvailabilityEnabled, // TODO - remove when voucherLimitAvailability is fully released
  isVoucherValidPeriodEnabled, // TODO - remove when voucherValidPeriod is fully released
  setFieldValue,
  translate,
  values,
}: FormikProps<FormValues> & Props) => {
  useVoucherForm<FormValues>({ errors, isSubmitting, translate });

  useEffect(() => {
    if (values.Menu?.value) {
      if (values.Stores.length) {
        setFieldValue('Stores', []);
      }
      if (values.PromotionAwardMenuItemPublicIds?.length) {
        setFieldValue('PromotionAwardMenuItemPublicIds', []);
      }
    }
  }, [values.Menu?.value]);

  const isSubmitDisabled =
    isSubmitting ||
    !values.Code ||
    !values.Stores.length ||
    !values.PromotionAwardMenuItemPublicIds?.length;

  return (
    <Form>
      <Grid container>
        {!isEditMode && (
          <>
            <Grid item xs={12}>
              <MenuSelectField appId={appId} />
            </Grid>
            <GridDivider />
          </>
        )}

        {isVoucherChannelEnabled && (
          <>
            <Grid item xs={12}>
              <ChannelField
                enabledChannels={[Voucher.ChannelRestrictionsEnum.Web]}
                isDisabled
                translate={translate}
              />
            </Grid>

            <GridDivider />
          </>
        )}

        <Grid item xs={12}>
          <StoresSelectField isDisabled={!values.Menu} selectedMenuId={values.Menu?.value || 0} />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <CodeField disabled={isEditMode} />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <DescriptionField
            translate={translate}
            isEditMode={isEditMode}
            defaultValue={''} // TODO: add default description based on menu item
          />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <PromotionalMenuItemField
            isDisabled={isEditMode}
            menuId={values.Menu?.value}
            translate={translate}
          />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <IsValidField />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <AutomaticallyApplyField />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <CanBeUsedField translate={translate} />
        </Grid>

        <GridDivider />

        {isVoucherDiscoverableEnabled && (
          <>
            <Grid item xs={12}>
              <IsDiscoverableField />
            </Grid>

            <GridDivider />
          </>
        )}

        <Grid item xs={12}>
          <AdvancedOptionsFormItems
            automaticallyApply={values.IsAutomaticallyApplied || false}
            isMenuItemDiscount
            isVoucherLimitAvailabilityEnabled={isVoucherLimitAvailabilityEnabled} // TODO - remove when voucherLimitAvailability is fully released
            isVoucherValidPeriodEnabled={isVoucherValidPeriodEnabled} // TODO - remove when voucherValidPeriod is fully released
            translate={translate}
          />
        </Grid>

        <GridDivider />

        <Grid item xs={12}>
          <Spacer size={16}>
            <Grid container justifyContent="flex-end" alignContent="center" spacing={2}>
              <Grid item>
                <LoadingButton
                  type="submit"
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    !isEditMode && setFieldValue('IsEnabled', false, false);
                    handleSubmit();
                  }}
                  fdKey="btn-voucher-create"
                  disabled={isSubmitDisabled}
                  loading={isLoading}
                >
                  {isEditMode ? <Translate id="Update" /> : <Translate id="Create" />}
                </LoadingButton>
              </Grid>
              {!isEditMode && (
                <Grid item>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    onClick={() => {
                      setFieldValue('IsEnabled', true, false);
                      handleSubmit();
                    }}
                    fdKey="btn-voucher-create-enable"
                    variant="contained"
                    disabled={isSubmitDisabled}
                    loading={isLoading}
                  >
                    <Translate id="Create_and_enable" />
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Spacer>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withFormik<Props, FormValues>({
  displayName: 'MenuItemDiscountForm',
  mapPropsToValues: ({ voucher }) => {
    return getDefaultFormState(voucher);
  },
  handleSubmit: (values, formikBag) => {
    const {
      ChannelRestrictions,
      Code,
      Description,
      ExpiryDate,
      IsAutomaticallyApplied,
      IsDiscoverable,
      IsEnabled,
      IsValidForDeliveryOrders,
      IsValidForFirstOrderOnly,
      IsValidForOrdersPayedByCash,
      IsValidForOrdersPayedOnline,
      IsValidForPickupOrders,
      IsValidOncePerCustomer,
      IsValidOnlyOnce,
      MaxRedemptions,
      PromotionAwardMenuItemPublicIds,
      PromotionId,
      PromotionName,
      StartDate,
      Stores,
      TakesPriority,
      ValidityPeriods,
      ValidOnOrdersOver,
      VoucherType,
    } = values;

    const getVoucher = (): CreateVoucher | UpdateVoucher => {
      if (!formikBag.props.isEditMode) {
        const promotion = {
          Name: PromotionName || '',
          MenuItemPublicIds: PromotionAwardMenuItemPublicIds,
        };

        const createVoucher: CreateVoucher = {
          ChannelRestrictions,
          Code,
          Description,
          ExpiryDate: moment(ExpiryDate).endOf('day').format() as unknown as Date,
          IsAutomaticallyApplied,
          IsDiscoverable,
          IsEnabled,
          IsValidForDeliveryOrders,
          IsValidForFirstOrderOnly,
          IsValidForOrdersPayedByCash,
          IsValidForOrdersPayedOnline,
          IsValidForPickupOrders,
          IsValidOncePerCustomer,
          IsValidOnlyOnce,
          MaxRedemptions,
          Promotion: promotion,
          StartDate: moment(StartDate).startOf('day').format() as unknown as Date,
          Stores,
          TakesPriority: !IsAutomaticallyApplied ? false : TakesPriority,
          ValidityPeriods,
          ValidOnOrdersOver,
          VoucherType,
        };
        return createVoucher;
      }

      const updateVoucher: UpdateVoucher = {
        ChannelRestrictions,
        Code,
        Description,
        ExpiryDate: moment(ExpiryDate).endOf('day').format() as unknown as Date,
        IsAutomaticallyApplied,
        IsDiscoverable,
        IsEnabled,
        IsValidForDeliveryOrders,
        IsValidForFirstOrderOnly,
        IsValidForOrdersPayedByCash,
        IsValidForOrdersPayedOnline,
        IsValidForPickupOrders,
        IsValidOncePerCustomer,
        IsValidOnlyOnce,
        MaxRedemptions,
        PromotionAwardMenuItemPublicIds,
        PromotionId,
        PromotionName,
        StartDate: moment(StartDate).startOf('day').format() as unknown as Date,
        Stores,
        TakesPriority: !IsAutomaticallyApplied ? false : TakesPriority,
        ValidityPeriods,
        ValidOnOrdersOver,
      };
      return updateVoucher;
    };

    const voucher = getVoucher();

    const serverFunc = formikBag.props.isEditMode
      ? formikBag.props.update(voucher)
      : formikBag.props.create(voucher);
    serverFunc
      .then(() => {
        formikBag.resetForm({ values: values }); // reset dirty state
      })
      .catch((err) => {
        formikBag.setSubmitting(false);
        if (err.code === VoucherErrorCodes.VOUCHER_CODE_ALREADY_EXISTS) {
          formikBag.setFieldError('Code', 'Could_not_create_voucher_with_this_code_already_exists');
        }
      });
  },
})(MenuItemDiscountForm);
