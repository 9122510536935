import React, { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import MuiAutocomplete, { type AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { AddressSuggestion } from './AddressSuggestion';
import type {
  AddressAutoCompleteSuggestionDetails,
  AddressSuggestionPropsType,
} from './types/GoogleMapTypes';
import { useMapTools } from './useMapTools';

const StyledAutocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '.MuiOutlinedInput-root': { paddingLeft: theme.spacing(1.75) },
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

type FormValues = {
  address: string;
};

type Props = MappedState & {
  onAddressSuggestionClicked: (geoCodeResult: google.maps.places.PlaceResult) => void;
  currentLocation: google.maps.LatLngLiteral;
};

const Autocomplete = (props: Props) => {
  const { currentLocation, onAddressSuggestionClicked, translate } = props;

  const mapTools = useMapTools();

  const [suggestions, setSuggestions] = useState<AddressAutoCompleteSuggestionDetails[]>([]);

  const handleInputChange = debounce(
    (
      _event: React.SyntheticEvent<Element, Event>,
      newInputValue: string,
      _reason: AutocompleteInputChangeReason
    ): void => {
      const minGeocodeInputLength = 5;

      if (newInputValue?.length < minGeocodeInputLength) {
        return;
      }

      (async () => {
        const newSuggestions = await mapTools?.getPlacePredictions(
          newInputValue,
          new google.maps.LatLng(currentLocation) as any
        );
        if (newSuggestions?.length) {
          handleAutocompleteResponse(newSuggestions);
        }
      })();
    },
    250
  );

  const handleAutocompleteResponse = (
    newSuggestions: google.maps.places.AutocompletePrediction[] | null
  ): void => {
    const formattedSuggestions: AddressAutoCompleteSuggestionDetails[] | undefined =
      newSuggestions?.map((newSuggestion) => ({
        description: newSuggestion.description,
        placeId: newSuggestion.place_id,
      }));

    if (formattedSuggestions) {
      setSuggestions(formattedSuggestions);
    }
  };

  const handlePlaceSelected = async (
    _event: React.SyntheticEvent<Element, Event>,
    selectedSuggestion: AddressAutoCompleteSuggestionDetails
  ): Promise<void> => {
    if (!suggestions || suggestions.length === 0 || !mapTools?.getPlaceDetails) {
      return;
    }
    const placeId = selectedSuggestion?.placeId;

    if (placeId) {
      const placeDetails = await mapTools?.getPlaceDetails(placeId);
      if (placeDetails?.geometry?.location) {
        onAddressSuggestionClicked(placeDetails);
      }
    }
  };

  const [value] = useState<FormValues>({
    address: '',
  });

  return (
    <StyledAutocomplete
      autoComplete
      filterOptions={(x) => x}
      noOptionsText={translate('No_Addresses_Found')}
      ListboxProps={{
        style: {
          maxHeight: 'unset',
        },
      }}
      onInputChange={handleInputChange}
      onChange={(event, values) => {
        handlePlaceSelected(event, values as AddressAutoCompleteSuggestionDetails);
      }}
      options={suggestions}
      getOptionLabel={(option: unknown) => {
        if (typeof option === 'string') {
          return option;
        } else if (typeof option === 'object' && option !== null && 'description' in option) {
          return (option as google.maps.places.AutocompletePrediction).description;
        } else {
          return '';
        }
      }}
      renderInput={(renderProps) => (
        <TextField
          {...renderProps}
          autoFocus
          label={translate('Property_Search_Prompt')}
          InputProps={{
            ...renderProps.InputProps,
            startAdornment: <StyledSearchIcon />,
            name: 'Search',
          }}
        />
      )}
      value={value}
      //@ts-ignore
      renderOption={(
        optionProps: AddressSuggestionPropsType['optionProps'],
        option: AddressAutoCompleteSuggestionDetails
      ): JSX.Element => (
        <AddressSuggestion option={option} key={optionProps.key} optionProps={optionProps} />
      )}
    />
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps)(Autocomplete);
