import React, { useEffect, useMemo, useState } from 'react';

import { CreatePropertyTypeEnum, UpdateProperty } from '@flipdish/orgmanagement';
import { type FieldProps, Field } from 'formik';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import Select from '@fd/ui/Select/Select';

type PropertyTypeOption = {
  label: string;
  value: CreatePropertyTypeEnum;
};

type OuterProps = {
  initialType?: CreatePropertyTypeEnum;
  onChange: (value: UpdateProperty['type']) => void;
};

type Props = MappedState & OuterProps;

const PropertyTypeFilter = (props: Props) => {
  const { initialType, onChange, translate } = props;

  const [selectedItem, setSelectedItem] = useState<PropertyTypeOption | null>(null);

  const options = useMemo(() => {
    const allOptions: PropertyTypeOption[] = [
      { value: CreatePropertyTypeEnum.Store, label: translate('Restaurant') as string },
      {
        value: CreatePropertyTypeEnum.CentralKitchen,
        label: translate('Central_kitchen') as string,
      },
    ];

    return allOptions.filter((option) => option.value !== selectedItem?.value);
  }, [translate, selectedItem]);

  const handleChange = (option: PropertyTypeOption) => {
    setSelectedItem(option);
    onChange(option.value);
  };

  useEffect(() => {
    if (initialType) {
      const initialOption: PropertyTypeOption = {
        value: initialType,
        label:
          initialType === CreatePropertyTypeEnum.Store
            ? (translate('Restaurant') as string)
            : (translate('Central_kitchen') as string),
      };

      setSelectedItem(initialOption);
    }
  }, [initialType]);

  return (
    <Field name="type">
      {({ field, form }: FieldProps) => {
        const { errors, touched, isSubmitting } = form;

        const fieldError = errors[field.name] as string | undefined;
        const showError = !!fieldError && (touched[field.name] as boolean | undefined);

        return (
          <Select
            dataFd={'property-type-filter'}
            disabled={isSubmitting}
            error={showError}
            helperText={
              showError && fieldError
                ? translate(fieldError, undefined, { missingTranslationMsg: fieldError })
                : null
            }
            isClearable
            isMulti={false}
            onChange={handleChange}
            options={options}
            placeholder={translate('Select_property_type') as TranslationId}
            TextFieldProps={{
              fdKey: 'property-type-dropdown',
              label: translate('Property_type') as TranslationId,
              name: 'property-type-filter',
            }}
            variant="standard"
            value={selectedItem}
          />
        );
      }}
    </Field>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;
  return {
    translate: getTranslate(locale),
  };
};

export default connect(mapStateToProps)(PropertyTypeFilter);
