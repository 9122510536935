import * as React from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { getTranslate } from 'react-localize-redux';
import { AutoAffix } from 'react-overlays';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { menusActions } from '../../actions/menus.actions';
import constants from '../../constants.json';
import { generalConstants } from '../../constants/general.constants';
import { getApiEndPoint } from '../../helpers/apibase';
import { isProductBasedMenusEnabled } from '../../selectors/app.selector';
import { flagService } from '../../services';
import PageLayout from '../../ui/Layout';
import DynamicAlertBanner from '../../ui/molecules/DynamicAlertBanner/DynamicAlertBanner';
import { AppLoadingCircularProgress } from '../AppLoadingCircularProgress';
import { EditableLabel } from '../common/molecules';
import { Checkpoints } from './components/MenuCheckpoints';
import { FirstColumn } from './components/MenuControlsFirstColumnResponsive';
import { SecondColumn } from './components/MenuControlsSecondColumnResponsive';
import { EditControls } from './components/MenuEditControls';
import { MenuMessage } from './components/MenuMessage';
import { getIntegrationMetaData } from './Menus.selectors';

const { SCROLLABLE_CONTAINER } = generalConstants;

const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    progressWrapper: {
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      background: 'white',
      zIndex: 2,
      bottom: 0,
      top: 0,
      width: 'calc(100% + 2px)',
      height: '100vh',
      maxWidth: '1200px',
      padding: spacing(2),
      paddingTop: '48px',
      [breakpoints.down('md')]: {
        paddingTop: '32px',
      },
    },
    container: {
      width: 'calc(100% + 24px)',
      margin: '-12px',
      [breakpoints.down('lg')]: {
        width: '100%',
        margin: '0',
      },
    },
    gridItem: {
      padding: '12px',
      [breakpoints.down('lg')]: {
        padding: '0',
      },
    },
    menuHolder: {
      [breakpoints.up('lg')]: {
        marginTop: '-12px',
      },
      [breakpoints.down('lg')]: {
        marginTop: '16px',
      },
      [breakpoints.down('md')]: {
        paddingLeft: '9px!important',
        paddingRight: '9px!important',
      },
      [breakpoints.down('sm')]: {
        marginTop: 0,
        paddingLeft: '1px!important',
        paddingRight: '1px!important',
      },
    },
    flipdishMenu: {
      marginLeft: '-27px',
      marginRight: '-27px',
      padding: '12px',
      [breakpoints.down('lg')]: {
        padding: '0',
        paddingTop: '20px',
        marginLeft: '-15px',
        marginRight: '-15px',
      },
      [breakpoints.down('md')]: {
        padding: '0',
        paddingTop: '20px',
        marginLeft: '0',
        marginRight: '0',
      },
    },
    flipdishMenuHidden: {
      visibility: 'hidden',
    },
    menuHeader: {
      padding: '0!important',
    },
    storeNames: {
      fontSize: '12px',
      fontWeight: 'normal' as any,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.33',
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.6)',
    },
    storesMore: {
      color: '#05149e',
    },
    divider: {
      [breakpoints.down('md')]: {
        marginLeft: '-9px',
        marginRight: '-9px',
      },
      [breakpoints.down('sm')]: {
        marginLeft: '-1px',
        marginRight: '-1px',
      },
    },
  });

export interface IMenuProps {
  parentComponent?: React.Component;
}

type MenuStateProps = {
  menu: FlipdishAPI.Menu;
  currentApp: FlipdishAPI.App;
  permissions: string[];
  menuTimestamp: number | null;
  menuCheckpointsTimestamp: number | null;
  menuStores: FlipdishAPI.MenuStoreNames[];
  language: string;
};

interface MatchParams {
  menuId: string | undefined;
}

type Props = IMenuProps &
  MenuStateProps &
  WithStyles<typeof styles> &
  RouteComponentProps<MatchParams> & { dispatch: ThunkDispatch };

class Menu extends React.Component<Props, any> {
  public state = {
    revisionMode: false,
    editLock: false,
    hideHidden: false,
    menuLoading: false,
    expanded: false,
  };

  private script: any;

  constructor(props: Props) {
    super(props);
  }

  public UNSAFE_componentWillMount() {
    const { match, dispatch } = this.props;

    if (match.params.menuId) {
      const menuId = parseInt(match.params.menuId);

      dispatch(menusActions.getMenu(menuId));
      dispatch(menusActions.getMenuCheckpoints(match.params.menuId));
      dispatch(menusActions.getMenuStores(match.params.menuId));
      dispatch(menusActions.getMenuTaxDetails(match.params.menuId));
    }
  }

  public componentDidMount() {
    const { menu, match } = this.props;

    if (menu && menu.Locked) {
      this.setState({ editLock: this.props.menu.Locked });
    }

    // If menu exists in store already on component mount
    if (menu && match.params.menuId && menu.MenuId === parseInt(match.params.menuId)) {
      this.loadMenu();
    }
  }

  public UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { menu, permissions } = newProps;

    // When menu changes and new menu exists
    if (menu != this.props.menu && menu) {
      this.setState({ editLock: menu.Locked });

      // If menu loaded for first time
      if (!this.props.menu || menu.MenuId != this.props.menu.MenuId) {
        this.loadMenu();
      }
    }

    const showHiddenNew =
      permissions.findIndex((permission) => permission === 'ShowHiddenFeatures') > -1;

    const showHiddenOld =
      this.props.permissions.findIndex((permission) => permission === 'ShowHiddenFeatures') > -1;

    if (!showHiddenOld && showHiddenNew) {
      if ((window as any).menuComponent) {
        (window as any).menuComponent.modifyHiddenFeatures(true);
      } else if ((window as any).configureComponent) {
        (window as any).configureComponent.modifyHiddenFeatures(true);
      }
    } else if (showHiddenOld && !showHiddenNew) {
      if ((window as any).menuComponent) {
        (window as any).menuComponent.modifyHiddenFeatures(false);
      } else if ((window as any).configureComponent) {
        (window as any).configureComponent.modifyHiddenFeatures(false);
      }
    }
  }

  public componentWillUnmount() {
    this.cleanUp();
  }

  public handeMenuReload = () => {
    this.cleanUp();
    this.loadMenu();
  };

  public toggleRevisionMode = () => {
    this.setState({ revisionMode: !this.state.revisionMode });
  };

  public handleExpand = (event, newExpanded) => {
    this.setState({ expanded: newExpanded });
  };

  public toggleEditLock = () => {
    const { dispatch, match } = this.props;

    this.setState({ editLock: !this.state.editLock });
    if (match.params.menuId) {
      dispatch(menusActions.lockMenu(parseInt(match.params.menuId), !this.state.editLock));
    }
  };

  public toggleHideHidden = () => {
    if ((window as any).menuComponent) {
      (window as any).menuComponent.setHideHidden(!this.state.hideHidden);
    } else if ((window as any).configureComponent) {
      (window as any).configureComponent.setHideHidden(!this.state.hideHidden);
    }

    this.setState({ hideHidden: !this.state.hideHidden });
  };

  public toggleMenuSectionBehaviour = () => {
    const { dispatch, menu } = this.props;

    const newMenuSectionBehaviour =
      menu.MenuSectionBehaviour === FlipdishAPI.Menu.MenuSectionBehaviourEnum.ExpandMultiple
        ? FlipdishAPI.Menu.MenuSectionBehaviourEnum.ExpandSingle
        : FlipdishAPI.Menu.MenuSectionBehaviourEnum.ExpandMultiple;

    if ((window as any).menuComponent) {
      (window as any).menuComponent.toggleMenuSectionBehaviour(newMenuSectionBehaviour);
    } else if ((window as any).configureComponent) {
      (window as any).configureComponent.toggleMenuSectionBehaviour(newMenuSectionBehaviour);
    }

    const failureCallback = () => {
      if ((window as any).menuComponent) {
        (window as any).menuComponent.toggleMenuSectionBehaviour(menu.MenuSectionBehaviour);
      } else if ((window as any).configureComponent) {
        (window as any).configureComponent.toggleMenuSectionBehaviour(menu.MenuSectionBehaviour);
      }
    };

    dispatch(menusActions.toggleMenuSectionBehaviour(menu, failureCallback));
  };

  public updateMenuTitle = (changes) => {
    const { match, dispatch } = this.props;

    // @ts-ignore
    dispatch(menusActions.renameMenu(parseInt(match.params.menuId, 10), changes.Name));
  };

  public render() {
    const {
      classes,
      currentApp,
      // @ts-ignore
      getIntegrationMetaData,
      // @ts-ignore
      isHideNextOptionSetButtonOnSkipOn,
      // @ts-ignore
      isProductBasedMenusOn,
      language,
      match,
      menu,
      menuCheckpointsTimestamp,
      menuStores,
      // @ts-ignore
      menuTaxDetails,
      menuTimestamp,
      parentComponent,
      permissions,
      // @ts-ignore
      showBannerAlertForIntegratedMenus,
      // @ts-ignore
      showAllMenusInEditor,
      // @ts-ignore
      translate,
    } = this.props;

    const { editLock, expanded, hideHidden, revisionMode, menuLoading } = this.state;

    const updatePermission =
      // @ts-ignore
      permissions.findIndex(
        // @ts-ignore
        (permission) => permission === FlipdishAPI.App.AppResourceSetEnum.UpdateMenu
      ) > -1;

    const menuId = menu && menu.MenuId;
    const showDepositReturnFee = currentApp.CountryId === 'IE';

    const toParentUrl = showAllMenusInEditor
      ? `/${currentApp.AppId}/menu-editor`
      : `/${currentApp.AppId}/menus`;

    return (
      <PageLayout
        auditLogsFilter={{ type: 'MenuId', value: `${menuId}` }}
        documentTitle={'Menus'}
        toParent={toParentUrl}
        strictToParent={true}
        title={
          <EditableLabel
            labelKey={'Store_group_name_label'}
            inputKey={'Store_group_name_input'}
            value={(menu && menu.Name) || `${translate('Unnamed')} ${menu ? menu.MenuId : ''}`}
            changePropName="Name"
            onChange={this.updateMenuTitle}
            disabled={(menu && menu.Locked) || !updatePermission}
          />
        }
        caption={
          menuStores.length > 0 && (
            <Typography variant="subtitle2" className={classes.storeNames}>
              {menuStores.slice(0, 2).join(', ')}
              {menuStores.length > 2 && (
                <span className={classes.storesMore}>
                  {` ${translate('More_stores', { stores: menuStores.length - 2 })}`}
                </span>
              )}
            </Typography>
          )
        }
      >
        {showBannerAlertForIntegratedMenus && getIntegrationMetaData.length > 0 && (
          <DynamicAlertBanner
            backgroundColor="yellow"
            bannerText={'Please_note_this_is_menu_is_being_used_by_an_integ'}
            showButton={false}
          />
        )}
        {!menu || menu.MenuId != match.params.menuId || menuTimestamp || !menuTaxDetails ? (
          <div className={classes.progressWrapper}>
            <div className={classes.progress}>
              <AppLoadingCircularProgress />
            </div>
          </div>
        ) : null}
        <Grid container className={classes.container}>
          {isProductBasedMenusOn && (
            <MenuMessage
              // @ts-ignore
              appId={currentApp.AppId}
              menu={menu}
              // @ts-ignore
              menuId={parseInt(match.params.menuId)}
              onChangesPublished={this.handeMenuReload}
              translate={translate}
            />
          )}
          {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
          {/* <TutorialNotifier onboardingItemId={301} status={'Completed' as OnboardingItemUpdate} /> */}
          <Hidden lgDown>
            <Grid item xs={12} style={{ marginTop: '-4px' }} className={classes.gridItem}>
              <Divider />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12} className={classes.menuHeader}>
              {revisionMode ? (
                <Checkpoints toggleRevisionMode={this.toggleRevisionMode} />
              ) : (
                <EditControls
                  toggleRevisionMode={this.toggleRevisionMode}
                  toggleMenuSectionBehaviour={this.toggleMenuSectionBehaviour}
                  toggleEditLock={this.toggleEditLock}
                  toggleHideHidden={this.toggleHideHidden}
                  handleExpand={this.handleExpand}
                  editLock={editLock}
                  hideHidden={hideHidden}
                  expanded={expanded}
                />
              )}
            </Grid>
          </Hidden>
          <Hidden smDown lgUp>
            <Grid sm={6}>
              <FirstColumn
                toggleMenuSectionBehaviour={this.toggleMenuSectionBehaviour}
                toggleHideHidden={this.toggleHideHidden}
                handleExpand={this.handleExpand}
                hideHidden={hideHidden}
                expanded={expanded}
              />
            </Grid>
            <Grid sm={6}>
              <SecondColumn toggleEditLock={this.toggleEditLock} editLock={editLock} />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={9}
            className={clsx(classes.gridItem, classes.menuHolder)}
          >
            {menuLoading || menuCheckpointsTimestamp ? <AppLoadingCircularProgress /> : null}
            <Hidden lgUp>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </Hidden>
            <div
              id="flipdish-menu"
              className={clsx(
                classes.flipdishMenu,
                menuLoading || menuCheckpointsTimestamp ? classes.flipdishMenuHidden : null
              )}
              data-branch={constants.GIT_BRANCH}
              data-full-screen-on-mobile="false"
              data-language={language || 'en'}
              data-locked={editLock}
              data-menuid={match.params.menuId}
              data-hideNextOptionSetButtonOnSkip={isHideNextOptionSetButtonOnSkipOn}
              data-depositReturnFee={showDepositReturnFee}
              data-permissions={permissions ? JSON.stringify(permissions) : null}
              data-productBasedMenus={isProductBasedMenusOn}
              data-restaurant={currentApp.AppId}
              data-scrollable-container={SCROLLABLE_CONTAINER}
              data-server={getApiEndPoint()}
              data-taxrates={
                menuTaxDetails && menuTaxDetails.TaxRates
                  ? JSON.stringify(menuTaxDetails.TaxRates)
                  : null
              }
              data-version-one="true"
            />
          </Grid>
          <Hidden lgDown>
            <Grid item lg={3} className={classes.gridItem}>
              <AutoAffix viewportOffsetTop={88} container={parentComponent}>
                <div>
                  {revisionMode ? (
                    <Checkpoints toggleRevisionMode={this.toggleRevisionMode} />
                  ) : (
                    <EditControls
                      toggleMenuSectionBehaviour={this.toggleMenuSectionBehaviour}
                      toggleRevisionMode={this.toggleRevisionMode}
                      toggleEditLock={this.toggleEditLock}
                      toggleHideHidden={this.toggleHideHidden}
                      handleExpand={this.handleExpand}
                      editLock={editLock}
                      hideHidden={hideHidden}
                      expanded={expanded}
                    />
                  )}
                </div>
              </AutoAffix>
            </Grid>
          </Hidden>
        </Grid>
      </PageLayout>
    );
  }

  private loadMenu = () => {
    const embeddedScriptUrl = constants.WEB_MENU_EDITOR_MAIN_SCRIPT_URL;
    const embeddedScript = document.querySelector(`script[src="${embeddedScriptUrl}"]`);

    if (this.state.menuLoading || embeddedScript) {
      return;
    }

    this.setState({ menuLoading: true }, () => {
      this.script = document.createElement('script');
      this.script.type = 'text/javascript';

      if (this.script.readyState) {
        // only required for IE <9
        this.script.onreadystatechange = () => {
          if (this.script.readyState === 'loaded' || this.script.readyState === 'complete') {
            this.script.onreadystatechange = null;
            this.setState({ menuLoading: false });
          }
        };
      } else {
        // Others
        this.script.onload = () => {
          this.setState({ menuLoading: false });
        };
      }

      this.script.onerror = (e) => {
        // TODO: show UI error
        console.error('Failed to load script', e);
        this.setState({ menuLoading: false });
      };

      this.script.src = embeddedScriptUrl;
      document.body.appendChild(this.script);
    });
  };

  private cleanUp() {
    const w = window as any;
    if (
      w.menuComponent &&
      w.menuComponent.clearFlipdishStore &&
      typeof w.menuComponent.clearFlipdishStore === 'function'
    ) {
      w.menuComponent.clearFlipdishStore();
    } else if (
      w.configureComponent &&
      w.configureComponent.clearFlipdishStore &&
      typeof w.configureComponent.clearFlipdishStore === 'function'
    ) {
      w.configureComponent.clearFlipdishStore();
    }

    if (this.script) {
      document.body.removeChild(this.script);
      this.script = null;
    }

    const addedScripts = document.querySelectorAll(
      `script[src^="${constants.WEB_MENU_EDITOR_BASE_URL}"]`
    );
    Array.from(addedScripts).forEach((addedScript) => {
      try {
        document.head.removeChild(addedScript);
      } catch (e) {
        console.log(e);
      }
    });

    const addedStyles = document.querySelectorAll(
      `link[href^="${constants.WEB_MENU_EDITOR_BASE_URL}"]`
    );
    Array.from(addedStyles).forEach((addedStyle) => {
      try {
        document.head.removeChild(addedStyle);
      } catch (e) {
        console.log(e);
      }
    });

    const addedMuiStyles = document.querySelectorAll('style');
    Array.from(addedMuiStyles).forEach((addedMuiStyle) => {
      if (addedMuiStyle.innerHTML.includes('flipdish-web-order')) {
        try {
          document.head.removeChild(addedMuiStyle);
        } catch (e) {
          console.log(e);
        }
      }
    });
  }
}

function mapStateToProps(state: AppState) {
  const { locale, currentApp, menus, permissions, account } = state;
  return {
    currentApp,
    isHideNextOptionSetButtonOnSkipOn: flagService.isFlagOn(state, 'hideNextOptionSetButtonOnSkip'),
    isProductBasedMenusOn: isProductBasedMenusEnabled(state),
    language: account.Language,
    menu: menus.menu,
    menuCheckpointsTimestamp: menus.menuCheckpointsTimestamp,
    menuStores: menus.menuStores,
    menuTaxDetails: menus.menuTaxDetails,
    menuTimestamp: menus.menuTimestamp,
    permissions,
    showBannerAlertForIntegratedMenus: flagService.isFlagOn(
      state,
      'showBannerAlertForIntegratedMenus'
    ),
    showAllMenusInEditor: flagService.isFlagOn(state, 'showAllMenusInEditor'),
    translate: getTranslate(locale),
    getIntegrationMetaData: getIntegrationMetaData(state),
  };
}

const EnhancedComponent = compose<{}, IMenuProps>(
  withRouter,
  withStyles(styles, {
    name: 'Menu',
    withTheme: true,
  }),
  connect(mapStateToProps)
)(Menu);

export { EnhancedComponent as Menu };
