import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import Permissions from 'react-redux-permissions';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import CSVRoute from '../../ui/GenericTable/CSVRoute';
import LookerDashboard from './components/LookerDashboard';
import Dashboards from './Dashboards/Dashboards';
import EndOfDay from './EndOfDay/EndOfDay';
import { downloadReportingCSV } from './OrderReport.actions';
import OrdersReport from './OrdersReport/OrdersReport';
import Overview from './Overview/ReportsOverview';

type RouteParams = {
  appId?: string;
};
type Props = RouteComponentProps<RouteParams>;

const ReportRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    match: { path, params },
  } = props;

  return (
    <ErrorBoundary identifier="reports">
      <Switch>
        <Route path={`${path}/endofday`} component={EndOfDay} />
        <Permissions allowed={[App.AppResourceSetEnum.ViewSalesStatistics]}>
          <Switch>
            <Route exact path={`${path}/overview`} component={Overview} />
            <CSVRoute
              downloadCSVAction={downloadReportingCSV}
              path={`${path}/orders/csvexport/:guid`}
              redirectURL={'/reports/orders?daterange=last30Days&comparewith=previousperiod'}
            />
            <Route exact path={`${path}/orders`} component={OrdersReport} />
            <Route
              exact
              path={`${path}/dashboards/global/:id`}
              render={() => <LookerDashboard isGlobal={true} />}
            />
            <Route exact path={`${path}/dashboards/:id`} component={LookerDashboard} />
            <Route exact path={`${path}/dashboards`} component={Dashboards} />
            <Redirect
              exact
              from={`/${params?.appId}/reports`}
              to={`/${params?.appId}/reports/dashboards`}
            />
          </Switch>
        </Permissions>
      </Switch>
    </ErrorBoundary>
  );
};

export default ReportRoutes;
