import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Form, FormikProps, withFormik } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Button, Typography } from '@fd/ui/atoms';
import { Dialog, FormikInputField } from '@fd/ui/molecules';

import { storeGroupActions } from '../../../../actions/storegroup.actions';
import * as storeGroupConstants from '../../../../constants/storegroup.constants';
import { LoadingButton } from '../../../../ui/LoadingButton';
import StoreGroupCurrencyFilter from '../../Filters/StoreGroupCurrencyFilter';

const MAX_NAME_LENGTH = 500;

const useStyles = makeStyles((theme: Theme) => ({
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
  //TODO: refactor loading button to utilize existing button and styles
  createButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: '#18145c',
    },
    '&:disabled': {
      color: theme.palette.grey[800],
      backgroundColor: theme.palette.primary.contrastText,
    },
  },
  formField: {
    minHeight: '5rem',
  },
  moreLink: {
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  storeGroupContent: {
    maxHeight: '410px',
    maxWidth: '455px',
  },
}));

type CreateStoreGroupFormValues = {
  Currency: string;
  Name: string;
};

export type CreateStoreGroupDialogProps = {
  open: boolean;
  handleClose: () => void;
  onStoreGroupCreated: (newStoreGroupId: number) => void;
};

type Props = CreateStoreGroupDialogProps &
  FormikProps<CreateStoreGroupFormValues> &
  MappedDispatch &
  MappedState;
const CreateStoreGroupDialog = (props: Props) => {
  const {
    createdStoreGroupId,
    dirty,
    handleClose,
    handleSubmit,
    isValid,
    isSubmitting,
    onStoreGroupCreated,
    open,
    setFieldValue,
    translate,
  } = props;
  const classes = useStyles();

  const handleCurrencyChange = (value) => {
    setFieldValue('Currency', value);
  };

  useEffect(() => {
    if (open && createdStoreGroupId !== undefined) {
      onStoreGroupCreated(createdStoreGroupId);
    }
  }, [createdStoreGroupId]);

  return (
    <Dialog
      title={translate('Create_new_store_group') as string}
      open={open}
      actions={[
        <Button
          className={classes.cancelButton}
          disabled={isSubmitting}
          fdKey="Cancel_create_store_group"
          key="Cancel_create_store_group"
          onClick={handleClose}
          variant="text"
        >
          <Translate id="Cancel" />
        </Button>,
        <LoadingButton
          key="Create_store_group"
          disabled={!isValid || !dirty}
          buttonProps={{
            className: classes.createButton,
            onClick: () => handleSubmit(),
            'data-fd': 'Create_store_group',
          }}
          loadingResources={[storeGroupConstants.STORE_GROUP_CREATE]}
        >
          <Translate id="Create" />
        </LoadingButton>,
      ]}
    >
      <Grid className={classes.storeGroupContent} container spacing={2} direction={'column'}>
        <Grid item>
          <Typography variant="caption">
            <Translate id={'Store_groups_are_used_to_apply_different_cuisine'} />
          </Typography>
        </Grid>

        <Grid item>
          <Form id="create-store-group-form">
            <FormikInputField
              autoFocus
              className={classes.formField}
              fdKey="store-group-name-field"
              fullWidth
              inputProps={{ 'data-fd': 'Store_group_name' }}
              label={translate('Store_group_name') as string}
              name="Name"
            />
            <Box mb={1}>
              <StoreGroupCurrencyFilter onChange={handleCurrencyChange} />
            </Box>
          </Form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { currentApp, storeGroups } = state;
  return {
    createdStoreGroupId:
      (storeGroups.create &&
        storeGroups.create.storeGroup &&
        storeGroups.create.storeGroup.StoreGroupId) ||
      undefined,
    currentApp,
    translate: getTranslate(state.locale),
  };
}

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  createStoreGroup: (appId: string, values) => dispatch(storeGroupActions.create(appId, values)),
});

const EnhancedComponent = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik<Props, CreateStoreGroupFormValues>({
    displayName: 'CreateStoreGroupForm',
    mapPropsToValues: () => {
      return {
        Name: '',
        Currency: 'EUR',
        new: '',
      };
    },
    handleSubmit: async (values, { props, setSubmitting }) => {
      const { currentApp, createStoreGroup } = props;
      try {
        await createStoreGroup(currentApp.AppId as string, values);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
    validate: ({ Name }, { translate }) => {
      const errors: Partial<any> = {};
      if (Name === '') {
        errors.Name = translate('Required') as string;
      } else if (Name.length > MAX_NAME_LENGTH) {
        errors.Name = translate('Max_fieldname_length_exceeded', {
          fieldName: '',
          max: MAX_NAME_LENGTH,
        }) as string;
      }
      return errors;
    },
  })
)(CreateStoreGroupDialog);

export { EnhancedComponent as CreateStoreGroupDialog };
