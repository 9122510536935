import React from 'react';

import Imgix from 'react-imgix';

interface ImgixImageProps {
  src: string;
  alt?: string;
  className?: string;
  sizes?: string;
  imgixParams?: {
    [key: string]: string | number | boolean;
  };
  width?: number;
  height?: number;
}

export const ImgixImage: React.FC<ImgixImageProps> = ({
  src,
  alt = '',
  className,
  sizes = '100vw',
  imgixParams = {},
  width,
  height,
}) => {
  const DEFAULT_LOGO_URL = 'https://flipdish.imgix.net/gddSky3brWXB4bHWcfax5M130.jpg';

  return (
    <Imgix
      src={src || DEFAULT_LOGO_URL}
      alt={alt}
      className={className}
      sizes={sizes}
      width={width}
      height={height}
      imgixParams={{
        auto: 'format,compress',
        fit: 'crop',
        ...imgixParams,
      }}
    />
  );
};
