import React, { useEffect } from 'react';

import useQueryPropertiesByOrgIdHook from '@fd/customHooks/useQueryPropertiesByOrgIdHook';
import { OpeningHour } from '@flipdish/orgmanagement';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import DynamicAlertBanner from '@fd/ui/molecules/DynamicAlertBanner/DynamicAlertBanner';

import { notifyError, NotifyProps, notifySaved } from '../../layouts/Notify/actions';
import OpeningHoursForm from '../OpeningHoursV2/OpeningHoursForm';
import propertiesService, { getPropertyOpeningHoursKey } from './properties.services';

const StyledDynamicAlertBanner = styled(DynamicAlertBanner)(({ theme }) => ({
  marginTop: `-${theme.spacing(1)}!important`,
}));

type Props = MappedState & MappedDispatch;
const PropertyOpeningHoursPage = ({
  orgId,
  translate,
  dispatchNotifySuccess,
  dispatchNotifyError,
}: Props) => {
  const queryClient = useQueryClient();
  const { propertyId } = useParams<{ propertyId: string }>();

  const {
    data: openingHours,
    isLoading,
    error,
  } = useQuery({
    queryKey: [getPropertyOpeningHoursKey, orgId, propertyId],
    queryFn: () => propertiesService.getPropertyOpeningHours(orgId, propertyId),
  });

  useEffect(() => {
    if (error) {
      dispatchNotifyError({
        message: error.message,
        translate: false,
      });
    }
  }, [error]);
  const { isPending: isSaving, mutateAsync: updateOpeningHours } = useMutation({
    mutationFn: async ({ hours }: { hours: OpeningHour[] }) => {
      await propertiesService.updatePropertyOpeningHours(orgId, propertyId, hours);
    },
    onSuccess: () => {
      dispatchNotifySuccess();
      queryClient.invalidateQueries({ queryKey: [getPropertyOpeningHoursKey] });
    },
    onError: (error) => {
      dispatchNotifyError({
        message: error.message,
        translate: false,
      });
    },
  });

  const { properties } = useQueryPropertiesByOrgIdHook(orgId);

  const property = properties?.find((property) => property?.propertyId === propertyId) || null;
  const propertyName = property?.name ?? '';

  return (
    <PageLayout toParent={'../'} caption={propertyName} title={translate('Opening_hours_title')}>
      <StyledDynamicAlertBanner
        backgroundColor="blue"
        icon="info"
        bannerText={'Property_opening_hours_banner'}
        showButton={false}
      />
      <OpeningHoursForm
        openingHours={openingHours?.data?.data || []}
        isLoading={isLoading}
        isSaving={isSaving}
        updateOpeningHours={updateOpeningHours}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  orgId: state.currentApp?.OrgId ?? '',
  translate: getTranslate(state),
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifySuccess: () => dispatch(notifySaved()),
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyOpeningHoursPage);
