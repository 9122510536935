import React, { lazy } from 'react';

import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import { styled } from '@mui/material/styles';

import LazyComponent from '@fd/ui/LazyComponent';

// Lazy load MUI icons
const WebsiteAndAppIcon = lazy(() => import('@mui/icons-material/PhonelinkOutlined'));
const StayCurrentPortraitOutlinedIcon = lazy(
  () => import('@mui/icons-material/StayCurrentPortraitOutlined')
);
const TvOutlinedIcon = lazy(() => import('@mui/icons-material/TvOutlined'));

// Lazy load SVG logos
const DeliverooLogo = lazy(() =>
  import('../../../assets/images/deliveroo-filled.svg').then((module) => ({
    default: module.ReactComponent,
  }))
);
const JustEatLogo = lazy(() =>
  import('../../../assets/images/just-eat-filled.svg').then((module) => ({
    default: module.ReactComponent,
  }))
);
const UberEatsLogo = lazy(() =>
  import('../../../assets/images/uber-eats-filled.svg').then((module) => ({
    default: module.ReactComponent,
  }))
);

// Lazy load Kiosk icon
const KioskIcon = lazy(() =>
  import('../../Kiosks/Components/KioskNestedMenuIcon').then((module) => ({
    default: module.KioskIcon,
  }))
);

const StyledStayCurrentPortraitOutlinedIcon = styled(StayCurrentPortraitOutlinedIcon)(
  ({ theme }) => ({
    fill: theme.palette.action.active,
    width: '35px',
    height: '35px',
  })
);

const StyledTvOutlinedIcon = styled(TvOutlinedIcon)(({ theme }) => ({
  svg: {
    fill: theme.palette.action.active,
  },
  fill: theme.palette.action.active,
  width: '35px',
  height: '35px',
}));

const StyledWebsiteAndAppIcon = styled(WebsiteAndAppIcon)(({ theme }) => ({
  fill: theme.palette.action.active,
  width: '35px',
  height: '35px',
}));

const StyledKioskIcon = styled(KioskIcon)(() => ({
  img: {
    width: '35px!important',
    height: '35px!important',
  },
}));

const SalesChannelIcon = ({ type }) => {
  switch (type) {
    case SalesChannelType.KIOSK:
    case SalesChannelType.FLIPDISH_KIOSK:
      return (
        <LazyComponent>
          <StyledKioskIcon width={'35px'} />
        </LazyComponent>
      );
    case SalesChannelType.JUST_EATS:
      return (
        <LazyComponent>
          <JustEatLogo />
        </LazyComponent>
      );
    case SalesChannelType.UBER_EATS:
      return (
        <LazyComponent>
          <UberEatsLogo />
        </LazyComponent>
      );
    case SalesChannelType.DELIVEROO:
      return (
        <LazyComponent>
          <DeliverooLogo />
        </LazyComponent>
      );
    case SalesChannelType.MOBILE_APP:
    case SalesChannelType.FLIPDISH_MOBILE_APP:
      return (
        <LazyComponent>
          <StyledStayCurrentPortraitOutlinedIcon />
        </LazyComponent>
      );
    case SalesChannelType.WEB_APP:
    case SalesChannelType.FLIPDISH_WEB_APP:
      return (
        <LazyComponent>
          <StyledWebsiteAndAppIcon />
        </LazyComponent>
      );
    default:
      return (
        <LazyComponent>
          <StyledTvOutlinedIcon />
        </LazyComponent>
      );
  }
};

export default SalesChannelIcon;
