import { UPDATE } from '../../constants/storeOpeningHours.constants';
import { createLoadingSelector } from '../../selectors/loading.selector';
import { DeliveryType } from './types';

export const getIsUpdating = createLoadingSelector([UPDATE]);

export const getOpeningHoursByDeliveryType = (
  state: AppState,
  deliveryType: DeliveryType,
  storeId: number
) => {
  return state.openingHours.data[storeId]?.[deliveryType] || null;
};
