import React from 'react';

import { Org } from '@flipdish/orgmanagement';
import { styled } from '@mui/material/styles';
import { TranslateFunction } from 'react-localize-redux';

import Tag from '@fd/ui/atoms/TagV2';

const StyledSecondaryContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const SecondaryText = ({ org, translate }: { org: Org; translate: TranslateFunction }) => {
  if (org?.isArchived) {
    return (
      <StyledSecondaryContainer>
        {org.orgId}
        <Tag ownerState={{ backgroundColor: '#E0E0E0' }}>{translate('Archived')}</Tag>
      </StyledSecondaryContainer>
    );
  }
  return <>{org?.orgId || ''}</>;
};

export default SecondaryText;
