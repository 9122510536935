import React from 'react';

import { useFormikContext } from 'formik';

import { DailyOpeningHoursTimeStrings } from '../utils';
import CopyButton from './CopyButton';

const CopyToDeliveryHoursButton = ({
  weekDays,
  disabled,
}: {
  weekDays: {
    key: string;
    val: string;
  }[];
  disabled?: boolean;
}) => {
  const { values, setFieldValue } = useFormikContext<{
    openingHours: DailyOpeningHoursTimeStrings;
  }>();
  const copyCollectionOpeningHoursToDeliveryHours = () => {
    weekDays.forEach(({ key }) => {
      const collectionOpeningHours = values.openingHours.Collection[key];
      setFieldValue(`openingHours.Delivery.${key}`, collectionOpeningHours);
    });
  };
  return (
    <CopyButton
      onClick={copyCollectionOpeningHoursToDeliveryHours}
      label="Copy_to_delivery_hours"
      disabled={disabled}
    />
  );
};

export default CopyToDeliveryHoursButton;
