import { DeliveryZone } from '@flipdish/api-client-typescript';
import { createSelector } from 'reselect';

import { createOrmSelector } from './selector';

export const createDeliveryZonesSelector = (storeId: number | string) =>
  createOrmSelector<DeliveryZone[]>((session) => {
    const zones =
      session.StoreDeliveryZone?.all()
        .filter((zone) => {
          const zoneFlipdishStore = zone.FlipdishStore?.toString();
          return zoneFlipdishStore === storeId;
        })
        .toModelArray()
        .map((zone) => zone.ref) || [];

    return zones;
  });

export const createDeliveryZoneByIdSelector = (deliveryZoneId: number | string) =>
  createOrmSelector<DeliveryZone>((session) => {
    const deliveryZone = session.StoreDeliveryZone.withId(deliveryZoneId.toString());
    return deliveryZone ? deliveryZone.ref : undefined;
  });

export const createDeliveryZoneIdsSelector = (storeId: number | string) => {
  const zonesSelector = createDeliveryZonesSelector(storeId.toString());

  return createSelector([zonesSelector], (zones): number[] => {
    return zones.map((zone) => zone.Id as number);
  });
};
