import React, { useCallback, useEffect, useRef, useState } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import {
  SalesChannelType,
  useQueryRMSSalesChannels,
} from '@fd/customHooks/useQueryRMSSalesChannels';
import { App, DeliveryZone, Store, StoreBase } from '@flipdish/api-client-typescript';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, useHistory } from 'react-router';

import { EnableSwitch, Typography } from '@fd/ui/atoms';
import { FormSection } from '@fd/ui/molecules';
import DynamicAlertBanner from '@fd/ui/molecules/DynamicAlertBanner/DynamicAlertBanner';

import * as deliveryZonesActions from '../../../actions/deliveryzones.actions';
import { storeActions } from '../../../actions/store.actions';
import * as storeConstants from '../../../constants/store.constants';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { appMapCenterSelector } from '../../../selectors/app.selector';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../../selectors/loading.selector';
import { createStoreMapCenterSelector, getSelectedStore } from '../../../selectors/store.selector';
import { createDeliveryZonesSelector } from '../../../selectors/storeDeliveryZone.selector';
import { createStoreGroupSelectorById } from '../../../selectors/storegroup.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import { flagService } from '../../../services/flagService';
import { logger } from '../../../services/loggerService';
import * as storeDeliveryZoneService from '../../../services/storeDeliveryZone.service';
import { StoreEvents } from '../../../signalr/hub.actions';
import Button from '../../../ui/Button/Button';
import PageLayout from '../../../ui/Layout';
import { appStoreService } from '../../AppStore/services/appstore.service';
import DeliveryZones from '../../DeliveryZones/DeliveryZones';
import { getStoreIdAssociations } from '../../RMS/rms.services';
import { LeadTimeFormField } from '../components/LeadTimeFormField';
import { useLeadTime } from '../hooks/useLeadTime';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: theme.spacing(4),
  },
  description: {
    whiteSpace: 'pre-line',
  },
  descriptionContainer: {
    marginBottom: theme.spacing(3),
  },

  switch: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 0,
    maxWidth: '70%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  copyButton: {
    marginBottom: theme.spacing(2),
  },
  dialogContent: {
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(1),
  },
}));

type DeliverySettingsProps = RouteComponentProps<{
  storeId: string;
  appId: string;
  storeGroupId: string;
  channelId: string;
  propertyId: string;
  type: string;
}> &
  MappedState &
  MappedDispatch & {
    selectedPropertyId: string;
    orgId?: string;
  };

const DeliverySettings = (props: DeliverySettingsProps) => {
  const classes = useStyles();
  const {
    add,
    canEdit,
    deliveryZones,
    dispose,
    getStoreLeadTimes,
    initLoad,
    isLeadTimeEnabled,
    isLoading,
    isSmallScreen,
    leadTimes,
    loadAll,
    loadDependencies,
    location,
    match,
    onChecked,
    orgId,
    remove,
    select,
    selectedPropertyId,
    store,
    storeGroupCurrency,
    storeMapCenter,
    storeName,
    toggleEvents,
    translate,
    update,
    updateStore,
    updateStoreLeadTimes,
    zones,
  } = props;

  const isMounted = useRef(false);
  const history = useHistory();
  const storeGroupId = store?.StoreGroupId;

  const { storeId: storeIdFromParams } = useLoadStoreFromSalesChannelIdIntoRedux({ store });
  const storeId = store?.StoreId || storeIdFromParams;
  const { data: leadTime } = useLeadTime(
    getStoreLeadTimes,
    'delivery',
    Number(storeId),
    leadTimes,
    !!(isLeadTimeEnabled && storeId)
  );

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_deliverySettings', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (storeId && storeGroupId && match.params.appId) {
      loadAll(match.params.appId, Number(storeGroupId), storeId, loadDependencies);
    }
  }, [storeId, storeGroupId, match.params.appId]);

  useEffect(() => {
    toggleEvents(true);
    return () => {
      toggleEvents(false);
      dispose();
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const newUrl = match.url.replace(/storegroups\/.*/, 'storegroups');
      history.replace(newUrl, location.state);
    } else {
      isMounted.current = true;
    }
  }, [match.params.appId]);

  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace('/deliverysettings', '');
    history.push(parent);
  }, []);

  const { data: storeDeliveryZonesManaged } = useQuery({
    enabled: !!storeId,
    queryKey: [storeId, match.params.appId, 'integratedDeliverySettings'],
    queryFn: async () => {
      const integrationManagedDeliveryZonesAppStoreAppIds = [
        'app_556380326994116608', // Uber
        'app_364255298539487232', // Glovo
      ];

      const promises = integrationManagedDeliveryZonesAppStoreAppIds.map((appStoreAppid) =>
        appStoreService.getAppStoreAppConfigsById(match.params.appId as string, appStoreAppid)
      );
      const responses = await Promise.all(promises);
      const integratedStoreIds = responses
        .map((response) =>
          response.Data.filter((config) => config.IsEnabled)
            .map((config) => config.Stores)
            .flat()
        )
        .flat()
        .map((store) => store.StoreId);

      return integratedStoreIds.includes(Number(storeId));
    },
  });

  const disableFormFields = deliveryZones?.length === 0 || !deliveryZones;

  const isPageLoading = isLoading || !initLoad || (isLeadTimeEnabled && !leadTimes);

  const isFormFieldDisabled = !canEdit || disableFormFields;

  const storeDeliveryZoneHavingFeesButNoIntegration =
    (zones as DeliveryZone[]).some((zone) => (zone.FeeChargedToStore ?? 0) > 0) &&
    !storeDeliveryZonesManaged;

  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const [sourceStoreId, setSourceStoreId] = useState<string>('');
  const [isCopyingZones, setIsCopyingZones] = useState(false);

  const handleCopyZones = async (): Promise<void> => {
    if (!sourceStoreId || !storeId || !storeGroupId) {
      return;
    }

    try {
      setIsCopyingZones(true);

      // Get the Flipdish store ID from the sales channel ID
      const storeIdResponse = await getStoreIdAssociations(sourceStoreId);
      const resolvedStoreId = Number(storeIdResponse.data.flipdish.storeId);

      // Fetch zones directly from API without affecting Redux state
      const sourceZones = await storeDeliveryZoneService.loadAll(resolvedStoreId);

      // Remove existing zones
      if (zones.length > 0) {
        const removePromises = zones.map(
          (zone) =>
            new Promise<void>((resolve) => {
              remove(storeId.toString(), zone.Id, false);
              resolve();
            })
        );
        await Promise.all(removePromises);
      }

      // Add new zones
      let currentTmpId = -1;
      sourceZones.forEach((zone) => {
        const newZone = {
          DeliveryFee: zone.DeliveryFee,
          MinimumDeliveryOrderAmount: zone.MinimumDeliveryOrderAmount,
          IsEnabled: true,
          WellKnownText: zone.WellKnownText,
          MapCenter: storeMapCenter,
        };

        currentTmpId--;
        add(storeId.toString(), newZone, currentTmpId);
      });

      setCopyDialogOpen(false);
      setSourceStoreId('');
    } catch (error) {
      logger.error('Failed to copy delivery zones:', error);
    } finally {
      setIsCopyingZones(false);
    }
  };

  const {
    data: salesChannels,
    isLoading: loadingSalesChannels,
    error: salesChannelsError,
  } = useQueryRMSSalesChannels({
    orgId: orgId || '',
    propertyId: selectedPropertyId || '',
    filterByType: [SalesChannelType.FLIPDISH_WEB_APP, SalesChannelType.FLIPDISH_MOBILE_APP],
  });

  const filteredSalesChannels = copyDialogOpen
    ? salesChannels?.filter((channel) => channel.salesChannelId !== match.params.channelId)
    : undefined;

  const renderSalesChannelMenuItems = () => {
    if (salesChannelsError) {
      return (
        <MenuItem disabled>
          <Translate id="Error_loading_sales_channels" />
        </MenuItem>
      );
    }

    if (loadingSalesChannels) {
      return (
        <MenuItem disabled>
          <Translate id="Loading_sales_channels" />
        </MenuItem>
      );
    }

    if (!filteredSalesChannels?.length) {
      return (
        <MenuItem disabled>
          <Translate id="No_sales_channels_available" />
        </MenuItem>
      );
    }

    return filteredSalesChannels
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
      .map((channel) => (
        <MenuItem key={channel.salesChannelId} value={channel.salesChannelId}>
          {channel.name}
        </MenuItem>
      ));
  };

  return (
    <PageLayout
      toParent={backToParent}
      caption={(store && store.Name) || ''}
      documentTitle="Delivery_settings_title"
      title={<Translate id="Delivery_settings_title" />}
    >
      <>
        {orgId && selectedPropertyId && (
          <Button
            variant="text"
            color="primary"
            className={classes.copyButton}
            fdKey="open-copy-zones"
            onClick={() => setCopyDialogOpen(true)}
            disabled={!canEdit}
          >
            <Translate id="Copy_delivery_zones" />
          </Button>
        )}

        <Dialog open={copyDialogOpen} onClose={() => setCopyDialogOpen(false)}>
          <DialogTitle>
            <Translate id="Copy_delivery_zones_from_sales_channel" />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="source-store-label">
                <Translate id="Select_sales_channel" />
              </InputLabel>
              <Select
                labelId="source-store-label"
                fullWidth
                value={sourceStoreId}
                onChange={(e) => setSourceStoreId(e.target.value)}
                label={translate('Select_sales_channel_to_copy_from')}
              >
                {renderSalesChannelMenuItems()}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              fdKey="cancel-copy-zones"
              onClick={() => setCopyDialogOpen(false)}
              disabled={isCopyingZones}
            >
              <Translate id="Cancel" />
            </Button>
            <Button
              fdKey="confirm-copy-zones"
              onClick={handleCopyZones}
              color="primary"
              disabled={isCopyingZones}
            >
              {isCopyingZones ? <Translate id="Copying_zones" /> : <Translate id="Copy" />}
            </Button>
          </DialogActions>
        </Dialog>

        <ErrorBoundary identifier="delivery_zones">
          {storeDeliveryZonesManaged && (
            <DynamicAlertBanner
              backgroundColor="yellow"
              bannerText={'Store_delivery_zones_integration_managed_warning_message'}
              showButton={false}
            />
          )}
          {storeDeliveryZoneHavingFeesButNoIntegration && (
            <DynamicAlertBanner
              backgroundColor="yellow"
              bannerText={'Store_delivery_zones_integration_restaurant_fees_warning_message'}
              showButton={false}
            />
          )}
          <DeliveryZones
            add={add}
            initLoad={initLoad}
            isSmallScreen={isSmallScreen}
            remove={remove}
            select={select}
            storeGroupCurrency={storeGroupCurrency}
            storeMapCenter={storeMapCenter}
            storeName={storeName}
            update={update}
            zones={zones}
            storeId={Number(storeId)}
          />
        </ErrorBoundary>
        <Paper className={classes.container}>
          <FormSection
            isLoading={isPageLoading}
            sectionTitle={translate('Enable_delivery') as string}
          >
            <FormControlLabel
              label={undefined}
              className={classes.switch}
              control={
                <EnableSwitch
                  name="DeliveryEnabled"
                  checked={store ? store.DeliveryEnabled : false}
                  onChange={(e) => store && onChecked(e, store, updateStore)}
                  fdKey="delivery_enabled"
                  disabled={isFormFieldDisabled}
                />
              }
            />
          </FormSection>
          <FormSection
            isLoading={isPageLoading}
            sectionTitle={translate('Require_customer_name') as string}
          >
            <div className={classes.descriptionContainer}>
              <Typography className={classes.description} variant="caption">
                {translate('Customer_name_requirements_description')}
              </Typography>
            </div>
            <FormControlLabel
              label={undefined}
              className={classes.switch}
              control={
                <EnableSwitch
                  name="RequireCustomerNameForDelivery"
                  checked={store ? store.RequireCustomerNameForDelivery : false}
                  onChange={(e) => store && onChecked(e, store, updateStore)}
                  fdKey="require_customer_name_for_delivery_orders"
                  disabled={isFormFieldDisabled}
                />
              }
            />
          </FormSection>
          <FormSection
            isLoading={isPageLoading}
            sectionTitle={translate('Accept_cash_for_delivery_orders') as string}
          >
            <FormControlLabel
              label={undefined}
              className={classes.switch}
              control={
                <EnableSwitch
                  name="CashOrdersDeliveryEnabled"
                  checked={store ? store.CashOrdersDeliveryEnabled : false}
                  onChange={(e) => store && onChecked(e, store, updateStore)}
                  fdKey="cash_orders_delivery_enabled"
                  disabled={isFormFieldDisabled}
                />
              }
            />
          </FormSection>
          {isLeadTimeEnabled && (
            <LeadTimeFormField
              description={translate('Auto_accept_lead_time_description') as string}
              isDisabled={isFormFieldDisabled}
              isLoading={isPageLoading}
              onBlur={(newValue) => updateStoreLeadTimes(Number(storeId), newValue)}
              translate={translate}
              value={leadTime}
            />
          )}

          <FormSection
            isLoading={isPageLoading}
            sectionTitle={translate('SMS_messaging') as string}
            showDivider={false}
          >
            <div className={classes.descriptionContainer}>
              <Typography className={classes.description} variant="caption">
                {translate('Include_estimated_time_in_the_SMS')}
              </Typography>
            </div>
            <FormControlLabel
              label={undefined}
              className={classes.switch}
              control={
                <EnableSwitch
                  name="EtaInDeliveryConfirmationSmsEnabled"
                  checked={store ? store.EtaInDeliveryConfirmationSmsEnabled : false}
                  onChange={(e) => store && onChecked(e, store, updateStore)}
                  fdKey="include_estimated_delivery_time_in_sms"
                  disabled={isFormFieldDisabled}
                />
              }
            />
          </FormSection>
        </Paper>
      </>
    </PageLayout>
  );
};

type updateStore = (storeId: number, store: StoreBase) => (dispatch: any) => void;

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  const deliveryZoneUpdateAction = deliveryZonesActions.update(true);

  return {
    loadAll: (appId, storeGroupId, storeId, loadDependencies = false) => {
      dispatch(deliveryZonesActions.loadAll(appId, storeGroupId, storeId, loadDependencies));
    },
    add: (storeId, zone, tmpId) => {
      dispatch(deliveryZonesActions.create(storeId, zone, tmpId));
    },
    update: (storeId, zone, undo) => {
      dispatch(deliveryZoneUpdateAction(storeId, zone.Id, zone, undo));
    },
    remove: (storeId, storeDeliveryZoneId, undo) => {
      dispatch(deliveryZonesActions.remove(storeId, storeDeliveryZoneId, undo));
    },
    select: (deliveryZoneId: number) => {
      dispatch(deliveryZonesActions.select(deliveryZoneId));
    },
    toggleEvents: (toggle: boolean) => {
      if (toggle) {
        dispatch(StoreEvents.Subscribe.DeliveryZoneCreated);
        dispatch(StoreEvents.Subscribe.DeliveryZoneUpdated);
        dispatch(StoreEvents.Subscribe.DeliveryZoneDeleted);
      } else {
        dispatch(StoreEvents.Unsubscribe.DeliveryZoneCreated);
        dispatch(StoreEvents.Unsubscribe.DeliveryZoneUpdated);
        dispatch(StoreEvents.Unsubscribe.DeliveryZoneDeleted);
      }
    },
    dispose: () => {
      dispatch(deliveryZonesActions.reset());
    },
    onChecked: (
      event: React.ChangeEvent<HTMLInputElement>,
      store: Store,
      updateStore: updateStore
    ) => {
      store &&
        store.StoreId &&
        dispatch(
          updateStore(store.StoreId, {
            ...store,

            [event.target.name]:
              event.target.name === 'AutomaticallyAcceptOrders'
                ? !event.target.checked
                : event.target.checked,
          })
        );
    },
    getStoreLeadTimes: (storeId: number) => {
      dispatch(storeActions.getStoreLeadTimes(storeId));
    },
    updateStoreLeadTimes: (storeId: number, leadTime: number) => {
      dispatch(
        storeActions.updateStoreLeadTimes(storeId, {
          DispatchType: 'delivery',
          LeadTimeMinutes: leadTime,
        })
      );
    },
  };
};

type MappedState = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: AppState, ownProps) => {
  const updateFunction = storeActions.update(true);
  const canEdit = state.permissions.some((p) => p === App.AppResourceSetEnum.EditStores.toString());
  const store = getSelectedStore(state);
  const storeId = store?.StoreId;
  const storeGroupId = store?.StoreGroupId;
  const storeGroupSelectorById = createStoreGroupSelectorById(storeGroupId ?? '');
  const zoneSelector = createDeliveryZonesSelector(storeId?.toString() ?? '');
  const storeMapCenterSelector = createStoreMapCenterSelector(storeId ?? '');
  const storeGroup = storeGroupSelectorById(state);
  const zones = zoneSelector(state);
  const storeMapCenter = storeMapCenterSelector(state) || appMapCenterSelector(state);

  return {
    canEdit,
    isLeadTimeEnabled: flagService.isFlagOn(state, 'deliveryLeadTime'),
    isLoading: initialLoadingSelector(state),
    leadTimes: state.stores.leadTimes,
    loadingError: initialLoadingErrorSelector(state),
    selectedApp: state.currentApp,
    store,
    translate: getTranslate(state.locale),
    updateStore: updateFunction,
    deliveryZones: state.orm?.StoreDeliveryZone?.items,
    zones,
    initLoad: state.deliveryZones.initLoad,
    storeGroupCurrency: storeGroup && store ? storeGroup.Currency : null,
    loadDependencies: !(storeGroup && store),
    storeMapCenter,
    storeName: store?.Name ?? '',
    isSmallScreen: ownProps.width === 'xs',
    selectedPropertyId: ownProps.match.params.propertyId || '',
    orgId: state.rms?.currentOrg?.orgId,
  } as const;
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliverySettings);
