import constants from '../constants.json';
import locales from '../localization/locales.json';

const isProduction = constants.GIT_BRANCH === 'master';

export const getSupportedLanguages = (): string[] => {
  const supportedLanguages: string[] = !isProduction
    ? locales.map((lang) => lang.code)
    : locales.filter((lang) => lang.code !== 'dev-lang').map((lang) => lang.code);

  return supportedLanguages;
};
