import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ImgixLogoWrapper } from '@fd/ui/Imgix/ImgixLogoWrapper';

import { ReactComponent as ForkIcon } from '../../../assets/images/app_logo_fork.svg';

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  whiteSpace: 'nowrap',
  span: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: (prop) => prop !== 'isCurrent' && prop !== 'isSelected',
})<{ isCurrent?: boolean; isSelected?: boolean }>(({ isCurrent, isSelected }) => ({
  backgroundColor: isCurrent ? '#eaf2ff' : 'transparent',
  '&:hover': {
    backgroundColor: '#eaf2ff',
  },
  ...(isSelected && {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  }),
}));

export const renderBrandItem =
  ({ currentApp, selectedIndex, filteredBrands, handleChange }) =>
  ({ index, style }) => {
    const brand = filteredBrands?.[index];
    return (
      <StyledMenuItem
        isCurrent={brand?.brandId === currentApp?.AppId}
        isSelected={index === selectedIndex}
        key={brand?.brandId}
        onClick={(event) => handleChange(event, brand)}
        data-fd={`brand-selector-${brand?.brandId}`}
        style={style}
      >
        <ListItemIcon>
          {brand?.logoImageUrl ? (
            <ImgixLogoWrapper src={brand.logoImageUrl} alt={brand?.name} />
          ) : (
            <ForkIcon />
          )}
        </ListItemIcon>
        <StyledListItemText
          primary={<Tooltip title={brand?.name}>{<Typography>{brand.name}</Typography>}</Tooltip>}
          secondary={brand?.brandId}
        />
      </StyledMenuItem>
    );
  };
