import React, { FC } from 'react';

import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Translate } from 'react-localize-redux';

import Switch from '@fd/ui/Switch/Switch';

const StyledContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: '#EEEBE9',
  borderRadius: '4px',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledAdminSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const StyledAdminOnlyText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginLeft: theme.spacing(1),
}));

const StyledSwitchSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

interface PropertiesListAdminOptionsProps {
  showArchived: boolean;
  onShowArchivedChange: (show: boolean) => void;
}

const PropertiesListAdminOptions: FC<PropertiesListAdminOptionsProps> = ({
  showArchived,
  onShowArchivedChange,
}) => {
  return (
    <StyledContainer>
      <StyledAdminSection>
        <SupportAgentOutlinedIcon />
        <StyledAdminOnlyText>
          <Translate id="Admin_Only" />
        </StyledAdminOnlyText>
      </StyledAdminSection>
      <StyledSwitchSection>
        <Typography>
          <Translate id="Show_archived" />
        </Typography>
        <Switch
          edge="end"
          checked={showArchived}
          onChange={(e) => onShowArchivedChange(e.target.checked)}
          inputProps={{
            'aria-labelledby': 'show-archived-toggle',
          }}
          fdKey="show-archived-toggle"
        />
      </StyledSwitchSection>
    </StyledContainer>
  );
};

export default PropertiesListAdminOptions;
