import { useMemo } from 'react';

import { useLocation } from 'react-router';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

/**
 * Hook to safely access URL search parameters
 * @returns A read-only URLSearchParams object
 * @throws Error if URL search string is malformed
 */
export const useSearchParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    try {
      return new URLSearchParams(search) as ReadOnlyURLSearchParams;
    } catch (error) {
      console.error('Failed to parse URL search params:', error);
      // Return empty params instead of throwing to prevent app crashes
      return new URLSearchParams() as ReadOnlyURLSearchParams;
    }
  }, [search]);
};

export default useSearchParams;
