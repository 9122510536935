import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../helpers/strings';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import RMSPage from '../RMS/components/RMSPage';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import DangerZoneList from './components/DangerZoneList';
import PropertyGeneralSettingsPage from './PropertyGeneralSettingsPage';
import PropertyOpeningHoursPage from './PropertyOpeningHoursPage';
import StaffSettingsListPage from './StaffSettingsListPage';

type RouteParams = {
  appId?: string;
  propertyId?: string;
};

type Props = RouteComponentProps<RouteParams>;

const PropertySettingsRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path, params },
  } = props;
  const toParentFromSettingsIframe = `/${params?.appId}/properties/${params?.propertyId}?tab=settings`;
  const basePropertyIframeUrl = 'configurations/franchisors/properties';
  const basePayrollIframeUrl = `modules/payroll`;
  return (
    <ErrorBoundary identifier="property-settings">
      <Switch>
        <Route exact path={`${path}/general-settings`} component={PropertyGeneralSettingsPage} />
        <Route
          exact
          path={`${path}/staff`}
          render={() => (
            <StaffSettingsListPage appId={params?.appId} propertyId={params.propertyId} />
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.OPERATING_CONFIGURATION)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                toParent={toParentFromSettingsIframe}
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Operating_Configuration" />}
                module={RMSModules.OPERATING_CONFIGURATION}
                url={`${basePropertyIframeUrl}/${RMSModules.OPERATING_CONFIGURATION}`}
                propertyIdFromUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/staff/manage`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Staff" />}
                module={RMSModules.STAFF}
                url={`${basePayrollIframeUrl}/${RMSModules.STAFF}`}
                propertyIdFromUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/staff/${toKebabCase(RMSModules.STAFF_ROLES)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Staff_Roles" />}
                module={RMSModules.STAFF_ROLES}
                url={`${basePayrollIframeUrl}/${RMSModules.STAFF_ROLES}`}
                propertyIdFromUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/layout`}
          render={() => (
            <RMSPage
              subscription={RMSSubscriptions.BASE}
              title={<Translate id="Layout_Configuration" />}
              module={RMSModules.LAYOUT_CONFIGURATION}
              url={`configurations/franchisors/properties/${RMSModules.LAYOUT_CONFIGURATION}`}
              propertyIdFromUrl
              toParent={toParentFromSettingsIframe}
            />
          )}
        />
        <Route
          exact
          path={`${path}/staff/${toKebabCase(RMSModules.STAFF_PERMISSIONS_CONFIGURATION)}`}
          render={() => (
            <RMSPage
              subscription={RMSSubscriptions.BASE}
              title={<Translate id="Staff_Permissions" />}
              module={RMSModules.STAFF_PERMISSIONS_CONFIGURATION}
              url={`configurations/franchisors/properties/${RMSModules.STAFF_PERMISSIONS_CONFIGURATION}`}
              propertyIdFromUrl
            />
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.GEO_CONFIGURATION)}`}
          render={() => (
            <RMSPage
              subscription={RMSSubscriptions.BASE}
              title={<Translate id="Geo_Configuration" />}
              module={RMSModules.GEO_CONFIGURATION}
              url={`configurations/franchisors/properties/${RMSModules.GEO_CONFIGURATION}`}
              propertyIdFromUrl
              toParent={toParentFromSettingsIframe}
            />
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.DELIVERY_CONFIGURATION)}`}
          render={() => (
            <RMSPage
              subscription={RMSSubscriptions.BASE}
              title={<Translate id="Delivery_Management_Configuration" />}
              module={RMSModules.DELIVERY_CONFIGURATION}
              url={`configurations/franchisors/properties/${RMSModules.DELIVERY_CONFIGURATION}`}
              propertyIdFromUrl
              toParent={toParentFromSettingsIframe}
            />
          )}
        />
        {/* Address Mapping */}
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.ADDRESS_MAPPING)}`}
          render={() => (
            <RMSPage
              subscription={RMSSubscriptions.BASE}
              title={<Translate id="Address_Mapping" />}
              module={RMSModules.ADDRESS_MAPPING}
              url={`configurations/system/${RMSModules.ADDRESS_MAPPING}`}
              toParent={toParentFromSettingsIframe}
              propertyIdFromUrl
            />
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.FLOAT_CONFIGURATION)}`}
          render={() => (
            <RMSPage
              subscription={RMSSubscriptions.BASE}
              title={<Translate id="Float_Configuration" />}
              module={RMSModules.FLOAT_CONFIGURATION}
              url={`configurations/franchisors/properties/${RMSModules.FLOAT_CONFIGURATION}`}
              propertyIdFromUrl
              toParent={toParentFromSettingsIframe}
            />
          )}
        />
        <Route
          exact
          path={`${path}/localisation-configuration`}
          render={() => (
            <RMSPage
              subscription={RMSSubscriptions.BASE}
              title={<Translate id="Localization_Configuration" />}
              module={RMSModules.LOCALIZATION_CONFIGURATION}
              url={`configurations/franchisors/properties/${RMSModules.LOCALIZATION_CONFIGURATION}`}
              propertyIdFromUrl
              toParent={toParentFromSettingsIframe}
            />
          )}
        />
        <Route exact path={`${path}/opening-hours`} component={PropertyOpeningHoursPage} />
        <Route exact path={`${path}/dangerzone`} component={DangerZoneList} />
      </Switch>
    </ErrorBoundary>
  );
};

export default PropertySettingsRoutes;
